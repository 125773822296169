export const errorLog = (...args) => {
    console.error(...args)
};

export const infoLog = (...args) => {
    console.log(...args)
};

export const testx = (...args) => {
    
    if(process.env.REACT_APP_NODE_ENV!=="development"){ return; }

    console.log("TESTX", ...args)
}

export const storageManager = localStorage;

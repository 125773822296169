import { useCallback, useEffect, useRef, useState } from "react";


/**
 * returns a setter and getter for the session storage bound to the key
 * @param {*} key 
 */
 export const useSessionStorage = (key, defaultValue = null) =>{
    const lastValueRef = useRef(defaultValue);

    const [value, setValue_] = useState(() => {
      const item = sessionStorage.getItem(key);
      lastValueRef.current = item ? JSON.parse(item) : defaultValue;
      return item ? lastValueRef.current : defaultValue;
    }
    );
    // if value changes then update session storage
    useEffect(()=>{
        const item = sessionStorage.getItem(key);
        const lastValue = lastValueRef.current;
        if(item && lastValue && item !== lastValue){
            const whatIsIt = JSON.parse(item);
            lastValueRef.current = whatIsIt;
            setValue_(lastValueRef.current);
        }
    }, [key, setValue_])
  
    const setValue = useCallback((value) => {
      const stringifiedValue = value? JSON.stringify(value):JSON.stringify(value);
      sessionStorage.setItem(key, stringifiedValue);
      setValue_(value);
    }, [key, setValue_]);
  
    return [value, setValue];
  }
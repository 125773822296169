import { Crdt } from "./Crdt";
import { crdtFactory } from "./crdtFactory";
import {v4 as uuidV4} from "uuid";

/**
 * the code here will be inside a web worker (using workerize)
 * its chief concern is to implement the manage various crdt objects
 * and to execute the crdt operations on them
 */
const { errorLog, testx } = require("utils");

const ICRDTManagementOperations = {
    "AddUserToPeer": "AddUserToPeer",
    "RemoveUserFromPeer": "RemoveUserFromPeer",
}

const ICRDTActionResult = {
    SuccessfulCreation: "SuccessfulCreation",
    ErrorCreation: "ErrorCreation",

    SuccessfulUnmount: "SuccessfulUnmount",

    SuccessfulDelete: "SuccessfulDelete",
    ErrorDelete: "ErrorDelete",

    SuccessfulGetCrdt: "SuccessfulGetCrdt",
    ErrorGetCrdt: "ErrorGetCrdt",

    SuccessfulHas: "SuccessfulHas",
    
    SuccessfulMutation: "SuccessfulMutation",
    ErrorMutation: "ErrorMutation",

    SuccessfulManagemenOperation: "SuccessfulManagemenOperation",
    ErrorManagemenOperation: "ErrorManagemenOperation",

    SuccessSetWithDoc: "SuccessSetWithDoc",
    ErrorSetWithDoc: "ErrorSetWithDoc",

    SuccessfulGetType: "SuccessfulGetType",
    ErrorGetType: "ErrorGetType",
}

class CrdtManager{
    static #initializedInsideStaticCreate = false;
    static #instance = null;
    versionWrapperRef = null;
    map = null;
    owner = null;
    authorities = ['uiochat'];

    constructor(){
        if(!CrdtManager.#initializedInsideStaticCreate){
            throw new Error("CrdtManager cannot be instantiated directly. Use static create() method instead");
        }
        this.map = new Map();
    }

      // version management
    setVersion = (key, version) => this.map.getCrdt(key)?.setVersion( version );
    getVersion = (key) => this.map.getCrdt(key)?.getVersion();

    setOwner = (owner) => {
        const newOwner = {id: owner._id?owner._id:owner.id, roles: owner.roles, username: owner.username};
        this.owner = newOwner;
    }

    setAuthorities = (authorities) => {
        this.authorities = authorities;
    }

    managementOperation = ({operation, payload, user, passport: {socketOriginator}}) => {
        // reject imports where the claimed user is not the socket originator
        if(user.id !== socketOriginator.id){
            errorLog(
                "CrdtManager.processImports: user claims to have id", user.id, 
                "but their socket user id is actually ", socketOriginator.id, 
                "rejecting import", "operation=", operation, 
                "payload=", payload
            );
            return {result:ICRDTActionResult.ErrorManagemenOperation};
        }

        if(operation === ICRDTManagementOperations.RemoveUserFromPeer){
            const {documentKey, userId} = payload;
            const crdt = this.map.get(documentKey);
            const isAuthorizedToRemove = (socketOriginator.roles.includes("main") && socketOriginator.id !== userId )|| false; // socketOriginator cannot remove itself if they are main
            if(isAuthorizedToRemove){
                crdt.removeUserFromPeer(payload.user);
            }
            return {result:ICRDTActionResult.SuccessfulManagemenOperation};
        }

        if(operation === ICRDTManagementOperations.AddUserToPeer){
            const crdt = this.map.get(payload.documentKey);
            crdt.addUserToPeer(payload.user);
            return {result:ICRDTActionResult.SuccessfulManagemenOperation};
        }
    }


    static getCrdtManager(versionWrapperRef=null){
        CrdtManager.#initializedInsideStaticCreate = true;
        if(CrdtManager.#instance !== null){
            if(versionWrapperRef && !CrdtManager.#instance.versionWrapperRef ){
                CrdtManager.#instance.versionWrapperRef = versionWrapperRef;
            }
            return CrdtManager.#instance;
        } 

        CrdtManager.#instance = new CrdtManager();
        if(versionWrapperRef && !CrdtManager.#instance.versionWrapperRef ){
            CrdtManager.#instance.versionWrapperRef = versionWrapperRef;
        }
        return CrdtManager.#instance;
    }

    setCrdtWithDoc = ({key, doc}) => {
        // if the key does not give then send error
        if(!this.map.has(key)){
            return ({result:ICRDTActionResult.ErrorSetWithDoc, message:"Crdt with key: " + key + " does not exist"})
        }
        
        // if key exists then set the crdt with the doc and return
        this.map.get(key).setDocument(doc);
        return ({result:ICRDTActionResult.SuccessSetWithDoc})
    }


    /**
     * uses factory to create a new crdt
     */
    createNewCrdt = async ({key, crdtType, promptType}) => {
        // TODO actually, just create a new crdt with the doc using a new property that allows crdt constructor to toggle between newDocument or initialData
        // this method here must validate the crdt when it is newly instantiated
        // return error if it is not valid

        if(!this.authorities.includes(crdtType)){
            return ({result:ICRDTActionResult.ErrorCreation, message:"Crdt of type: " + crdtType + " is not allowed. Must include: "+JSON.stringify(this.authorities)})
        }

        if(this.map.has(key)){
            return ({result:ICRDTActionResult.ErrorCreation, message:"Crdt with key: " + key + " already exists"})
        }

        const {default: config} = await crdtFactory( crdtType );
        if(!config){
            return ({result:ICRDTActionResult.ErrorCreation, message:"Crdt of type: " + crdtType + " does not exist"})
        }

        let newCrdt = new Crdt({...config, key, owner: this.owner.username, versionWrapperRef: this.versionWrapperRef, promptType})
        this.map.set(key, newCrdt);
        newCrdt = this.map.get(key)

        // put crdt key into session storage so that if socket connections drop out, the user has an inventory
        // of what crdt keys they have to rejoin
        const crdtKeys = JSON.parse(sessionStorage.getItem("crdtKeys")) || [];
        // make sure the key is not already in the array
        const crdtKeyIncluded = crdtKeys.some(({crdtKey})=>crdtKey===key)
        if(!crdtKeyIncluded){
            crdtKeys.push({crdtKey:key, crdtType, promptType});
            sessionStorage.setItem("crdtKeys", JSON.stringify(crdtKeys));
        }

        const result = {result:ICRDTActionResult.SuccessfulCreation, crdt: newCrdt}

        return (result)
    }

    resetAll = () => {
        // create a new map and set it to the old map
        this.map = new Map();
        // reset keys
        sessionStorage.setItem("crdtKeys", JSON.stringify([]));
    }

    deleteCrdt = (key) => {
       
        if(!this.map.has(key)){
            return ({result:ICRDTActionResult.ErrorDelete, message:"Crdt with key: " + key + " does not exist"})
        } 


        const result = {result:ICRDTActionResult.SuccessfulDelete, key}

        this.map.delete(key);

        // remove crdt key from session storage
        const crdtKeys = JSON.parse(sessionStorage.getItem("crdtKeys")) || [];
        // make sure the key is not already in the array
        if(crdtKeys.includes(key)){
            const index = crdtKeys.indexOf(key);
            if (index > -1) {
                crdtKeys.splice(index, 1);
            }
            sessionStorage.setItem("crdtKeys", JSON.stringify(crdtKeys));
        }

        return result;
    }

    getCrdt = (key) => {
        if(!this.map.has(key)){
            return {result:ICRDTActionResult.ErrorGetCrdt, message:"Crdt with key: " + key + " does not exist"}
        } 

        return {result:ICRDTActionResult.SuccessfulGetCrdt, crdt: this.map.get(key).getDocument()};
    }

    mutateCrdt = ({key, opKey, operation, payload, claimant, user: userObject, mutationHint = null, owner=null}) => {
        if(!this.map.has(key)){
            return ({result:ICRDTActionResult.ErrorMutation, message:"Crdt with key: " + key + " does not exist"})
        } 
        
        let success = false;

        try{
            success = this.map.get(key).mutateDocument({operation, opKey, payload, claimant, user: userObject , mutationHint , owner: !owner ? this.owner: owner})
        }catch(error){
            errorLog("CrdtManager.mutateCrdt error:",error)
            return ({result:ICRDTActionResult.ErrorMutation, message:"Crdt with key: " + key + " could not be mutated"})
        }

        return {
            result: success?ICRDTActionResult.SuccessfulMutation:ICRDTActionResult.ErrorMutation, 
        }
    }


    hasCrdt = (key) => {
        const result = {result:ICRDTActionResult.SuccessfulHas, has: this.map.has(key)}
        return result;
    }

    //{result:typeResult, crdtType} = crdtManager.getType(documentId)
    getType = (key) => {
        if(!this.map.has(key)){
            return ({result:ICRDTActionResult.ErrorGetType, message:"Crdt with key: " + key + " does not exist"})
        } 
        return {result:ICRDTActionResult.SuccessfulGetType, crdtType: this.map.get(key).getType()};
    }


    unmount(){
        this.map.clear();
        CrdtManager.#instance = null;
        return {result:ICRDTActionResult.SuccessfulUnmount}
    }

    static generateNewOpKey=()=>{
        return uuidV4()
    }

}

export { CrdtManager, ICRDTActionResult } 

import * as Styled from "./styles"

export const LoadingState = ({text, xs}) => {
    return (
        <Styled.LoadingStateContainer xs={xs}>
            <Styled.Spinner xs={xs}/>
            <Styled.LoadingStateText>{text}</Styled.LoadingStateText>
        </Styled.LoadingStateContainer>
    )    
}

import { Button, Drawer, Dropdown } from 'antd';
import { theme } from 'config/themes';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  margin-left: 8px;
  border: none;
  
`;


export const StyledDropdownButton = styled(Dropdown)`
    border: none;
`

export const MenutItemButton = styled(Button)`
    background: transparent;
    border: none;
    color: inherit;
    box-shadow: none;
    outline: none;
    width: 100%;
    margin: 0;
    &>*{
        margin-left: ${theme.spacing(3)};
        margin-right: 0;
    }
`

export const StyledDrawer = styled(Drawer)`
    & > div.ant-drawer-content-wrapper{
        height: 100% !important;
    }
    & > *, &>*>*{
        overflow: hidden;
    }
    
`

export const OverflowY = styled.div`
    & form {
        overflow-x: hidden;
        overflow-y: auto;
    }
`
import { transformCrdtKeysIntoActions } from "./transformCrdtKeysIntoActions";

const actionCreatorsList = [
    transformCrdtKeysIntoActions
]

export const actionCreators = (payload) => {
    // for each actionCreators function, call it with the payload and return the result inside a try/catch
    
    try{
        const results = actionCreatorsList.map((actionCreator)=> actionCreator(payload))
        const result = results.find((result)=> result.success);
        const {success} = result;
        if(!success || success.length===0){
            return { error: "No actionCreators returned a success", results }
        }
        return {success};

    }catch(e){
        console.error("Error in actionCreators", e);
        return {error: "Error in actionCreators"};
    }
}

import { useMutation } from "@tanstack/react-query";
import { axiosFactory } from "./utils/axiosFactory";
import { Urls } from "./config";


// response from useSignInMutation should never be cached
export const useSignOutMutation = () =>  {
  return useMutation((payload) => axiosFactory({useTokenFromCookie:true}).post(`${Urls.userApi}/logout`, payload), {
    cacheTime: 0,
    staleTime: 0,
    useErrorBoundary: false,
  })
}
import * as CommonStyled from "../styles";
import * as Styled from "./styles";
import { IAccountMode,  } from "pages-modular/hooks";
import { DropdownButton } from "./DropdownButton";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders, faUser, faUserAstronaut } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "providers";
import { _selectorUser } from "providers/AppStateProvider/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useModularNavigation } from "../hooks";
import { IBottomsheetBehavior } from "providers/AppStateProvider/reducers/pageModularScreenReducer";
import { IPagesModularScreen } from "pages-modular/types";

export const UserManagementButtons = ({ handleUserSettings, handleSignUp, handleSignIn, accountMode, showAuthButtons }) => {

  
  const user = useSelector(_selectorUser)
  const {navigateToIfOpenOn, navigateToIfClosed } = useModularNavigation();

  const profileModalConfig = {screen: IPagesModularScreen.UserSettings, props:null, behavior:IBottomsheetBehavior.FixedLeftSideDrawer}
  const accountModalConfig = {screen: IPagesModularScreen.AccountSettings, props:null, behavior:IBottomsheetBehavior.FixedLeftSideDrawer}


  return (
        <>
          {accountMode===IAccountMode.MEMBER_OR_ADMIN ? 
            <>
              {/* <CommonStyled.SmallSecondaryIconButton onClick={handleUserSettings}><FontAwesomeIcon icon={faUser} />
              </CommonStyled.SmallSecondaryIconButton> */}



<DropdownButton icon={faUser} label={user?.username} items={[
  
  {
    key: '1',
    label: (

      <Styled.MenutItemButton onClick={()=>{
        navigateToIfClosed(profileModalConfig)
        navigateToIfOpenOn((IPagesModularScreen.Chat, profileModalConfig))
      }}>
        <FontAwesomeIcon icon={faUserAstronaut}/>
        Profile Settings
      </Styled.MenutItemButton>
    ),
  },
  {
    key: '2',
    label: (
      <Styled.MenutItemButton  onClick={()=>{
        navigateToIfClosed(accountModalConfig)
        navigateToIfOpenOn((IPagesModularScreen.Chat, accountModalConfig))
      }}>
        <FontAwesomeIcon icon={faSliders} />
        Account settings
      </Styled.MenutItemButton>
    ),
  },
]} placement="bottomRight"
/>

            </>
            :
            (
              showAuthButtons
              ?
              <> 
                <CommonStyled.SmallSecondaryButton onClick={handleSignIn}>
                  Sign In
                </CommonStyled.SmallSecondaryButton>
                <CommonStyled.SmallPrimaryButton onClick={handleSignUp}>
                  Create Account
                </CommonStyled.SmallPrimaryButton>
              </> 
              :
              null
            )
            
          }
        </>
  );
}
import { useMutation } from "@tanstack/react-query";
import { Urls } from "./config";

import { axiosFactory } from "./utils/axiosFactory";


export const useBusinessModifyMutation = () =>  
    useMutation((payload) => axiosFactory({useTokenFromCookie:true, userCommonType:false}).patch(`${Urls.userApi}`, payload), {
      cacheTime: 0,
      staleTime: 0,
      useErrorBoundary: false,
    })
  





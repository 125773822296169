import styled from "styled-components";
import { 
    typographyMixin, TypographyType, TypographySize
} from "components/utils/mixins";
import { ErrorMessage } from "formik";

export const FieldLabel = styled.label`
display: flex;
flex-direction: column;
`

export const FieldError = styled(ErrorMessage)`
    ${typographyMixin(TypographyType.red, TypographySize.medium)}
`


export const FormError = styled.p`
    ${typographyMixin(TypographyType.red, TypographySize.medium)}
`


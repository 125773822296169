import * as Actions from "providers/AppStateProvider/actions";

export const counterReducer = (state, action = {}) => {
  const { type } = action
  switch (type) {
    case Actions.COUNTER_INCREMENT: {
      return {
        ...state,
        count: state.count + 1,
      }
    }
    case Actions.COUNTER_DECREMENT : {
      return {
        ...state,
        count: state.count - 1,
      }
    }
    default:
      return state
  }
}
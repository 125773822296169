import * as Styled from "../styles";
import { NavLayout } from "./NavLayout";
import Logo from "vector-assets/logo.svg";
import { UserManagementButtons } from "./UserManagementButtons";

export const BrandHeaderLayout = ({ handleOnClick, handleUserSettings, handleSignUp, handleSignIn, rtcId, accountMode, showAuthButtons }) => {

  return (
    <NavLayout
      renderLeft={<Styled.DashboardButton onClick={!rtcId?handleOnClick:()=>{}} type="link">
          <img src={Logo} alt="UIO.io Logo" />
        </Styled.DashboardButton>
      }
      renderRight={
        <>
          <UserManagementButtons 
              handleSignUp={handleSignUp} 
              handleSignIn={handleSignIn} 
              handleUserSettings={handleUserSettings} 
              accountMode={accountMode} 
              showAuthButtons={showAuthButtons}
          />
        </>
      }
    />
  );
}

import { confirmNewPasswordField, newPasswordField, oldPasswordField, fullnameModify, usernameModify } from "components/utils/forms/fields";
import { LargeSecondaryButton } from "components/Button";
import { FormError } from "components/Text";
import { buildSimpleSubmissionForm } from "components/utils/forms/buildSimpleSubmissionForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useSuccessIndicator } from "./hooks";
import { theme } from "config/themes";


const fields = [ 
  fullnameModify, 
  usernameModify,
  oldPasswordField,
  newPasswordField,
  confirmNewPasswordField
];

// Note: `isSubmitting` is injected by default with Formik
const PersonalModifyFormControPanel = ({isSubmitting, errors, values, submitHook})=>{
  const showCheckmark = useSuccessIndicator(isSubmitting, submitHook.isError);

  // Check if there are any validation errors
  const hasErrors = Object.keys(errors).length > 0;

    // Check if all values are falsey
    const allValuesFalsey = Object.values(values).every(value => !Boolean(value));

  return (<>
        <FormError name={"signin-error"} component={"p"} >{submitHook.isError?`Cannot update fields`:null}&nbsp;</FormError > 
        <LargeSecondaryButton type="submit" disabled={isSubmitting || hasErrors || allValuesFalsey} fluid>
          {showCheckmark && <FontAwesomeIcon icon={faCheck} style={{color: 'green', marginRight:theme.spacing(3)}} />}
          {isSubmitting?`Updating...`: !showCheckmark ?` Update` : `Updated`}
        </LargeSecondaryButton>
    </>)
}

export const PersonalModifyForm = buildSimpleSubmissionForm(fields, PersonalModifyFormControPanel)
import * as Styled from "./styles";
import "react-spring-bottom-sheet/dist/style.css";
import {
  useLayoutMode,
  useModalBottomsheetLayoutHandlers,
  useModularNavigation,
  useSelectBottomsheet,
  useSelectPageModular,
} from "./hooks";
import {
  BrandHeaderLayout,
  BottomsheetLayout,
  NavLayout,
} from "layout/ModalBottomsheetLayout/layouts";
import { Modal } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { IPagesModularScreen } from "pages-modular/types";
import { PagesModularScreen } from "pages-modular/PagesModularScreen";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { IBottomsheetBehavior } from "providers/AppStateProvider/reducers/pageModularScreenReducer";
import { usePagesModularStackContext } from "providers/PagesModularStackProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "providers";
import { _selectorBottomsheetLayout, _selectorChat } from "providers/AppStateProvider/store";
import { useAccountMode } from "pages-modular/hooks";
import { useMemo } from "react";
import { ChatContent } from "pages-modular/ChatContent";
import { useEffect, useRef } from "react";
import * as Actions from "providers/AppStateProvider/actions";
import { useState } from "react";
import { ModalLayout } from "./layouts/ModalLayout";
import { DrawerLayout } from "./layouts/DrawerLayout";

const useHandleOnClick = () => {
  const navigateToUrl = useNavigate();
  const handle = useCallback(()=>{navigateToUrl("/")}, [navigateToUrl])
  return handle
}

const ILayoutMode = {
  bottomsheet: "bottomsheet",
  modal: "modal",
  drawer: "drawer"
}

export const ModalBottomsheetLayout = ({children}) => {

  // Media query for desktop
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  });

  // Media query for tablets and smaller devices
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });


  // Modal: State to control the antd Modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Bottomsheet: State to control the react-spring-bottom-sheet
  const {open:openBottomsheet} = useSelectBottomsheet();
  const {screen, behavior, props, nonprimaryScreens} = useSelectPageModular();

  const { pop, count } = usePagesModularStackContext();

  const modularNavigation = useModularNavigation()

  const {rtcId} = useSelector(_selectorChat);
  const accountMode = useAccountMode()

  const showAuthButtons = useMemo(()=> !openBottomsheet || (screen !== IPagesModularScreen.SignIn && screen !== IPagesModularScreen.SignUp), [openBottomsheet, screen])


  const handleOnClick = useHandleOnClick();

  const { handleSignUp, handleSignIn, handleOverlayClick, onDismiss, handleUserSettings } =
    useModalBottomsheetLayoutHandlers();

    const dispatch = useDispatch();
    const {height} = useSelector(_selectorBottomsheetLayout)
    const footerRef = useRef(null);

    const intervalCb = useCallback(() => {
      const footerDim = {
        width: footerRef.current?.clientWidth,
        height: footerRef.current?.clientHeight,
        top: footerRef.current?.getBoundingClientRect().top,
      }
      if (footerDim) {
        const isMobile = (window.innerWidth-16) <= footerDim.width;
        if(height !== footerDim.height && height !== -1 && isMobile){
          const payload = Number.parseInt(footerDim.top)
          const payloadheight = Number.parseInt(footerDim.height)
          dispatch({
            type: Actions.BOTTOMSHEET_TOP,
            payload,
          });
          dispatch({
            type: Actions.BOTTOMSHEET_HEIGHT,
            payload: payloadheight,
          });
        }
      }
    }, [dispatch, height]);

    const intervalCbRef = useRef(intervalCb);


    useEffect(() => {
      // Open or close the antd Modal based on the openBottomsheet state 
      // and the device type
      if (openBottomsheet && isDesktopOrLaptop) {
        setIsModalOpen(true);
      } else {
        setIsModalOpen(false);
      }
    }, [openBottomsheet, isDesktopOrLaptop]);

  // every 1 second get the height of the bottomsheet and console log it
  // this is to help with debugging the height of the bottomsheet
  useEffect(() => {
    const interval = setInterval(intervalCbRef.current, 1000);
    return () => clearInterval(interval);
  }, [ intervalCb]);

  
const layoutMode = useLayoutMode()

  const RenderContent = () => (
    <>
      <Styled.LayoutContentContainer>
      <PageModularHeaderLayout count={count} pop={pop} modularNavigation={modularNavigation}/>
        <PagesModularScreen
        
          screen={screen}
          props={props}
          nonprimaryScreens={nonprimaryScreens}
        />
      </Styled.LayoutContentContainer>
    </>
  )

  return (
    <Styled.LayoutContainer>
      {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like branding and navigation. */}
        {
          !rtcId
          ?
          <BrandHeaderLayout
            handleOnClick={handleOnClick}
            handleUserSettings={handleUserSettings}
            handleSignUp={handleSignUp}
            handleSignIn={handleSignIn}
            rtcId={rtcId}
            accountMode={accountMode}
            showAuthButtons={showAuthButtons}
          />
          :
          null
        }
        {children}


        { layoutMode === ILayoutMode.modal ? (
          <ModalLayout
            handleOverlayClick={handleOverlayClick}
            showBackgroundOverlay={openBottomsheet && behavior === IBottomsheetBehavior.Priority}
            openBottomsheet={openBottomsheet}
            onDismiss={onDismiss}
            renderContent={RenderContent}
          />
          )
          :
          <></>
        }
        
        { layoutMode === ILayoutMode.drawer ? (
          <DrawerLayout
            handleOverlayClick={handleOverlayClick}
            showBackgroundOverlay={openBottomsheet && behavior === IBottomsheetBehavior.Priority} // switches behavior from drawer to priority
            openBottomsheet={openBottomsheet}
            onDismiss={onDismiss}
            renderContent={RenderContent}
          />
          )
          :
          <></>
        }

        {
          layoutMode === ILayoutMode.bottomsheet && behavior !== IBottomsheetBehavior.FixedBar
          ?
          <BottomsheetLayout
              handleOverlayClick={handleOverlayClick}
              showBackgroundOverlay={openBottomsheet && behavior === IBottomsheetBehavior.Priority}
              openBottomsheet={openBottomsheet}
              onDismiss={onDismiss}
              renderContent={RenderContent}
            />
          :
          <>
            {/* <Styled.Footer ref={footerRef} component="footer">
                  <ChatContent {...props}/> 
            </Styled.Footer> */}
          </>
        }

    </Styled.LayoutContainer>
  );
};


const PageModularHeaderLayout = (props) => {
  
  return (
    
    <LeftNavPagesModular {...props}/>
  )
}

const LeftNavPagesModular = ({pop, count, modularNavigation}) =>{
  if(count >= 1){
    return (
      <Styled.DashboardButton aria-label={"previous content"} onClick={()=>{
          const top = pop();
          modularNavigation.navigateToAlways(top);
        }} type="link">
        <FontAwesomeIcon icon={faArrowLeft} size="xs" />
      </Styled.DashboardButton>
    )
  }
  return  <Styled.DashboardButton onClick={()=>{
  }} type="link" aria-label={"no other content"} disabled>&nbsp;
</Styled.DashboardButton>;
}
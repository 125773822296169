import { InputRegular } from "components/Input"
import { useRef, useState } from "react";
import { useCallback } from "react";

export const deltaMaker = (text) => ({ops:[{insert: text}]})
const initDelta = deltaMaker("");
// uses fields from components to render stateful input and will call handleSubmit on key press Enter
const MessageInput = ({handleSubmit}) => {

    // we are using this clunky looking idiom just in case we need to inject a third party library which would require a ref state instead of a regular state
    let stateRef = useRef(initDelta);
    const [toggle, setToggle] = useState(false);

    const message = stateRef.current;
    const setMessage = useCallback((value)=>{
        stateRef.current = value;
        setToggle(!toggle);
    })

    return (
        <div>
            <InputRegular value={message.ops[0].insert} onChange={ev=>{
                setMessage({ops:[{insert: ev.target.value}]});
            }} onKeyDown={ev=>{
                if(ev.keyCode === 13){
                    const myMessage = JSON.parse(JSON.stringify(message));
                    handleSubmit(myMessage);
                    setMessage(initDelta);
                }
            }} />
        </div>
    )
}


export { MessageInput }
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { Urls } from "./config";


// response from useSignInMutation should never be cached
export const useSignInMutation = () =>  
    useMutation((payload) => axios.post(`${Urls.userApi}/login`, payload), {
      cacheTime: 0,
      staleTime: 0,
      useErrorBoundary: false,
    })
  

import * as CommonStyles from "../styles";
import { IPagesModularScreen } from "pages-modular/types";
import { ISystemMessageClientTypes } from "providers/MessagingProvider/socket-handlers/ISystemMessageClientTypes";
import { _selectorUser } from "providers/AppStateProvider/store";
import { useCallback, useState } from "react";
import { OptionsBar } from "./OptionsBar";
import { SuccessErrorWrapper } from "pages-modular/utils";
import { usePersonalModifyMutation } from "hooks/usePersonalModifyMutation";
import { useGetUser } from "pages-modular/SignInFormContent/hooks";
import { useAnonymousTokenFromCookie, useTokenFromCookie } from "pages-modular/hooks";
import { ANON_XSRF_TOKEN_SENTINEL } from "utils/constants";
import { testx } from "utils";
import { useBusinessModifyMutation } from "hooks/useBusinessModifyMutation";
import { useMessaging, useSelector } from "providers";

export const UserSettingsContent = ({screen=IPagesModularScreen.UserSettings}) => {

  
  const { clientEmit } = useMessaging()


  const businessModifyMutation = useBusinessModifyMutation()
  // const businessCreateMutation = useCreateBusinessMutation()
  const personalModifyMutation = usePersonalModifyMutation()
  const { getUserThenUpdateRedux } = useGetUser();
  const { setAnonymousToken } = useAnonymousTokenFromCookie();
  const {token} = useTokenFromCookie()
  const user = useSelector(_selectorUser)

  const businessModifyFormSubmitHook = useCallback(async (...props) => {
    const [values, { setSubmitting, resetForm }] = props;

      // if all properties in values are falsey then return
      if(Object.values(values).every(x=>!x)){
        return;
      }
      

      setSubmitting(true);
      const businessModPayload ={...values, _id: user.id}
      const wrappedResponse = await SuccessErrorWrapper(async ()=>await businessModifyMutation.mutateAsync(businessModPayload))
      const {success} = wrappedResponse;
      testx("businessModifyFormSubmitHook success=",success)
      setSubmitting(false);
      if(success && token){
        setAnonymousToken(ANON_XSRF_TOKEN_SENTINEL);
        getUserThenUpdateRedux(token);

        // emit event to websocket server to refresh user
        clientEmit(ISystemMessageClientTypes.UpdateUserSession, {token});


        resetForm(); // Reset the form after successful submission
        
      }
  }, [businessModifyMutation, getUserThenUpdateRedux, setAnonymousToken, clientEmit, token, user])

  const personalModifyFormSubmitHook = useCallback(async (...props) => {
    const [values, { setSubmitting, resetForm }] = props;

      // if all properties in values are falsey then return
      if(Object.values(values).every(x=>!x)){
        return;
      }
      

      setSubmitting(true);
      const wrappedResponse = await SuccessErrorWrapper(async ()=>await personalModifyMutation.mutateAsync(values))
      const {success} = wrappedResponse;
      testx("personalModifyFormSubmitHook success=",success)
      setSubmitting(false);
      if(success && token){
        setAnonymousToken(ANON_XSRF_TOKEN_SENTINEL);
        getUserThenUpdateRedux(token);

        // emit event to websocket server to refresh user
        clientEmit(ISystemMessageClientTypes.UpdateUserSession, {token});


        resetForm(); // Reset the form after successful submission

        
      }
  }, [personalModifyMutation, getUserThenUpdateRedux, setAnonymousToken, clientEmit, token])

  const optionsBarProps = {
    screen,
    onSubmits: {
      personalModifyForm: personalModifyFormSubmitHook,
      businessModifyForm: businessModifyFormSubmitHook,
      // businessCreateForm: businessCreateFormSubmitHook
    },
    submitHooks: {
      personalModifyForm: personalModifyMutation,
      // businessCreateForm: businessCreateMutation,
      businessModifyForm: businessModifyMutation
    }
  }
  
  return (
    <CommonStyles.ColumnContainer>
      <CommonStyles.CenterAlign>
        <OptionsBar {...optionsBarProps}/>    
      </CommonStyles.CenterAlign>
      
    </CommonStyles.ColumnContainer>
  );
};





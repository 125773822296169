import React, { useMemo, useCallback, useState} from "react";
import { useEffect } from "react";
import { deltaMaker, MessageInput } from "./MessageInput";
import {QuillDeltaToHtmlConverter} from 'quill-delta-to-html';
import DOMPurify from "dompurify";
import TimeAgo from "./TimeAgo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";
import { colors } from "config/colors";

// a factory that generates the ui component that will be used to render the crdt
export const ChatRoll = ({document, onAdd, onEditChat, onDeleteChat, owner}) => {
    const [uniqueKey] = useState(Math.random());
    const [editId, setEditId] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const contentRef = useRef(null);

    const scrollToTop = useCallback( (ref) => {
        if (ref.current) {
        ref.current.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        }
    }, []);
    

  useEffect(() => {
    contentRef.current.addEventListener('scroll', handleScroll);
    return () => {
      contentRef.current.removeEventListener('scroll', handleScroll);
    };
  }, [contentRef]);

    const handleScroll =  useCallback(() => {
        const scrollPosition = contentRef.current.scrollTop;
        setIsVisible(scrollPosition > 16);
      }, [])
  

    const chatRoll = useMemo(()=>{
        return document ? document.chatRoll.map((item,index)=>({...item, editId, uniqueKey, index, owner, onDeleteChat, onEditChat, setEditId, chat:item, key:`chat-roll-${uniqueKey}-${index}`})) : []
    }, [document])
    return (
        <div> 
            <MessageInput handleSubmit={onAdd} />
            <div style={{maxHeight:"96px", overflowY:"scroll", position:"relative"}} ref={contentRef}>
                {
                    chatRoll.map((chat, index)=>(<ChatRollItem key={`chat-roll-${uniqueKey}-${index}`} editId={editId} uniqueKey={uniqueKey} owner={owner} onDeleteChat={onDeleteChat} onEditChat={onEditChat} setEditId={setEditId} chat={chat} />))
                }
                {
                    isVisible
                    ?
                    <button style={circleButtonStyles} onClick={() => scrollToTop(contentRef)}>
                        <span style={upwardCaretStyles} />
                    </button>
                    :
                    null
                }
            </div>
        </div>
    )

}

const ChatRollItem = (props) => {
    const {uniqueKey, onEditChat, setEditId, onDeleteChat, owner, chat, editId} = props;
    const {content, timestamp, author, authorFullname, id, archived} = chat;
    const [_,deltaToHtml] = useDeltaToHtml(content);



    const [editHovered, setEditHovered] = React.useState(false);
    const [deleteHovered, setDeleteHovered] = React.useState(false);
    const [submitHovered, setSubmitHovered] = React.useState(false);
  
    if(archived){
        return null;
    }
    const logProps = {uniqueKey, id, chat, content, onEditChat, setEditId, submitHovered, setSubmitHovered}

    return (
            <div style={chatMessageContainerStyle} key={`chat-roll-${uniqueKey}-${id}`}>
                {
                    editId === id
                    ?
                    <ChatLog {...logProps} />
                    :
                    <>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: deltaToHtml(content),
                            }}
                        />

                        <div style={buttonContainerStyle}>
                            {
                                author === owner 
                                ?
                                <button
                                    style={editHovered ? circularButtonHoverStyle : circularButtonStyle}
                                    onMouseEnter={() => setEditHovered(true)}
                                    onMouseLeave={() => setEditHovered(false)}
                                    onClick={()=>{
                                        setEditId(id);
                                        setEditHovered(false);
                                    }}
                                    >
                                    <FontAwesomeIcon icon={faEdit} size="xs" />
                                </button>
                                :
                                null
                            }
                            {
                                author === owner 
                                ?
                                <button
                                    style={deleteHovered ? circularButtonHoverStyle : circularButtonStyle}
                                    onMouseEnter={() => setDeleteHovered(true)}
                                    onMouseLeave={() => setDeleteHovered(false)}
                                    onClick={()=>{
                                        onDeleteChat({id});
                                        setDeleteHovered(false);
                                    }}
                                    >
                                    <FontAwesomeIcon icon={faTrash} size="xs" />
                                </button>
                                :
                                null
                            }
                        </div>
                    </>
                }

                <div>
                    <span style={{fontWeight:"bold"}}>{formatName(authorFullname)}</span>
                    <span>{author}</span>
                    {" ("}<TimeAgo date={timestamp} />{" ago)"}
                </div>
            </div>
    )
}

const ChatLogNoMemo = ({uniqueKey, id, chat, content, onEditChat, setEditId, submitHovered, setSubmitHovered}) =>{
    const [myContent, setMyContent] = useState("");
    const [_,deltaToHtml,removeHtml] = useDeltaToHtml(content);


    useEffect(()=>{
        const noHtml = removeHtml(content);
        setMyContent(noHtml);
    },[content, deltaToHtml])

    const handleOnChange = useCallback((ev)=>{
        const content = deltaMaker(ev.target.value)
        const payload = {...chat, content}
        payload.chat = undefined; 
        onEditChat(payload);
        setMyContent(ev.target.value);
    },[chat, onEditChat, setMyContent])

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        }} key={`chat-roll-${uniqueKey}-${id}`}>
        <input
            style={{
                flexGrow: 1,
                marginRight: '10px'
            }} 
            value={myContent}
            onKeyDown={ev=>{
                // on enter 
                if(ev.keyCode === 13){
                    const payload = {...chat, content:deltaMaker(myContent)}
                    payload.chat = undefined;
                    onEditChat(payload);
                    setEditId(null);
                }
            }} 
            onChange={handleOnChange}
            />
                <button 
                    style={submitHovered ? circularButtonHoverStyle : circularButtonStyle}
                    onMouseEnter={() => setSubmitHovered(true)}
                    onMouseLeave={() => setSubmitHovered(false)}
                    onClick={(ev)=>{
                        setEditId(null);
                        setSubmitHovered(false);
                    }}>
                        <FontAwesomeIcon icon={faCheck} size="xs" />
                </button>
    </div>
    )
}


const areEqual = (prevProps, nextProps) => {
    return prevProps.id === nextProps.id
        && prevProps.content === nextProps.content
        && prevProps.uniqueKey === nextProps.uniqueKey
}
const ChatLog = React.memo(ChatLogNoMemo, areEqual);

const useDeltaToHtml = (content) => {
    const [myContent, setMyContent] = useState("");
        // convert delta ops in content prop to html
        const deltaToHtml = useCallback((deltaOps)=>{

            const converter = new QuillDeltaToHtmlConverter(deltaOps.ops);
            const html = converter.convert();
            const sanitizedHTML = DOMPurify.sanitize(html);
            return sanitizedHTML
        },[])


        const removeHtml = useCallback((deltaOps)=>{

            const converter = new QuillDeltaToHtmlConverter(deltaOps.ops);
            const html = converter.convert();
            const myClean = DOMPurify.sanitize(html, {ALLOWED_TAGS: []});
            return myClean
        },[])


        useEffect(()=>{
            const html = deltaToHtml(content);
            setMyContent(html);
        },[deltaToHtml])

        return [myContent, deltaToHtml, removeHtml]
}



const chatMessageContainerStyle = {
    position: 'relative',
    padding: '0 1em 1em 1em',
    backgroundColor: '#fff',
    borderRadius: '5px',
    borderBottom: "1px solid #ddd",
    margin: '1em 0 0 0',
  };

  const buttonContainerStyle = {
    position: 'absolute',
    top: '5px',
    right: '5px',
  };

  const circularButtonStyle = {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    marginLeft: '5px',
  };

  const circularButtonHoverStyle = {
    ...circularButtonStyle,
    backgroundColor: '#ddd',
  };

  const circleButtonStyles = {
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    backgroundColor: colors.gray,
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
    position: 'fixed',
    bottom: '16px',
    left: '16px',
    padding: "8px",
  };
  
  const upwardCaretStyles = {
    display: 'inline-block',
    border: `solid ${colors.black}`,
    borderWidth: '0 2px 2px 0',
    padding: '2px',
    transform: 'rotate(-135deg)',
  };

  function formatName(fullName) {
    const nameParts = fullName.split(' ');
  
    if (nameParts.length === 0) {
      return '';
    }
  
    const firstName = nameParts[0].charAt(0).toUpperCase() + nameParts[0].slice(1);
    const remainingInitials = nameParts.slice(1).map(name => name.charAt(0).toUpperCase() + '.');
  
    return [firstName, ...remainingInitials].join(' ');
  }
  
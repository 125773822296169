// import { useSelector } from "providers/AppStateProvider";
// import { _selectorPageModularScreen } from "providers/AppStateProvider/store";
import { useMemo } from "react";
import { useCallback } from "react";
import {
  EmailConfirmationMessage,
  SignUpFormContent,
  SignInFormContent,
} from ".";
import { AccountSettingsContent } from "./AccountSettingsContent";
import { ChatContent } from "./ChatContent";
import { NewBusinessInfoContent } from "./NewBusinessInfoContent";
import { IPagesModularScreen } from "./types";
import { UserSettingsContent } from "./UserSettingsContent";
import { EditBusinessInfoContent } from "./EditBusinessInfoContent";

/**
 * The components in /pages-modular should be presented only from this pagesModular function
 */
export const PagesModularScreen = ({screen, props: screenProps, nonprimaryScreens=[]}) => {
  const memodScreens = useMemo(()=>[{screen, props:screenProps, nonprimaryScreens}, ...nonprimaryScreens], [screen, screenProps, nonprimaryScreens])



  const screens = useGeneratePagesModularScreens(memodScreens);
  return screens
};






const useGeneratePagesModularScreens = (screens) => {
  const generatePagesModularScreens = useCallback((screens)=>{
    const jsxResults = screens.map(injectedScreen=>{
      const {screen, props: screenProps} = injectedScreen

      switch (screen) {
        case IPagesModularScreen.SignUp: {
          return (<SignUpFormContent {...screenProps} key={`${screen}-modular`}/>);

        }
        case IPagesModularScreen.SignIn: {
          return (<SignInFormContent {...screenProps}  key={`${screen}-modular`}/>);

        }
        case IPagesModularScreen.UserSettings: {
          return (<UserSettingsContent {...screenProps}  key={`${screen}-modular`}/>);

        }
  
        case IPagesModularScreen.PersonalUserSettings: {
          return (<UserSettingsContent {...screenProps} screen={IPagesModularScreen.PersonalUserSettings} key={`${screen}-modular`}/>);

        }
  
        case IPagesModularScreen.RolesUserSettings: {
          return (<UserSettingsContent {...screenProps} screen={IPagesModularScreen.RolesUserSettings} key={`${screen}-modular`}/>);

        }
  
        case IPagesModularScreen.ChatUserSettings: {
          return (<UserSettingsContent {...screenProps} screen={IPagesModularScreen.ChatUserSettings} key={`${screen}-modular`}/>);

        }
  
        case IPagesModularScreen.BusinessUserSettings: {
          return (<UserSettingsContent {...screenProps} screen={IPagesModularScreen.BusinessUserSettings} key={`${screen}-modular`}/>);

        }
  
        case IPagesModularScreen.PrivacyUserSettings: {
          return (<UserSettingsContent {...screenProps} screen={IPagesModularScreen.PrivacyUserSettings} key={`${screen}-modular`}/>);

        }
  
  
        case IPagesModularScreen.NewBusinessInfo: {
          return ( <NewBusinessInfoContent {...screenProps} key={`${screen}-modular`}/>);

        }

        
        case IPagesModularScreen.EditBusinessInfo: {
          return ( <EditBusinessInfoContent {...screenProps} key={`${screen}-modular`}/>);

        }



        case IPagesModularScreen.AccountSettings: {
          return (<AccountSettingsContent {...screenProps}  key={`${screen}-modular`}/>);

        }

        case IPagesModularScreen.EmailConfirmationMessage: {
          return ( <EmailConfirmationMessage {...screenProps} key={`${screen}-modular`}/>);

        }
        case IPagesModularScreen.Chat: {
          return (<ChatContent {...screenProps} key={`${screen}-modular`}/>);

        }
        default:
          return (<>PagesModularScreen error: No component found</>);

      }
    })
    return jsxResults;
  }, [screens])

    return useMemo(()=>generatePagesModularScreens(screens), [generatePagesModularScreens, screens])
};
import { useMutation } from "@tanstack/react-query";
import { Urls } from "./config";

import { axiosFactory } from "./utils/axiosFactory";


// response from useSignInMutation should never be cached
export const usePersonalModifyMutation = () =>  
    useMutation((payload) => axiosFactory({useTokenFromCookie:true, userCommonType:false}).put(`${Urls.userApi}/profile`, payload), {
      cacheTime: 0,
      staleTime: 0,
      useErrorBoundary: false,
    })
  





/**
 * Styles common for all page-modular
 */

import { simpleFormSpacingMixin } from "components/Forms/styles";
import styled from "styled-components";


export const ColumnContainer = styled.div`
    ${simpleFormSpacingMixin()}
    display: flex;
    flex-direction: column;
`

export const CenterAlign = styled.div`
    margin: auto;
`

export const LoadingStateContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 8px;
    align-items: center;
    justify-content: center;
`
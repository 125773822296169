import { Tabs } from 'antd';
import { PersonalModifyForm, BusinessModifyForm } from 'components/Forms';
import { BusinessModifyFormAlt } from 'components/Forms/BusinessModifyFormAlt';
import { BusinessModifyFormAlt4 } from 'components/Forms/BusinessModifyFormAlt4';
import { SelectBusinessIndependentField } from 'components/Forms/SelectBusinessIndependentField';
import { ILayoutMode, useLayoutMode, useModularNavigation } from 'layout/ModalBottomsheetLayout/hooks';
import { IPagesModularScreen } from 'pages-modular/types';
import { Heading } from 'pages/GetStarted/styles';
import { useSelector } from 'providers';
import { IBottomsheetBehavior } from 'providers/AppStateProvider/reducers/pageModularScreenReducer';
import { _selectorUser } from 'providers/AppStateProvider/store';
import React, { useCallback, useMemo } from 'react';
import { useGetRefs } from './hooks/useGetRefs';

export const OptionsBar = (props) => {
  const {navigateToIfOpenOn} = useModularNavigation();
  const user = useSelector(_selectorUser);
  const {data: industriesRef, isLoading }  = useGetRefs("industries");
  // const [activeKey, setActiveKey] = useState("profile-settings-1");

  const handleTabNav = useCallback((fromScreen, toScreen)=>{
    navigateToIfOpenOn(fromScreen, {screen:toScreen, props:{}, behavior:IBottomsheetBehavior.FixedLeftSideDrawer, showBack:false})
  }
  ,[navigateToIfOpenOn])
  const activeKey = useMemo(()=>{
    const screen = screenToKeyMapping[props.screen] || "profile-settings-1"
    return screen;
  },[props.screen])

  const layoutMode = useLayoutMode();
  const tabPosition = layoutMode===ILayoutMode.drawer ? 'left' : 'top';
  return (
    <>
      <Tabs
        onTabClick={(screen, ev)=>{
          keyToScreenMapping[screen] && handleTabNav(props.screen, keyToScreenMapping[screen]);
        }}
        tabPosition={tabPosition}
        activeKey={activeKey}
        items={
          [
            {
              label: `Personal`, // should include icon
              key: `profile-settings-1`,
              children: <>
                <Heading>Update Personal Info</Heading><PersonalModifyForm {...props} onSubmit={props.onSubmits.personalModifyForm} submitHook={props.submitHooks.personalModifyForm} />
              
              </>,
            },
            // {
            //   label: `Roles`,
            //   key: `roles-settings`,
            //   children: <></>,
            // },
            // {
            //   label: `Chat`,
            //   key: `chat-settings`,
            //   children: <></>,
            // },
            // {
              
            {
              label: `Business`,
              key: `business-settings`,
              children: <>
                <BusinessModifyForm {...props} onSubmit={props.onSubmits.businessModifyForm} submitHook={props.submitHooks.businessModifyForm} user={user}/>
                </>
            },
            // {
            //   label: `Privacy`,
            //   key: `privacy-settings`,
            //   children: <></>,
            // }
          ]
        }
      />
    </>
  );
};



const screenToKeyMapping = {
  [IPagesModularScreen.UserSettings]: "profile-settings-1",
  [IPagesModularScreen.PersonalUserSettings]: "profile-settings-1",
  [IPagesModularScreen.RolesUserSettings]: "roles-settings",
  [IPagesModularScreen.ChatUserSettings]: "chat-settings",
  [IPagesModularScreen.BusinessUserSettings]: "business-settings",
  [IPagesModularScreen.PrivacyUserSettings]: "privacy-settings",
}
// reverse of screenToKeyMapping
const keyToScreenMapping = {};

Object.keys(screenToKeyMapping).forEach((key, index) => {
  const value = Object.values(screenToKeyMapping)[index];
  keyToScreenMapping[value] = key;
});
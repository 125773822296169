

export const XSRF_TOKEN = "XSRF-TOKEN";
export const XSRF_TOKEN_SENTINEL = "XSRF-TOKEN-SENTINEL";



export const ANON_XSRF_TOKEN = "ANON-XSRF-TOKEN";
export const ANON_XSRF_TOKEN_SENTINEL = "ANON-XSRF-TOKEN-SENTINEL";

export const USER_INFO_COOKIE = "USER-INFO";
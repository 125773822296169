import * as CommonStyles from "../styles";
import { useModalBottomsheetLayoutHandlers, useModularNavigation } from "layout/ModalBottomsheetLayout/hooks";
import { IPagesModularScreen } from "pages-modular/types";
import { LargeSecondaryButton } from "components/Button";
import { useHandleAnonymousTokenAndUser } from "../UserSettingsContent/hooks/useHandleAnonymousTokenAndUser";
import { useCrdtContext, useDispatch, useMessaging, useSelector } from "providers";
import { ISystemMessageClientTypes } from "providers/MessagingProvider/socket-handlers/ISystemMessageClientTypes";
import { IBottomsheetBehavior } from "providers/AppStateProvider/reducers/pageModularScreenReducer";
import { CrdtManager } from "providers/CrdtProvider/CrdtManager";
import { _selectorChat } from "providers/AppStateProvider/store";
import * as Actions from "providers/AppStateProvider/actions"
import { useNavigate } from "react-router-dom";
import { LoadingState } from "components/LoadingState";
import { useState } from "react";

export const AccountSettingsContent = () => {

  const { handleOverlayClick } = useModalBottomsheetLayoutHandlers();

  const {resetVersionData, versionData} = useCrdtContext();

  const dispatch = useDispatch();
  
  const [isLoadingLogout, setIsLoadingLogout] = useState(false);

  const {rtcId} = useSelector(_selectorChat);
  
  const { clientEmit } = useMessaging()

  const navigateTo = useNavigate()

  const {navigateToIfClosed, navigateToIfOpenOn, resetNavigation} = useModularNavigation()

  const {handleLogOut} = useHandleAnonymousTokenAndUser();

  const handleSignOutMemberAndGetAnonymous = async () => {
    
    // clear session storage first
    sessionStorage.clear();

    setIsLoadingLogout(true);
    const newAnonToken = await handleLogOut();
    setIsLoadingLogout(false)
    if(newAnonToken){

      // delete all crdt data
      const crdtManager = CrdtManager.getCrdtManager();
      crdtManager.resetAll();

      // emit event to websocket server to refresh user
      clientEmit(ISystemMessageClientTypes.UpdateUserSession, {token: newAnonToken});
      
      const newPageModularObject = 
      { 
        screen:IPagesModularScreen.SignIn, 
        props: {title: "Sign In", description: "You are signed out. To enjoy UIO.io to its fullest, sign in to your account"},
        behavior: IBottomsheetBehavior.Priority
      }
      // either does 1.
      navigateToIfClosed(newPageModularObject)

      // or does 2.
      navigateToIfOpenOn( IPagesModularScreen.AccountSettings, newPageModularObject)   

      // then reset navigation
      resetNavigation();

      // reset version data
      resetVersionData();


      // find out if i have an rtcId and if so delete it and navigate to home
      if(rtcId){
        dispatch({type: Actions.SET_RTC, payload: null})
        navigateTo("/");
      }
    }
  }
  
  return (
    <CommonStyles.ColumnContainer>
      <CommonStyles.CenterAlign>
        <LargeSecondaryButton disabled={isLoadingLogout} type="button" onClick={handleSignOutMemberAndGetAnonymous} fluid>
          <CommonStyles.LoadingStateContainer>
            {
              isLoadingLogout 
              ? 
              <LoadingState xs={true} />
              : 
              null
            }
            Sign Out
          </CommonStyles.LoadingStateContainer>
          </LargeSecondaryButton>         
      </CommonStyles.CenterAlign>
      
    </CommonStyles.ColumnContainer>
  );
};





import { useQuery } from "@tanstack/react-query";
import { axiosFactory } from "./utils/axiosFactory";
import { Urls } from "./config";

export const fetchUserInfoAxios = (userCommonType) => axiosFactory({useTokenFromCookie:true, userCommonType}).get(`${Urls.userApi}/me`);

// response from useGetMyUserInfo should never be cached
export const useGetMyUserInfoQuery = () =>  
    useQuery([`${Urls.userApi}`, `me`], 
    fetchUserInfoAxios(),
    {
      cacheTime: 0,
      staleTime: 0,
      useErrorBoundary: false,
    }
  )
  

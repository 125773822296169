
import { useSessionStorage } from "hooks/useSessionStorage";
import { IPagesModularScreen } from "pages-modular/types";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef } from "react";

const PagesModularStackContext = createContext();

export const PagesModularStackProvider = ({ children }) => {
  const [pagesModularStack, setPagesModularStack] = useSessionStorage("pagesModularStack", []);
  const initRef = useRef(false);

  useEffect(()=>{
    if(!pagesModularStack || pagesModularStack.length===0 || initRef.current){
      return;
    }
    if(pagesModularStack.length === 1 && pagesModularStack[pagesModularStack.length-1].screen===IPagesModularScreen.Chat){
      return;
    }
    if(pagesModularStack.length > 1 && pagesModularStack[pagesModularStack.length-1].screen===IPagesModularScreen.Chat){
      const top = pagesModularStack.pop();
      setPagesModularStack([top])
      initRef.current = true;
      return;
    }
    setPagesModularStack([])
    initRef.current = true;
  }, [pagesModularStack, setPagesModularStack])

  const reset = useCallback(()=>{
    setPagesModularStack([]);
  }, [setPagesModularStack])

  const push = useCallback((item)=>{
    setPagesModularStack([...pagesModularStack, item])
  }, [pagesModularStack, setPagesModularStack])


  const pop = useCallback(()=>{
    const item = pagesModularStack.pop();
    setPagesModularStack([...pagesModularStack]);
    return item;
  }, [pagesModularStack, setPagesModularStack])
  

  const count = useMemo(()=>pagesModularStack?.length, [pagesModularStack])
  return (
    <PagesModularStackContext.Provider value={{ push, pop, reset, count }}>
      {children}
    </PagesModularStackContext.Provider>
  );
  
};

export const usePagesModularStackContext = () => {
  const context = useContext(PagesModularStackContext);
  if (!context) {
    throw new Error("usePagesModularStackContext must be used within a PagesModularStackProvider");
  }
  return context;
};

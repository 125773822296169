
import { Formik } from "formik";
import { useMemo } from "react";
import { testx } from "utils";
import * as Yup from "yup";

export const buildInitialValues = (list)=>{
    const result = {};
    list.forEach(item=>{
      // if(typeof item.initialValue!=="undefined"){
      //   result[item.name]= item.initialValue
      // }
      result[item.name]= item.initialValue
    })
    return result
  }
  
  
export const buildValidationSchemeFromList = (list, yupChain = null) => {
    const result = {};
    list.forEach(item=>{
    result[item.name]= item.yup
    })
    const resultValidation = (yupChain?yupChain:Yup.object()).shape(result);
    return resultValidation;
}

export const buildFormik = (list, untoggledValues={})=>{

    const validationSchema = buildValidationSchemeFromList(list)
    testx("validationSchema", validationSchema)
    const staticInitialValues = buildInitialValues(list);
  

    const CurryFormik = ({children, ...props}) => {
      
      // combine initialValues from props and buildInitialValues return values
      const initialValues = useMemo(()=>{
        return  {...staticInitialValues, ...props.initialValues, ...untoggledValues}
      }, [staticInitialValues, props.initialValues, untoggledValues])


        return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            {...props}
            onSubmit={(...args)=>{
              return props.onSubmit(...args, initialValues)
            }}
        >
            {children}
        </Formik>
        );
    };
  return CurryFormik
}

import { ICRDTActionResult } from "providers/CrdtProvider/CrdtManager";
import { IPromptType } from "providers/CrdtProvider/prompts/chat";
import { ISystemMessageClientTypes } from "providers/MessagingProvider/socket-handlers/ISystemMessageClientTypes";

export const documentManifestFoundHandler = async ({ socket, crdtManager }, {documentManifest}) => {
    const {forDocumentType:crdtType, _id:key} = documentManifest
    const successHas = crdtManager.hasCrdt(key)
    if(successHas.result===ICRDTActionResult.SuccessHas && successHas.has){
        socket.emit(ISystemMessageClientTypes.GetSharedDocument, {documentManifestId: documentManifest._id})
        return;
    }
    const successCreate = await crdtManager.createNewCrdt({key, crdtType, promptType:IPromptType.LOCATION_THEN_CHAT})
    socket.emit(ISystemMessageClientTypes.GetSharedDocument, {documentManifestId: documentManifest._id})
}
import {colors} from "config/colors";

export const TypographyType = {
    black: "TypographType_black",
    gray: "TypographType_gray",
    gold : "TypographType_gold",
    cta: "TypographType_cta",
    blue: "TypographType_blue",
    red: "TypographType_red",
}
export const TypographySize ={
    small: "TypographSize_small",
    medium: "TypographSize_medium",
    large: "TypographSize_large",
    extraLarge: "TypographSize_extraLarge"
}

export const typographyMixin = (typographyStyle, typographySize)=>{
    const {fontSize} = typographySize===TypographySize.small?
    {fontSize: 14} : typographySize===TypographySize.medium ?
    {fontSize: 16} : typographySize===TypographySize.large ?
    {fontSize: 18} :  {fontSize: 20}
    const color = typographyStyle===TypographyType.red?colors.red:colors.black;
    // note this default is medium
    const result = `
        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: ${fontSize}px;
        line-height: 16px;

        letter-spacing: 0.5px;

        color: ${color};
    `
    return result
}
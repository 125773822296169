import { CheckboxRegular } from "components/Checkboxes"
import { useHasValidBusiness } from "./hooks"

/** will show only if there is a single valid business. the first business is "No Business" which is a sentinel business object that every user is given */
export const SelectBusinessCheckbox = (props) => {
    const hasValidBusiness = useHasValidBusiness()

    if(hasValidBusiness){
        return (<CheckboxRegular {...props} />)
    }
    return null;
}



import _ from "lodash"
import { useMessaging } from "providers"
import { useCrdtContext } from "providers/CrdtProvider"
import { CrdtManager, ICRDTActionResult } from "providers/CrdtProvider/CrdtManager"
import { ISystemMessageClientTypes } from "providers/MessagingProvider/socket-handlers/ISystemMessageClientTypes"
import { useCallback } from "react"
import { useSelector } from "providers/AppStateProvider"
import { _selectorUser } from "providers/AppStateProvider/store"
import { errorLog } from "utils"

/**
 * ChatContent shows the chat content according to peer's documentid
 */
export const useSharedDocumentHandler = (props) => {
    const {documentId} = props
    const { clientEmit } = useMessaging()
    const user = useSelector(_selectorUser);

    // crdt things start here
    const {crdtManager} = useCrdtContext();

    const generateOperationObject = useCallback(({ operation, payload, mutationHint })=>{
        const result = {
            opKey: `${CrdtManager.generateNewOpKey()}`,
            key: documentId, 
            operation, 
            payload,
            user, 
            mutationHint
        }
        return result;
    },[user, documentId])

    const mutateDocAndEmit = useCallback((payload)=>{

        const {result} = crdtManager.mutateCrdt({...payload, claimant: user.username})
                    
        if(result===ICRDTActionResult.SuccessfulMutation){
            clientEmit(ISystemMessageClientTypes.CrdtMutateData, payload);
        }else{
            errorLog("mutateDocAndEmit callback payload=", payload)
        }

    }, [user])

    const genericHandler = useCallback(
        input=>  _.flow([
                generateOperationObject,
                mutateDocAndEmit
            ])(input), [generateOperationObject, mutateDocAndEmit] 
        );

    
    
    return (
        genericHandler
    )

}


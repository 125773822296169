import { useState, useEffect, useRef } from 'react';
import { useField, useFormikContext } from 'formik';
import { SmallText } from './Text';
import { FieldInputRegular, FieldInputMimic } from 'components/Input';

// TODO find out how to allow clicking on components that expand outside their initial fields like select option components

export const ToggleEditField = ({ render, untoggledValue, ...props }) => {
  const [field, meta] = useField(props);
  const [isEditing, setIsEditing] = useState(false);
  const [selectionStart, setSelectionStart] = useState(0);
  const [selectionEnd, setSelectionEnd] = useState(0);

  const wrapperRef = useRef(null);
  const inputRef = useRef(null);
  const spanRef = useRef(null);
  const editTripRef = useRef(false);

  const [editedUntoggledValue, setEditedUntoggledValue] = useState(null);

  useEffect(()=>{
    if(!isEditing && editTripRef.current){
      console.log("EDITING", isEditing) 
      setEditedUntoggledValue(field.value)
    }
    editTripRef.current = true;
  }, [isEditing, field.value])

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      if(inputRef.current.setSelectionRange){
        inputRef.current.setSelectionRange(selectionStart, selectionEnd);
      }
    }
  }, [isEditing, selectionStart, selectionEnd]);

  useEffect(() => {
    function handleClickOutside(event) {
      console.log("UNTOGGLED handleClickOutside", props)
      const delayToggle = props?.staticField?.delayToggle
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        !delayToggle
      ) {
        setIsEditing(false);
      }

      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        delayToggle
      ) {
        
          setTimeout(() => {
            setIsEditing(false);
          }, 500); // 500ms delay
      }
        
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const captureSelection = () => {
    const selection = window.getSelection();
    const start = selection.anchorOffset;
    const end = selection.focusOffset;
    setSelectionStart(Math.min(start, end));
    setSelectionEnd(Math.max(start, end));
  };

  const handleEditClick = () => {
    captureSelection();
    setIsEditing(true);
  };
  return (
    <div ref={wrapperRef} onClick={handleEditClick}>
      {isEditing ? (
        render ? (
          render({ field, meta, innerRef: inputRef, selectionRanges:{start:selectionStart, end:selectionEnd} })
        ) : (
          <FieldInputRegular innerRef={inputRef} {...field} {...props} onBlur={() => setIsEditing(false)} selectionRanges={{start:selectionStart, end:selectionEnd}}/>
        )
      ) : (
        <FieldInputMimic>
          <SmallText ref={spanRef}>{(editedUntoggledValue ? editedUntoggledValue : untoggledValue) || 'N/A'}</SmallText>
          {/* <SmallText> (Click to Edit)</SmallText> */}
        </FieldInputMimic>
      )}
    </div>
  );
};

import styled from "styled-components"
import { Checkbox as AntdCheckbox } from 'antd';
import { colors } from "config/colors";



export const CheckboxRegular = styled(AntdCheckbox)`
    span.ant-checkbox-inner {
        background-color: ${colors.white};
        border-color: ${colors.black};
        &:after{
            border: 2px solid ${colors.black};
            border-left: 0;
            border-top: 0;
        }
    }
`

import { EventEmitter } from "events";
import { eventEmitterHandlers } from ".";

window.EventEmitter = EventEmitter;
// singleton class to manage the event emitter
export class EventManager {
    static #privateField = true;
    static #instance = null;
    #emitter = null;
    #resources = null

    static getInstance(){
        window.em=this;
        EventManager.#privateField = true
        if(EventManager.#instance !== null){
            return EventManager.#instance;
        }

        EventManager.#instance = new EventManager();
        EventManager.#instance.#emitter = new EventEmitter();
        window.eventManager = EventManager.#instance;  
        EventManager.#instance.attachHandlers();

        return EventManager.#instance;
    }

    // constructor private
    constructor() {
        if(!EventManager.#privateField){
            throw new Error("CrdtManager cannot be instantiated directly. Use static create() method instead");
        }
        //bind
        this.on = this.on.bind(this);
        this.emit = this.emit.bind(this);
        this.attachHandlers = this.attachHandlers.bind(this);
        this.setResources = this.setResources.bind(this);
    }

    setResources(resources){
        this.#resources = resources;
    }

    on(event, handler){
        this.#emitter.on(event, handler);
    }

    emit(event, ...data){
        window.e1 = this.#emitter;
        window.ev = event;
        window.d = data;
        this.#emitter.emit(event, ...data);
    }

    // dynamic load every function inside any js file (other than EventManager.js itself) inside the emitter-handlers folder
    // and attach them to the emitter
    attachHandlers(){
        eventEmitterHandlers.forEach(eventHandler => {
            this.on(eventHandler.event, (...args)=>eventHandler.handler(this.#resources, ...args));
        });
    }




}


export const SuccessErrorWrapper = async (mutateAsync) => {
    let wrappedResponse;
    try{
      const success = await mutateAsync();
      wrappedResponse = {success}
    }catch(error){
      wrappedResponse = {error}
    }
    return wrappedResponse
}


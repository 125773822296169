import { mobileMixins } from "layout/ModalBottomsheetLayout/styles";
import styled from "styled-components";
import { theme } from "../../config/themes";

export const SearchWrapper = styled.div`
    ${mobileMixins}
    height: 100vh;
    display: flex;
    width: 100%;
`

export const GetStartedContainer = styled.div`
    margin: auto;
    width: 100%;
`

export const SearchContainer = styled.div`
    width: 100%;
    padding-left: ${theme.spacing(4)};
    padding-right: ${theme.spacing(4)};
    && > * {
        margin: 0;
        margin-bottom: ${theme.spacing(5)};
    }
`

const rankingFontSize = {
    1: theme.fontSize.xlarge,
    2: theme.fontSize.large,
    3: theme.fontSize.medium,
    4: theme.fontSize.small,
    5: theme.fontSize.xsmall,
}
const rankingWeightSize = {
    1: theme.fontSize.xlarge,
    2: theme.fontSize.large,
    3: theme.fontSize.medium,
    4: theme.fontSize.small,
    5: theme.fontSize.xsmall,
}
export const Heading = styled.p`
    font-size: ${({rank})=>rank?rankingFontSize[rank]:theme.fontSize.xlarge};
    font-weight: ${({rank})=>rank?rankingWeightSize[rank]:theme.fontWeight.xlarge};
    font-weight: ${theme.fontWeight.large}
`
import { useState, useEffect } from 'react';

export const useSuccessIndicator = (isSubmitting, isError) => {
    const [showCheckmark, setShowCheckmark] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);
  
    useEffect(() => {
      if (isSubmitting) {
        setHasSubmitted(true);
      }
      if (hasSubmitted && !isSubmitting && !isError) {
        setShowCheckmark(true);
        const timeout = setTimeout(() => {
          setShowCheckmark(false);
        }, 3000); // 3 seconds
  
        return () => clearTimeout(timeout);
      }
    }, [isSubmitting, isError, hasSubmitted]);
  
    return showCheckmark;
  };
  
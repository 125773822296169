import styled from "styled-components";
import { Button } from 'antd';
import {colors} from "config/colors";
import { theme } from "config/themes";
import { 
    buttonMixins, ButtonMixinSizeType, ButtonMixinStyleType, 
} from "components/utils/mixins";

const headingHeight = "54px";
const drawerSpace = "320px";

export const LayoutContainer = styled.div`
    position: relative;
    height: 100%;
    margin: auto;
`

export const LayoutContentContainer = styled.div`
    position: relative;
    height: 100%;
    margin: 0px 8px 8px 8px;
`

export const Nav = styled.nav`
    height: ${({dynamicHeading})=> dynamicHeading ? headingHeight : "auto"};
    top: 0px;
    width: 100%;
    padding: 0px ${theme.spacing(1)};
    border-radius: ${theme.spacing(2)};
    display: flex;
    justify-content: center;
`

export const NavAlignmentContainer = styled.div`
    width: 100%;
    max-width: 978px;
    display: flex;
    justify-content: space-between;
`

export const NavLogoSvg = styled.svg`
    vertical-align: none;
`

export const LeftContainer = styled.div`
    display: flex; 
    align-items: center; 
    margin:  ${theme.spacing(2)} auto auto ${theme.spacing(4)};
    &>img{
        z-index: 99999999;
    }
`

export const RightContainer = styled.div`
    display: flex;
    align-items: center;
    margin: ${theme.spacing(4)} ${theme.spacing(4)} auto auto;
    * {
        margin-left: ${theme.spacing(4)};
    }
    &>a, &>button{
        z-index: 99999999;
    }
`

export const mobileMixins = `
    margin: auto;
    height: 100%;
    max-width: 412px;
`

export const MobileContainer = styled.div`
    ${mobileMixins}
`


export const BottomSheetOverlay = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: ${colors.black};
    opacity: 0.3;
    display: ${({shouldBlock})=> shouldBlock ? "block": "none"};
`

export const DashboardButton = styled(Button)`
    padding: 0;
    height: auto;
    color: ${colors.black};
    &:hover, &:active, &:visited{
        color: ${colors.gray[500]};
    }
    &:active{
        color: ${colors.gray[900]};
    }
` 

export const Drawer = styled.div`
    position: absolute;
    height: 100%;
    width: ${({openDashboard})=> openDashboard ? `${drawerSpace}px`: `0px`};
    border-right: 1px solid ${colors.black};
    top: ${headingHeight};
`
const textShadowMixin = `text-shadow: 0px 0px 8px ${colors.white} , 0px 0px 8px ${colors.white};`;
const noTextShadowMixin = `text-shadow: none;`
export const HowItWorksText = styled.span`
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;

    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;

    letter-spacing: 0.02em;


    color: ${colors.black};
    ${textShadowMixin}



`

export const SmallPrimaryButton = styled.button`    
    ${buttonMixins(ButtonMixinStyleType.primary, ButtonMixinSizeType.small)}
`

export const SmallSecondaryButton = styled.button`    
    ${buttonMixins(ButtonMixinStyleType.secondary, ButtonMixinSizeType.small)}
    ${textShadowMixin}
    &:hover {
        ${noTextShadowMixin}
    }
`

export const SmallSecondaryIconButton = styled.button`    
    ${buttonMixins(ButtonMixinStyleType.secondary, ButtonMixinSizeType.small)}
    svg {
        margin: 0px;
    }
`



export const StickyFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const MainContent = styled.div`
  flex-grow: 1;
`;

export const Footer = styled.div`
  width: 100%;
  padding: 16px 16px;
  background-color: #f8f8f8;
  position: fixed;
  bottom: 0;
`;
import * as Styled from "../styles";
import { Modal } from 'antd';

export const ModalLayout = (props) => {
  const { openBottomsheet, handleOverlayClick, onDismiss, renderContent, showBackgroundOverlay } =
    props;

    const handleCancel = () => {
        handleOverlayClick();
        onDismiss();
      };
    
  return (
    <>
      <Styled.BottomSheetOverlay
        shouldBlock={showBackgroundOverlay}
        onClick={handleOverlayClick}
      />
        
        <Modal 
            visible={openBottomsheet} 
            onCancel={handleCancel}
            footer={null} // remove default footer
            mask={showBackgroundOverlay} // control visibility of the mask
            maskClosable={true} // prevent mask close when showBackgroundOverlay is true
            style={{ maxHeight: '80vh' }} // your custom styles
        >
      {renderContent({ 
        openBottomsheet, 
        handleOverlayClick, 
        onDismiss, 
        renderContent, 
        showBackgroundOverlay 
      })}
    </Modal>

    </>
  );
};

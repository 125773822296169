import { fetchUserInfoAxios } from "hooks";
import { useDispatch } from 'providers/AppStateProvider';
import * as Actions from "providers/AppStateProvider/actions";

import { useCallback } from "react";
import { SuccessErrorWrapper } from 'pages-modular/utils';
import { useAnonymousTokenFromCookie, useTokenFromCookie } from 'pages-modular/hooks';
import { IPagesModularScreen } from "pages-modular/types";
import { useModularNavigation } from "layout/ModalBottomsheetLayout/hooks";
import { useCloseBottomsheetIfScreen } from "layout/ModalBottomsheetLayout/hooks";

export const IUserCommonType = {
  Anonymous: "Anonymous",
  MemberOrAdmin: "MemberOrAdmin",
}

/** 
 * produces a handler when given a token will update cookies with key XSRF-TOKEN,
 * get the user object from `/users/me` and update the user info in redux
 * */
export const useGetUser = () => {
  const {token, setToken} = useTokenFromCookie();
  const { anonymousToken, setAnonymousToken } = useAnonymousTokenFromCookie();
  const dispatch = useDispatch();
  const {navigateToAlways} = useModularNavigation();
  const closeBottomsheetIfScreen = useCloseBottomsheetIfScreen();

  // will fetch user object according to user token or anonymous token
  const getUserCommon = useCallback(async (newToken, userCommonType) => {
    if(!newToken){
      if(userCommonType===IUserCommonType.MemberOrAdmin){
        newToken = token;
      }else if(userCommonType===IUserCommonType.Anonymous){
        newToken = anonymousToken;
      }
    }else{
      if(userCommonType===IUserCommonType.MemberOrAdmin){
        setToken(newToken)
      }else if(userCommonType===IUserCommonType.Anonymous){
        setAnonymousToken(newToken)
      }
    }
    return await SuccessErrorWrapper(()=>fetchUserInfoAxios(userCommonType));
  }, [setToken, token, setAnonymousToken, anonymousToken])


  const getUserThenUpdateRedux = useCallback(async (newToken, userCommonType=IUserCommonType.MemberOrAdmin) => {
    if(!getUserCommon){
      return;
    }
    const {success} = await getUserCommon(newToken, userCommonType);
    if(success){
      const  {data:{user} } = success;
      dispatch({type: Actions.SET_USER_INFO, payload: user})
      return user;
    }
    return null;
  }, [dispatch, getUserCommon])


  const getUserAndNavigateTo = useCallback(async (newToken, userCommonType=IUserCommonType.MemberOrAdmin) => {
    if(!getUserCommon){
      return;
    }
    
    const {success} = await getUserCommon(newToken, userCommonType);
    if(success){
      const  {data:{user} } = success;
      dispatch({type: Actions.SET_USER_INFO, payload: user})
      if(!user.isEmailConfirmed){
        navigateToAlways({screen:IPagesModularScreen.EmailConfirmationMessage, props:{email:user.email}});
      }else{
        closeBottomsheetIfScreen(IPagesModularScreen.SignIn);
      }
    }
  }, [dispatch, setToken, getUserCommon])

  return { getUserAndNavigateTo, getUserThenUpdateRedux }
}

import * as Actions from "providers/AppStateProvider/actions";

export const authReducer = (state, action = {type: null, payload: null}) => {
  const { type, payload } = action
  switch (type) {
    case Actions.SET_AUTH: {
        // if payload is null then all individual props will be reset to null
        return {
            ...state,
            token: payload?.token
        }
    }
    case Actions.SET_ANONYMOUS_AUTH: {
        // if payload is null then all individual props will be reset to null
        return {
            ...state,
            anonymousToken: payload?.anonymousToken
        }
    }
    default:
        return state
  }
}
import { useMutation } from "@tanstack/react-query";
import { axiosFactory } from "./utils/axiosFactory";
import { Urls } from "./config";


// response from useEmailConfirmationMutation should never be cached
export const useEmailConfirmationMutation = () =>  
    useMutation((payload) => axiosFactory({useTokenFromCookie:true}).post(`${Urls.userApi}/email-confirmation`, payload), {
      cacheTime: 0,
      staleTime: 0,
      useErrorBoundary: false,
    })
  

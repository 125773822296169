
export const IPagesModularScreen = {
    SignUp: "SignUp",
    SignIn: "SignIn",
    AccountSettings: "AccountSettings",
    UserSettings: "UserSettings",
  
    PersonalUserSettings:"PersonalUserSettings",
    RolesUserSettings: "RolesUserSettings",
    ChatUserSettings:"ChatUserSettings",
    BusinessUserSettings:"BusinessUserSettings",
    PrivacyUserSettings:"PrivacyUserSettings",
  
    NewBusinessInfo: "NewBusinessInfo",
    EditBusinessInfo: "EditBusinessInfo",
  
    EmailConfirmationMessage: "EmailConfirmationMessage",
  
    // communication screens
    Chat: "Chat",
  };
  


import { buildSimpleSubmissionForm } from "components/utils/forms/buildSimpleSubmissionForm";
import { commonBusinessFields, generateBusinessFormControl } from "./CreateBusinessForm";
import { generateOptionalFields } from "./utils";
import * as _ from "lodash";
import { ToggleEditField } from "components/Text/ToggleEditField";
import { industryControlledField } from "components/utils/forms/fields";


const generateToggleEditFields = (fields)=>{
    
    const resultingFields = fields.map(obj => {
        const result = { ...obj };
    
        // Decorate the Yup validation with optional
        if (result.Component) {
            const ResultComponent = result.Component
            result.Component = (props)=> {
                console.log("UNTOGGLED 3 props=", props)
                return (<ToggleEditField staticField={obj} {...props} render={ (myProps)=><ResultComponent {...props} {...myProps}/>}/>)
            }
        }
    
        return result;
    });

    return resultingFields;
}

const replaceSpecialCaseFields = (fields) => {
    const results = fields.map(f=>{
        if(f.name==="industry"){
            return industryControlledField
        }
        return f;
    })
    return results
}

const patchableFields = _.flow(replaceSpecialCaseFields, generateOptionalFields, generateToggleEditFields)

const fields = patchableFields([...commonBusinessFields]);
// submitHook is injected into FormControlPanel by buildSimpleSubmissionForm
const FormControlPanel = generateBusinessFormControl({submitLabel: "Edit Business"})

export const EditBusinessForm = buildSimpleSubmissionForm(fields, FormControlPanel)


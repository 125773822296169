import React, { createContext, useContext, useMemo } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useCallback } from 'react';
import { useSelector } from 'providers/AppStateProvider';
import { _selectorUser } from 'providers/AppStateProvider/store';
import { CrdtManager } from './CrdtManager';
import { useVersion } from './VersionProvider';

export const CrdtContext = createContext(null);

export const useCrdt = (versionWrapperRef) => {
  const user = useSelector(_selectorUser)
  const crdtManager = useMemo(() => CrdtManager.getCrdtManager(versionWrapperRef), []);
  crdtManager.setOwner(user);
  return crdtManager;
};

export const CrdtProvider = ({ children }) => {
  const versionWrapperRef = useRef(null);

  const crdtManager = useCrdt(versionWrapperRef);
  const everything = useVersion();
  const { versionData, addVersionData, resetAll } = everything;

// usecallback
  const handleAddVersionData = useCallback((docId, version, opKey) => {
    // Add a new version data to the context state
    addVersionData(docId, version, opKey);
  }, [addVersionData])

  const resetVersionData = useCallback(() => {
    // Reset the version data to the initial state
    resetAll();
  }, [resetAll]);

  useEffect(()=>{
    versionWrapperRef.current = handleAddVersionData;
  }, [handleAddVersionData])



  return (
    <CrdtContext.Provider value={{crdtManager, handleAddVersionData, resetVersionData, versionData}}>
      {children}
    </CrdtContext.Provider>
  );
};


export const useCrdtContext = () => {
  const crdt = useContext(CrdtContext);
  return crdt;
};

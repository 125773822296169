
import { searchForLocationsEventHandlers } from "../event-handlers/search-for-locations";
import { crdtManagerEventHandlers } from "../event-handlers/crdt-manager";


const attachSocketHandlers_ = (socket, handlers, args) => {
    const {eventManager} = args;
    
    if(handlers){
        if(Array.isArray(handlers)){
            handlers.forEach(handlers2=>eventManager.on(handlers2.event, ({args:resources, data}) => handlers2.handler(resources, data)))
            handlers.forEach(handlers2=>socket.on(handlers2.event, (data) => eventManager.emit(handlers2.event, {args, data}))) // socket is included in args already
        }else{
            // socket.on(handlers.event, () => handlers.handler(args))
            eventManager.on(handlers.event, () => handlers.handler(args));
        }
    }
}

// TODO convert onHandlers to attach memoized callbacks hooks instead of attaching new callbacks on every call
// do this by manifesting the callbacks in the useSocket hook by creating a useCallback hook for each handler
export const onHandlers = async (socket, params = {}) => {

    const handlers = [

        // search for locations event handlers
        ...searchForLocationsEventHandlers,

        // crdt event handlers
        ...crdtManagerEventHandlers,
    ];


    // we should be able to push events into the emitter in the handlers
    attachSocketHandlers_(socket, handlers, {socket, ...params});
}
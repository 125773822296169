import * as Actions from "providers/AppStateProvider/actions";

export const chatReducer = (state, action = {}) => {
  const { type, payload } = action
  switch (type) {
    case Actions.SET_CHAT_DOCMANI: {
      return {
        ...state,
        rtcId: payload
      }
    }
    case Actions.SET_RTC: {
      return {
        ...state,
        rtcId: payload
      }
    }
    case Actions.SET_CONNECT_NOT_DISABLED: {
      return {
        ...state,
        connectNotDisabled: payload?.connectNotDisabled
      }
    }
    default:
      return state
  }
}
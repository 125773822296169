import { LoadingState } from "components/LoadingState"
import { ChatRoll } from "providers/CrdtProvider/uiochat/components"
import { IChatOperations } from "providers/CrdtProvider/uiochat/operations"
import { useSelector } from "providers/AppStateProvider"
import { _selectorUser } from "providers/AppStateProvider/store"
import { v4 as uuidV4 } from "uuid";
import { useSharedDocumentHandler, useUpdateWithDocumentId } from "./hooks"
import * as Styles from "./styles"
import { useCrdtContext } from "providers"

/**
 * ChatContent shows the chat content according to peer's documentid
 */
export const ChatContent = (props) => {
    const {versionData} = useCrdtContext();
    const {documentId} = props
    const user = useSelector(_selectorUser);

    const genericHandler = useSharedDocumentHandler({ documentId });

    const {crdt, crdtVersion} = useUpdateWithDocumentId({documentId, deleteResourcesOnUnmount: false});
    
    // If there is no documentId, then theres no chat log to show and we say
    // an initial message according to the chatViewType
    if(!crdt){
        return (
            <Styles.LoadingStateContainer>
                <LoadingState text={"Loading Chat"} />
            </Styles.LoadingStateContainer>
        )
    }

    return (
        <>
            <ChatRoll 
                owner={user.username}
                version={crdtVersion}
                document={crdt} 
                onAdd={(value) => genericHandler({
                        operation:IChatOperations.UnshiftToChatRoll, 
                        payload:{ id: uuidV4(), content:value, author: user.username, fullname: user.fullname },
                        mutationHint:"adding message"
                }
                )}
                onEditChat={(chat) => genericHandler({
                        operation:IChatOperations.ModifyChatInChatRoll, 
                        payload:chat,
                        mutationHint:"editing message"
                    })
                }
                onDeleteChat={(chat) => genericHandler({
                        operation:IChatOperations.ArchiveChatInChatRoll, 
                        payload:{id:chat.id},
                        mutationHint:"deleting message"
                    })
                }

            />
        </>
    )

}



import { colors } from  "config/colors"

export const ButtonMixinSizeType = {
    small:"button-mix-type-small",
    medium:"button-mix-type-medium",
    large:"button-mix-type-large",
}
export const ButtonMixinStyleType = {
    primary:"button-mix-style-type-primary",
    secondary:"button-mix-style-type-secondary",
}

export const buttonMixins=(buttonMixinStyleType, buttonMixinSizeType)=> {
    const {fontColor, fontColorHover, borderColor, defaultColor, hover, active} = 
        buttonMixinStyleType === ButtonMixinStyleType.primary ? 
        {fontColor: colors.white, fontColorHover: colors.white, borderColor: colors.cta, defaultColor:  colors.cta, hover: colors.ctaHover, active:colors.ctaActive} : 
        {fontColor: colors.black, fontColorHover: colors.gray[900],borderColor: colors.black, defaultColor: colors.white, hover: colors.gray[100], active: colors.gray[200]};
    
        let extras = ""
    if(buttonMixinSizeType===ButtonMixinSizeType.large){
        extras = `height: 44px;`

    }else if(buttonMixinSizeType===ButtonMixinSizeType.medium){
        extras = `height: 30px`
    }
    

    return `
        transition: all 0.2s;
        background: ${defaultColor};
        border-radius: 16px;
        border-color: ${borderColor};
        border: 1px solid ${defaultColor};
        color: ${fontColor};
        box-shadow: none;
        ${extras}
        &:hover{
            color: ${fontColorHover};
            background: ${hover};
            border: 1px solid ${hover};
        }
        &:active,&:visited{
            background: ${active};
            border: 1px solid ${active};
        }
    `
}

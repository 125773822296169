
import * as CommonStyles from "../styles";
import { MediumText, TitleHeader } from "components/Text";
import { SignInForm } from "components/Forms";

import { useSignInMutation } from "hooks";
import { SuccessErrorWrapper } from "pages-modular/utils";
import { useGetUser } from "./hooks";
import { ANON_XSRF_TOKEN_SENTINEL } from "utils/constants";
import { useAnonymousTokenFromCookie } from "pages-modular/hooks";
import { useMessaging } from "providers";
import { useCallback } from "react";
import { ISystemMessageClientTypes } from "providers/MessagingProvider/socket-handlers/ISystemMessageClientTypes";


export const SignInFormContent = ({title, description, emailToken}) => {
  const { clientEmit } = useMessaging()
  const signInMutation = useSignInMutation();
  const { getUserAndNavigateTo } = useGetUser();
  const { setAnonymousToken } = useAnonymousTokenFromCookie();

  const onSubmit = useCallback(async (...props) => {
    const [values, { setSubmitting }] = props;

      setSubmitting(true);
      const wrappedResponse = await SuccessErrorWrapper(async ()=>await signInMutation.mutateAsync(emailToken?{...values, emailToken}:values))
      const {success} = wrappedResponse;
      setSubmitting(false);
      if(success){
        setAnonymousToken(ANON_XSRF_TOKEN_SENTINEL);
        console.log("SIGNIN", success)
        getUserAndNavigateTo(success.data.token);

        // emit event to websocket server to refresh user
        clientEmit(ISystemMessageClientTypes.UpdateUserSession, {token: success.data.token});
        
      }
  }, [signInMutation, getUserAndNavigateTo, setAnonymousToken, emailToken, clientEmit])

  return (
    <CommonStyles.ColumnContainer>
      <CommonStyles.CenterAlign>
        <TitleHeader>{title ? title: `Sign In`}</TitleHeader>
        { description && <MediumText>{description}</MediumText> }
      </CommonStyles.CenterAlign>
      <SignInForm onSubmit={onSubmit} submitHook={signInMutation}/>
    </CommonStyles.ColumnContainer>
  );
};
import * as CommonStyles from "../styles";
import { Bolded, MediumText, TitleHeader } from "components/Text";
import { LargeSecondaryButton } from "components/Button";
import { useCloseBottomsheet } from "layout/ModalBottomsheetLayout/hooks";


export const EmailConfirmationMessage = ({email}) => {
    const handleClose = useCloseBottomsheet();
  
    return (
        <CommonStyles.ColumnContainer>
            <CommonStyles.CenterAlign>
                <TitleHeader>You're almost there!</TitleHeader>
                <MediumText>Once you've confirmed that <Bolded>{email}</Bolded> is your email, we will get your UIO.io account set up.</MediumText>
                <LargeSecondaryButton type="button" onClick={handleClose} fluid>Close</LargeSecondaryButton>           
            </CommonStyles.CenterAlign>
        </CommonStyles.ColumnContainer>
    );
};

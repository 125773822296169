import * as CommonStyles from "../styles";
import { TitleHeader } from "components/Text";
import { SignUpForm } from "components/Forms";

import { useSignUpMutation } from  "hooks";
import { useModularNavigation } from "layout/ModalBottomsheetLayout/hooks";
import { IPagesModularScreen } from "pages-modular/types";
import { SuccessErrorWrapper } from "pages-modular/utils";
import { useCallback } from "react";

export const SignUpFormContent = () => {
  
  const signUpMutation = useSignUpMutation();

  const {navigateToAlways} = useModularNavigation()

  const clearAllStates = useCallback(
    () => {
      // Clear session storage
      sessionStorage.clear();
      
      // Clear local storage
      localStorage.clear();
      
      // Clear cookies
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`;
      }
    }, [])
  
  const onSubmit = async (...props) => {
    const [values, { setSubmitting }] = props;

      clearAllStates();

      setSubmitting(true);
      const wrappedResponse = await SuccessErrorWrapper(async ()=>await signUpMutation.mutateAsync(values))
      const {success} = wrappedResponse;
      setSubmitting(false);
      if(success){
        navigateToAlways({screen:IPagesModularScreen.SignIn, props: {title: "Sign In", description: "Use the credentials you just created to sign in"}})
      }
  };

  
  return (
    <CommonStyles.ColumnContainer>
      <CommonStyles.CenterAlign>
        <TitleHeader>Sign Up</TitleHeader>
      </CommonStyles.CenterAlign>
      <SignUpForm onSubmit={onSubmit} submitHook={signUpMutation}/>
    </CommonStyles.ColumnContainer>
  );
};



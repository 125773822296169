import { Typography } from "antd";
import * as Styled from "../styles";
import { HelmetCreator } from "pages/components/HelmetCreator";
import { useSelector } from "providers/AppStateProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEmailConfirmationMutation } from "hooks";
import { SuccessErrorWrapper } from "pages-modular/utils";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useGetUser } from "pages-modular/SignInFormContent/hooks";
import { _selectorUser } from "providers/AppStateProvider/store";
import { IAccountMode, useAccountMode } from "pages-modular/hooks";
import { useOpenBottomsheetIfClosed } from "layout/ModalBottomsheetLayout/hooks";
import { IPagesModularScreen } from "pages-modular/types";

const { Title, Paragraph } = Typography;

export const EmailConfirmation = () => {
  const accountMode = useAccountMode();
  const preventNavToBottomsheetRef = useRef(false);
  const [params] = useSearchParams();
  const preventRef = useRef(false);
  const {mutateAsync: emailConfirmationMutation } = useEmailConfirmationMutation();
  const { getUserThenUpdateRedux } = useGetUser();
  const navigateToUrl = useNavigate();
  const emailToken = useMemo(()=>params.get("emailToken")?params.get("emailToken"):null,[params])

  const openBottomsheetIfClosed = useOpenBottomsheetIfClosed();

  const user = useSelector(_selectorUser)
  const { isEmailConfirmed } = user;


 useEffect(()=>{
  if(preventNavToBottomsheetRef.current || accountMode===IAccountMode.MEMBER_OR_ADMIN || !emailToken || emailToken===""){
    return;
  }
  preventNavToBottomsheetRef.current = true; 
  preventRef.current = true

  openBottomsheetIfClosed(IPagesModularScreen.SignIn, {emailToken,description: "Sign in to confirm your account"})
 }, [accountMode, emailToken, openBottomsheetIfClosed, params])

  const emailConfirmationMutationCb = useCallback(async ()=>{
    if(!isEmailConfirmed && !preventRef.current && emailToken){
      const { success, error } = await SuccessErrorWrapper(async ()=> await emailConfirmationMutation({emailToken}))
      preventRef.current = true;
      if(success){
        getUserThenUpdateRedux();
        navigateToUrl("/")
      }else if(error){
        navigateToUrl("/uio/error")
      }
    }
  },[emailConfirmationMutation, getUserThenUpdateRedux, isEmailConfirmed, navigateToUrl, emailToken])

  useEffect( ()=>{
    if(!emailConfirmationMutation || !emailConfirmationMutationCb || !getUserThenUpdateRedux || !navigateToUrl || !params ){
      return;
    }
    if(!preventRef.current){
      emailConfirmationMutationCb();
      preventRef.current = true;
    }
  }, [emailConfirmationMutation, emailConfirmationMutationCb, getUserThenUpdateRedux, isEmailConfirmed, navigateToUrl, params])

    const renderConfirmationStatus = isEmailConfirmed ? <YourAccountIsConfirmed /> : <ConfirmingYourAccount /> ;
    
    return (
        <>
        <HelmetCreator title={"Email Confirmation"} />

        <Styled.Container>
            <Typography>
            <Title>Email Confirmation</Title>

            {renderConfirmationStatus}

            </Typography>
        </Styled.Container>
        </>
    );
};


const ConfirmingYourAccount = () => {
  return (
    <>
          <Paragraph>
            Confirming your account ...
          </Paragraph>
    </>
  );
};


const YourAccountIsConfirmed = () => {
  return (
    <>
          <Paragraph>
            Your account is now confirmed. You can continue on your current session
          </Paragraph>
          <Paragraph>
            Thank you again for creating your UIO.io account!
          </Paragraph>
          {/* TODO include the search range bar here */}
    </>
  );
};

import { ICrdtType } from "providers/CrdtProvider/crdtFactory";
import { IPromptType } from "providers/CrdtProvider/prompts/chat";
import { IEmitterHandlerEvents } from "..";

/**
 * return false if the user is not allowed to perform the action 
 * returns true if the user is allowed to perform at least one action
 * @param {*} crdtKeys 
 * @param {*} emitter 
 */
export const transformCrdtKeysIntoActions = ({crdtKeys}) => {
    if(crdtKeys.length!==1){
        console.warn("Please transformCrdtKeysIntoActions to take multiple crdtKeys. it should take 1 item", crdtKeys)
    }

    const { 
        crdtKey,
        crdtType,
        promptType,
     } = crdtKeys[0];

     if(!crdtKey || !crdtType || !promptType){
        return {
            error: "The following must ALL be defined: crdtKey, crdtType, promptType"
        }
     }

     if( crdtType === ICrdtType.uiochat && promptType === IPromptType.LOCATION_THEN_CHAT ){
        return {
            success: {
                event: IEmitterHandlerEvents.GEOCOLLABORATION_CHAT,
                payload: {
                    documentManifest: {_id:crdtKey, forDocumentType:crdtType},
                }
            }
         }
     }
     return {
        error: "The following cases for the values is not caught: crdtType="+crdtType+", promptType="+promptType
     }

}

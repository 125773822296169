import { ANON_XSRF_TOKEN_SENTINEL, XSRF_TOKEN_SENTINEL } from "utils/constants";
import { useAnonymousTokenFromCookie, useTokenFromCookie } from "pages-modular/hooks";
import { useCallback } from "react";
import { useGetMyAnonymousToken } from "hooks/useGetMyAnonymousToken";
import { SuccessErrorWrapper } from "pages-modular/utils";
import { IUserCommonType, useGetUser } from "pages-modular/SignInFormContent/hooks";
import { useSignOutMutation } from "hooks/useSignOutMutations";
import { errorLog } from "utils";
import { useDispatch } from "providers/AppStateProvider";
import * as Actions from "providers/AppStateProvider/actions";
import { fetchUserInfoAxios } from "hooks";

/**
 * 
 * @returns handler when called will get an anonymous user token and retrieve user object for it. handler returns success.
 */
export const useHandleAnonymousTokenAndUser = () => {
  const dispatch = useDispatch();
  const{ token, setToken } = useTokenFromCookie();
  const { anonymousToken, setAnonymousToken } = useAnonymousTokenFromCookie();
  const logOutUser = useSignOutMutation();
  const getAnonymousToken = useGetMyAnonymousToken()
  const { getUserThenUpdateRedux } = useGetUser();
  

  const resolveUser = useCallback(async () => {
    // check to see if the XSRF_TOKEN cookie is set to null or XSRF_TOKEN_SENTINEL.
    if (token === XSRF_TOKEN_SENTINEL || token === null) {
      // if it is then check if there is a valid anonymous token in cookie by fetching user object from usersme end point
      const { success, error } = await SuccessErrorWrapper(()=>fetchUserInfoAxios(IUserCommonType.Anonymous));
      
      // if we get an error from that end point, we know the token is invalid and we need to get a new one.
      if (error) {
        
        // we do that with the getAnonymousToken.mutateAsync function
        const { success: success2, error: error2 } = await SuccessErrorWrapper(()=>getAnonymousToken.mutateAsync());
        if(error2){
          // if we get an error from the getAnonymousToken.mutateAsync function, we log it out and return null.
          errorLog(error2);
          return null;
        }
        if(success2){
          // we set the XSRF_TOKEN cookie to XSRF_TOKEN_SENTINEL to indicate that we are using an anonymous token
          setToken(XSRF_TOKEN_SENTINEL);
          // we set the ANON_XSRF_TOKEN cookie to the new token.
          setAnonymousToken(success2.data.token);

          // we then get the user object for the anonymous user and update the redux store.
           await getUserThenUpdateRedux(success2.data.token, IUserCommonType.Anonymous);
        }
      }
      if(success){
        // if we get a success from the usersme end point, we know the token is valid and update the redux store with the user object
        
        dispatch({type: Actions.SET_USER_INFO, payload: success.data.user})
      }
      return;
      // else if both XSRF_TOKEN and XSRF_ANON_TOKEN are null or undefined, we need to get a new anonymous token and user object
    } else if ( (!token && !anonymousToken) || (token === XSRF_TOKEN_SENTINEL && anonymousToken === ANON_XSRF_TOKEN_SENTINEL) ) {
      const { success, error } = await SuccessErrorWrapper(()=>getAnonymousToken.mutateAsync());
      if(error){
        errorLog(error);
      }
      if(success){
        setToken(XSRF_TOKEN_SENTINEL);
        setAnonymousToken(success.data.token);
        await getUserThenUpdateRedux(success.data.token, IUserCommonType.Anonymous);
      }
      return;
    }



    // if the XSRF_TOKEN cookie is not null or XSRF_TOKEN_SENTINEL, we need to see if it is a valid user token or not.
    // we do this by fetching the user object from the usersme end point using the token in the XSRF_TOKEN cookie
    const { success, error } = await SuccessErrorWrapper(()=>fetchUserInfoAxios(IUserCommonType.User));

    // if we get an error, we know the token is invalid and we need to log the user out by calling the logOutUser.mutateAsync function, deleting the XSRF_TOKEN cookie, and fetching an anonymous user token
    if (error) {
      const { success: success2, error: error2 } = await SuccessErrorWrapper(()=>logOutUser.mutateAsync());
      if(error2){
        errorLog(error2);
      }
      if(success2){
        const { success: success3, error: error3 } = await SuccessErrorWrapper(()=>getAnonymousToken.mutateAsync());
        if(error3){
          errorLog(error3);
          return null;
        }
        if(success3){
          setToken(XSRF_TOKEN_SENTINEL);
          setAnonymousToken(success3.data.token);
          await getUserThenUpdateRedux(success3.data.token, IUserCommonType.Anonymous);
        }
      }
      return;
    }

    // if we get a success, we know the token is valid and we update the redux store with the user object
    if(success){
      dispatch({type: Actions.SET_USER_INFO, payload: success.data.user})
    }


  }, [token, setToken, anonymousToken, setAnonymousToken, getAnonymousToken, getUserThenUpdateRedux, dispatch]);

  // this function is used to log out the user
  // it calls the logOutUser.mutateAsync function, deletes the XSRF_TOKEN cookie, and fetches an anonymous user token, and updates the redux store with the anonymous user object
  const handleLogOut = useCallback(async () => {
    const { success, error } = await SuccessErrorWrapper(()=>logOutUser.mutateAsync());
    if(error){
      errorLog(error);
      return null;
    }
    if(success){
      const { success: success2, error: error2 } = await SuccessErrorWrapper(()=>getAnonymousToken.mutateAsync());
      if(error2){
        errorLog(error2);
        return null;
      }
      if(success2){
        setToken(XSRF_TOKEN_SENTINEL);
        setAnonymousToken(success2.data.token);
        await getUserThenUpdateRedux(success2.data.token, IUserCommonType.Anonymous);
        return success2.data.token;
      }
    }
    return null;
  }, [logOutUser, getAnonymousToken, setToken, setAnonymousToken, getUserThenUpdateRedux]);
  

  return {resolveUser, handleLogOut}
};



import { useMutation } from "@tanstack/react-query";
import { Urls } from "./config";
import { axiosFactory } from "./utils/axiosFactory";


// response from useSignUpMutation should never be cached
export const useCreateBusinessMutation = () =>  
    useMutation((payload) => {
      return axiosFactory({useTokenFromCookie:true, userCommonType:false}).post(`${Urls.businessApi}`, payload)
    }, {
      cacheTime: 0,
      staleTime: 0,
      useErrorBoundary: false,
    })
  
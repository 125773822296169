import React from 'react';
import { Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import * as Styled from "./styles"

library.add(faUser);


export const DropdownButton = ({ icon, label, items, placement }) => {
  const menu = (
    <Menu>
      {items.map((item) => (
        <Menu.Item key={item.key}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Styled.StyledDropdownButton overlay={menu} placement={placement}>
      <Styled.StyledButton>
        <FontAwesomeIcon icon={icon} />
        {label}
      </Styled.StyledButton>
    </Styled.StyledDropdownButton>
  );
};

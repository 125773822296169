// import { ISystemMessageClientTypes } from "providers/MessagingProvider/socket-handlers/ISystemMessageClientTypes";
import { IEmitterHandlerEvents } from "providers/MessagingProvider/emitter-handlers";
import { ISystemMessageServerTypes } from "providers/MessagingProvider/socket-handlers/ISystemMessageServerTypes";
import { errorLog } from "utils";

const getResourcePayload = ()=>{

    // data if user refreshes page
    const sessionCrdtKeys =  JSON.parse(sessionStorage.getItem("crdtKeys") || "[]");


    const payload = {crdtKeys: sessionCrdtKeys}
    return payload
}

export const crdtManagerEventHandlers =[
    {
        event: ISystemMessageServerTypes.ResourcesInconsistent,
        handler: ({eventManager}) => {
            try{
                const payload = getResourcePayload();
                // CODE BREAKS SOMEWHERE AFTER THIS LINE;
                eventManager.emit(IEmitterHandlerEvents.TRANSFORM_TO_EMITTER_ACTIONS, payload) 
            }catch(error){ 
                errorLog("error in ResourcesInconsistent event handler", error)
            }

        }
    },
    {
        event: ISystemMessageServerTypes.CrdtMutateData,
        handler: ({ crdtManager }, data ) => {
            const {key, operation, user, payload, mutationHint, opKey, claimant} = data
            const success = crdtManager.mutateCrdt({
                key, operation, payload, user, mutationHint, opKey, claimant
            })

        }
    },
    {
        event: ISystemMessageServerTypes.CrdtManagementOperation,
        handler: ({ crdtManager, socket }, data ) => {
            const {operation, payload, user, passport: {socketOriginator}, claimant} = data
            const success = crdtManager.managementOperation({
                operation, payload, user, passport: {socketOriginator}, claimant
            })

            // // find a better way to send these errors to uioio, we don't want a blast of errors. maybe use a 3rd party error logging service
            // if(success.result.toLowerCase().indexOf("error")===0){
            //     socket.emit(ISystemMessageClientTypes.Error, {error:"failed attempt to crdt management operation with data", data})
            // }
        }
    }
]


import { ModalBottomsheetLayout } from 'layout/ModalBottomsheetLayout';
import { EmailConfirmation } from "pages/EmailConfirmation"
import { GetStarted } from 'pages/GetStarted';
import { GoChat } from "pages/GoChat"
import {
  BrowserRouter,
  Routes, // instead of "Switch"
  Route,
} from 'react-router-dom'
import { Error } from 'pages/Error';
import { useEffect, useRef } from 'react';
import { useHandleAnonymousTokenAndUser } from 'pages-modular/UserSettingsContent/hooks/useHandleAnonymousTokenAndUser';
import { useShouldGetAnonymousCookieToken } from 'pages-modular/hooks';
import { useDispatch, useSelector } from 'providers/AppStateProvider';
import { _selectorUser } from 'providers/AppStateProvider/store';
import * as Actions from "providers/AppStateProvider/actions";
import useCookie from 'react-use-cookie';
import { USER_INFO_COOKIE } from 'utils/constants';

function MainRoutes(props) {

  const {resolveUser} = useHandleAnonymousTokenAndUser();
  const noTokenResult = useShouldGetAnonymousCookieToken();
  const preventRef = useRef(false);
  const user = useSelector(_selectorUser);
  const dispatch = useDispatch();
  const [userInfo]= useCookie(USER_INFO_COOKIE,"{}")
  const preventUserInfoRef = useRef(false);
 
  // if user info is in cookie but not in react app then refresh redux state with it
  useEffect(()=>{
    if(user && !user.id && userInfo && !preventUserInfoRef.current){
      preventUserInfoRef.current = true;
      dispatch({type: Actions.SET_USER_INFO, payload: JSON.parse(userInfo)});
      return;
    }
  }, [user, userInfo])
  
  useEffect(()=>{
    const initializeTokenAndUserCb = async () => {
      if(!resolveUser){
        return;
      }
      if(noTokenResult && !preventRef.current){

        // very important to preempt prior to doing an async post for new anonymous token
        // will stop extra posts
        preventRef.current = true;
        
        await resolveUser();

      }
    }
    initializeTokenAndUserCb();
  }, [noTokenResult, resolveUser])

  return (
    <BrowserRouter>
        <Routes>
              {/* root layout start*/}

                    <Route index element={<ModalBottomsheetLayout ><GetStarted /></ModalBottomsheetLayout>} />        

                    {/* uio layout start*/}   
                    <Route exact path="uio/email-confirmation" element={<ModalBottomsheetLayout ><EmailConfirmation /></ModalBottomsheetLayout>} />
                    <Route exact path="uio/error" element={<ModalBottomsheetLayout ><Error /></ModalBottomsheetLayout>} />
                    <Route exact path="uio/go" element={<ModalBottomsheetLayout ><GoChat /></ModalBottomsheetLayout>} />
                    {/* uio layout end*/}


              {/* root layout end*/}
        </Routes>
    </BrowserRouter>
  );
}

export default MainRoutes;

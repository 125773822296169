/**
 * All the handlers that can control the bottomsheet directly should live here
 */

import * as Actions from "providers/AppStateProvider/actions";
import { useAppState, useDispatch, useSelector } from "providers/AppStateProvider";
import { useCallback, useRef } from "react";
import { IPagesModularScreen } from "pages-modular/types";
import { _selectorBottomsheetLayout, _selectorPageModularScreen } from "providers/AppStateProvider/store";
import { IBottomsheetBehavior } from "providers/AppStateProvider/reducers/pageModularScreenReducer";
import { usePagesModularStackContext } from "providers/PagesModularStackProvider";
import "react-spring-bottom-sheet/dist/style.css";
import { useMediaQuery } from 'react-responsive';
import { useMemo } from "react";

export const ILayoutMode = {
  bottomsheet: "bottomsheet",
  modal: "modal",
  drawer: "drawer"
}



export const bottomsheetOpenAction = (dispatch, payload) => {

  dispatch({ type: Actions.BOTTOMSHEET_OPEN, payload });
}

/** must only be used if the bottomsheet is most likely not open and should be opened */
export const useOpenBottomsheetOn = (screen, props, behavior=IBottomsheetBehavior.Priority) => {
  const {open:isBottomsheetOpen} = useSelectBottomsheet();
  const dispatch = useDispatch();
  const handle = useCallback(()=>{
    if(isBottomsheetOpen){
      // will prevent setting screen and props if bottomsheet is already open
      return;
    }
    dispatch({ type: Actions.BOTTOMSHEET_OPEN, payload: true });
    dispatch({type: Actions.CHANGE_SCREEN_AND_PROPS, payload:{screen, props, behavior} })
  }, [behavior, dispatch, isBottomsheetOpen, props, screen])
  return handle
}

export const useNavigateToIfOnScreen = (conditionalScreen, {screen, props, behavior=IBottomsheetBehavior.Priority}) => {
  const {navigateToIfOpenOn} = useModularNavigation();
  const handle = useCallback(()=>{
    navigateToIfOpenOn(conditionalScreen, {screen, props, behavior});
  }, [behavior, conditionalScreen, navigateToIfOpenOn, props, screen]);
  return handle
}

export const useNavigateToIfClosed = (pagesModularObject) => {
  const {navigateToIfClosed} = useModularNavigation();
  const handle = useCallback(()=>{
    navigateToIfClosed(pagesModularObject);
  }, [ navigateToIfClosed, pagesModularObject]);
  return handle
}

export const useSelectBottomsheet = () => {
  const bottomsheetStates = useSelector(_selectorBottomsheetLayout)
  return bottomsheetStates;
}

export const useSelectPageModular = ()=>{
  const result = useSelector(_selectorPageModularScreen);
  return result;
}

export const useCloseBottomsheet= () => {
  const dispatch = useDispatch()
  return useCallback(()=> bottomsheetOpenAction(dispatch, false),
    [dispatch])
}

export const useCloseBottomsheetIfScreen = () => {
  const {screen} = useSelector(_selectorPageModularScreen);
  const dispatch = useDispatch()
  const handle = useCallback((pagesModularScreen)=> {
    if(screen===pagesModularScreen){
      bottomsheetOpenAction(dispatch, false)
    }
  },
    [dispatch, screen])
  return handle
}


export const useOpenBottomsheetIfClosed = () => {
  const {open} = useSelectBottomsheet();
  const dispatch = useDispatch();
  const handler = useCallback((screen, props, behavior)=>{
    if(open || !dispatch || !screen){
      return;
    }

    dispatch({ type: Actions.BOTTOMSHEET_OPEN, payload: true });
    dispatch({type: Actions.CHANGE_SCREEN_AND_PROPS, payload:{screen, props, behavior} })

  },[open, dispatch])
  return handler
}

export const useModalBottomsheetLayoutHandlers = ()=>{

  const {dispatch} = useAppState();

  const {open: openBottomsheet} = useSelectBottomsheet();

  const closeBottomsheetIfScreen = useCloseBottomsheetIfScreen();
    
  const handleSignUp = useOpenBottomsheetOn(IPagesModularScreen.SignUp, null)

  const handleSignIn = useOpenBottomsheetOn(IPagesModularScreen.SignIn, null)

  // user settings START
  const userSettingsPageModularObj = {screen:IPagesModularScreen.UserSettings, props:null, behavior:IBottomsheetBehavior.Priority}
  const handleUserSettingsIfOnScreen = useNavigateToIfOnScreen(IPagesModularScreen.Chat, userSettingsPageModularObj);
  const handleUserSettingsIfClosed = useNavigateToIfClosed( userSettingsPageModularObj );
  const handleUserSettings = useCallback(() => {
    handleUserSettingsIfOnScreen();
    handleUserSettingsIfClosed();
  }, [handleUserSettingsIfOnScreen, handleUserSettingsIfClosed]);

  // user settings END

  

  const { pop,  count } = usePagesModularStackContext();
  const modularNavigation = useModularNavigation()
  const overlayClicks = useRef(false);
  
  const handleOverlayClick = useCallback(() => {
    overlayClicks.current = true;
    if(count){ 

      const top = pop();
      modularNavigation.navigateToAlways(top);
      return
    }

    return bottomsheetOpenAction(dispatch, !openBottomsheet);
  },
    [count, dispatch, modularNavigation, openBottomsheet, pop]);

  const onDismiss = useCallback(()=> {
    dispatch({type: Actions.BOTTOMSHEET_HEIGHT, payload: -1}) // sentinel value
    bottomsheetOpenAction(dispatch, false)
  },
    [dispatch])

  return {handleSignUp, handleSignIn, handleUserSettings, handleOverlayClick, onDismiss, closeBottomsheetIfScreen}
}

/**
 * will change bottomsheet content even if the bottomsheet is closed
 */
export const useModularNavigation = () => {
  const {open:isBottomsheetOpen} = useSelectBottomsheet();
  const openBottomsheetIfClosed = useOpenBottomsheetIfClosed();
  const pageModularState = useSelectPageModular()
  const { push, pop, reset } = usePagesModularStackContext()
  const dispatch = useDispatch();

  const resetNavigation = useCallback(()=>{
    reset();
  }, [reset])

  const navigateToAlways = useCallback(({screen, props=null, behavior=IBottomsheetBehavior.Priority})=>{
    dispatch({type: Actions.CHANGE_SCREEN_AND_PROPS, payload: {screen, props, behavior} })}, [dispatch]
  )

  const navigateToIfOpenOn = useCallback((conditionalScreen, {screen, props=null, behavior=IBottomsheetBehavior.Priority, showBack=false})=>{
    const {screen: currentScreen, props: currentProps, behavior: currentBehavior} = pageModularState;
    if(isBottomsheetOpen && pageModularState.screen===conditionalScreen && currentScreen){
      const currentItem = {screen: currentScreen, nonprimaryScreens:[], props: currentProps, behavior: currentBehavior}
      push(currentItem)
      dispatch({type: Actions.CHANGE_SCREEN_AND_PROPS, payload: {screen, props, behavior, options:{showBack}} })
    }
  }, [dispatch, isBottomsheetOpen, pageModularState, push])

  const pushOntoNavStackIfOpenOn = useCallback((conditionalScreen, {screen, nonprimaryScreens=[], props=null, behavior=IBottomsheetBehavior.Priority, showBack=false})=>{
    const {screen: currentScreen, nonprimaryScreens: currentNonprimaryScreens, props: currentProps, behavior: currentBehavior} = pageModularState;

    // canNavTo is true if the current screen is the conditional screen or if the current screen is the last screen in the nonprimaryScreens array
    let canNavTo = (nonprimaryScreens.length===0 && (pageModularState.screen===conditionalScreen) )
    || (nonprimaryScreens.length>0 && nonprimaryScreens[nonprimaryScreens.length-1]===conditionalScreen) || false;

    if(isBottomsheetOpen && canNavTo && currentScreen){
      const currentItem = {screen: currentScreen, nonprimaryScreens: currentNonprimaryScreens, props: currentProps, behavior: currentBehavior}
      const newNonprimaryScreens = [ ...currentNonprimaryScreens, {screen,props, nonprimaryScreens, behavior}]
      dispatch({type: Actions.CHANGE_SCREEN_AND_PROPS, payload: {screen: currentScreen, props: currentProps, behavior: currentBehavior, nonprimaryScreens: newNonprimaryScreens, options:{showBack}} })
    }
  }, [dispatch, isBottomsheetOpen, pageModularState, push])

  const popFromNavStackIfOpenOn = useCallback((conditionalScreen)=>{


    let currentScreenObj = null;
    const isInNonprimaryScreens = pageModularState.nonprimaryScreens&&pageModularState.nonprimaryScreens.length>0 && pageModularState.nonprimaryScreens[pageModularState.nonprimaryScreens.length-1].screen === conditionalScreen
    if(isInNonprimaryScreens){
      currentScreenObj = pageModularState.nonprimaryScreens[pageModularState.nonprimaryScreens.length-1]
    }else{
      currentScreenObj = pageModularState
    }
    const currentScreen = currentScreenObj.screen;
    if(currentScreen!==conditionalScreen){
      return;
    }

    if(isInNonprimaryScreens){
      const newNonprimaryScreens = [...pageModularState.nonprimaryScreens];
      newNonprimaryScreens.pop();
      const payload = {...pageModularState, nonprimaryScreens:newNonprimaryScreens, options:{showBack: false}}
      dispatch({type: Actions.CHANGE_SCREEN_AND_PROPS, payload})

    }
  }, [dispatch, pageModularState])

  const getCurrentScreenObject = useCallback(()=>{

    const {screen: currentScreen, nonprimaryScreens: currentNonprimaryScreens} = pageModularState;
    const myCurrent = currentNonprimaryScreens.length>0 ? currentNonprimaryScreens[currentNonprimaryScreens.length - 1] : currentScreen;
    return myCurrent;
  }, [pageModularState])


  const navigateToIfClosed = useCallback(({screen, props=null, behavior=IBottomsheetBehavior.Priority})=>{
    if(!isBottomsheetOpen){
      openBottomsheetIfClosed(screen, props, behavior)
    }
  }, [openBottomsheetIfClosed, isBottomsheetOpen])

  return {navigateToAlways, navigateToIfOpenOn, pushOntoNavStackIfOpenOn, navigateToIfClosed, resetNavigation, popFromNavStackIfOpenOn, getCurrentScreenObject} 
}



export const useLayoutMode = () => {

  // Media query for desktop
  const isDesktopOrLaptop = useMediaQuery({
      query: '(min-width: 1224px)'
  });

  // Media query for tablets and smaller devices
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  const { behavior } = useSelectPageModular();

  const layoutMode = useMemo(()=>{
      if( isDesktopOrLaptop && behavior && behavior === IBottomsheetBehavior.FixedLeftSideDrawer){
          return ILayoutMode.drawer
      }
      if( isDesktopOrLaptop ) {
          return ILayoutMode.modal
      }
      if(isTabletOrMobile){
          return ILayoutMode.bottomsheet
      }
      return null;
  }, [behavior, isDesktopOrLaptop, isTabletOrMobile])
  return layoutMode;
};

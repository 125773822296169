import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { Urls } from "./config";


// response from useSignUpMutation should never be cached
export const useSignUpMutation = () =>  
    useMutation((payload) => axios.post(`${Urls.userApi}`, payload), {
      cacheTime: 0,
      staleTime: 0,
      useErrorBoundary: false,
    })
  

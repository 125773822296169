import styled from "styled-components";
import { Field } from "formik";
import { fieldStyles } from "./mixins";
import { colors } from "config/colors";

export const FieldInputRegular = styled(Field)`
${fieldStyles}
width: 100%;
`

export const FieldInputMimic = styled.div`
${fieldStyles}
border: 1px solid ${colors.gray[800]};
width: 100%;
display: flex;
align-items: center;
overflow: hidden;
`
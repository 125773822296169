

import { useMessaging } from "providers"
import { useCrdtContext } from "providers/CrdtProvider"
import { ICrdtType } from "providers/CrdtProvider/crdtFactory"
import { ICRDTActionResult } from "providers/CrdtProvider/CrdtManager"
import { IPromptType } from "providers/CrdtProvider/prompts/chat"
import { useGetVersion } from "providers/CrdtProvider/VersionProvider"
import { ISystemMessageClientTypes } from "providers/MessagingProvider/socket-handlers/ISystemMessageClientTypes"
import { useCallback, useEffect, useRef, useState } from "react"
import { errorLog } from "utils"

/**
 * ChatContent shows the chat content according to peer's documentid
 */
export const useUpdateWithDocumentId = ({documentId, deleteResourcesOnUnmount}) => {
    const {clientEmit} = useMessaging()

    const [crdt, setCrdt] = useState(null)
    const crdtVersion = useGetVersion(documentId)
    const preventConfirmationRef = useRef(false);
    // send ResourceOnboardedSuccessfully to server on crdt
    useEffect(()=>{

        const cb = async () => {

            if(!crdt || !documentId || !clientEmit || preventConfirmationRef.current){ return; }
            
            preventConfirmationRef.current = true;

            clientEmit(ISystemMessageClientTypes.DocumentManifestOnboardedSuccess, {documentManifestId: documentId})

        }
        cb()
    }, [crdt, documentId, clientEmit])

    // crdt things start here
    const {crdtManager} = useCrdtContext();

    const ring = useCallback((myDocId) => {
        const getting = crdtManager.getCrdt(myDocId);
        let {result, crdt: storedCrdt} = getting;
        if(result!==ICRDTActionResult.ErrorGetCrdt) {
            setCrdt(storedCrdt);
        }
    }, [crdtManager, setCrdt])    

    // delete crdt on unmount
    useEffect(()=>{
        return ()=>{
            if(documentId && crdtManager && deleteResourcesOnUnmount){

                // this will allow the user to remain connected to the document and be searchable by others connected to it.
                clientEmit(ISystemMessageClientTypes.SocketLeaveDocumentManifest, {documentManifestId: documentId})
                const {result, message} = crdtManager.deleteCrdt(documentId);
                if(result!==ICRDTActionResult.SuccessfulDeletion){
                    errorLog("error deleting crdt", result, "message: ", message)
                    return;
                }
                
                // remove from session storage crdtKeys
                const crdtKeys = JSON.parse(sessionStorage.getItem("crdtKeys")) || [];

                // iterate through and see if the key is already in the array by using the crdtKey property
                const isInArray = crdtKeys.some(({crdtKey})=>{
                    return crdtKey===documentId;
                })

                if(!isInArray){ return; }
                
                const index = crdtKeys.findIndex(({crdtKey})=>{
                    return crdtKey===documentId;
                })

                if (index > -1) {
                    crdtKeys.splice(index, 1);
                }
                sessionStorage.setItem("crdtKeys", JSON.stringify(crdtKeys));
            
            }
        }
    }, [clientEmit, crdtManager, documentId])

    useEffect(()=>{
        ring(documentId);
    }, [crdtVersion, documentId, ring])

    useEffect(() => {
        const cb = ()=>{
            // if there is no documentId, then we don't need to do anything
            if(!documentId || !crdtManager || !clientEmit) return;

            // get the crdt
            const getting = crdtManager.getCrdt(documentId);
            let {result, crdt: storedCrdt} = getting;
            // if there is no crdt, then we need to create a new crdt
            if(result!==ICRDTActionResult.ErrorGetCrdt) {
                const { result, crdt: crdt_ } = crdtManager.createNewCrdt({key: documentId, crdtType: ICrdtType.uiochat, promptType: IPromptType.LOCATION_THEN_CHAT});
                if(result===ICRDTActionResult.SuccessfulCreation){

                    storedCrdt = crdt_;
                    clientEmit(ISystemMessageClientTypes.CrdtCreateDocument, {message:result})
                }
            }

            // if the crdt is not a uiochat, then we don't need to do anything
            const typeResults = crdtManager.getType(documentId)
            const {result: typeResult, crdtType} = typeResults;
            if(typeResult !== ICRDTActionResult.SuccessfulGetType || crdtType !== ICrdtType.uiochat){
                errorLog("type does not exit typeResult=", typeResult, "crdtType=", crdtType, "documentId=", documentId)
                return;
            }
            setCrdt(storedCrdt)
        }
        cb();

    }, [documentId, crdtManager, clientEmit, setCrdt])
    

    return {
        crdt, crdtVersion
    }

}


import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { Urls } from "./config";

const oneDayInSeconds = 60 * 60 * 24;

// response from useSignInMutation should never be cached
export const useGetMyAnonymousToken = () =>  
    useMutation(() => axios.post(`${Urls.userApi}/anonymous-user`), {
      cacheTime: oneDayInSeconds,
      staleTime: oneDayInSeconds,
      useErrorBoundary: false,
    })
  

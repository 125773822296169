



export const IPromptType = {
    LOCATION_THEN_CHAT: "LOCATION_THEN_CHAT",
  }
  
export  const IPrompt = {
    [IPromptType.LOCATION_THEN_CHAT]: "i clicked to connect from the start page and i am navigated to the webrtc conference and chat page"
  }


import { LargePrimaryButton } from "components/Button";
import { useOpenBottomsheetIfClosed } from "layout/ModalBottomsheetLayout/hooks";
import { useAccountMode, useToken } from "pages-modular/hooks";
import { IPagesModularScreen } from "pages-modular/types";
import { useCallback } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "providers/AppStateProvider";
import { _selectorSearch, _selectorUser } from "providers/AppStateProvider/store";
import { DistanceSlider, maxYardLabel } from "./components/DistanceSlider";
import * as Styled from "./styles";
import { useEffect } from "react";
import { useNavToChatOnRtcId } from "./hooks";
import { MediumErrorText } from "components/Text";
import * as Actions from "providers/AppStateProvider/actions"
import { ISystemMessageClientTypes } from "providers/MessagingProvider/socket-handlers/ISystemMessageClientTypes";
import { useMessaging } from "providers";
import { LoadingState } from "../../components/LoadingState";
import { usePositionData } from "providers/PositionProvider";

const maxAnonymousDistance = maxYardLabel; // semantically more accurate, and can be different than the initialDistanceSearch if required

export const copy = {
  anonymousNotAllowed: "Anonymous users are not allowed to connect with other users in this area. Please Sign In or Create Account.",
  signInReason: "Sign in to communicate with a colleague who is more than 100 yards away"
}

// use this to experiment with socket.io
// window.socketio = socketio;

export const GetStarted = () => {
  const {latitude, longitude, error:gpsError} = usePositionData();
  const {socketIsConnected, clientEmit} = useMessaging()
  const dispatch = useDispatch();
  const [distance, setDistance] = useState(maxAnonymousDistance)
  const openBottomsheetIfClosed = useOpenBottomsheetIfClosed()
  const user = useSelector(_selectorUser)

  // // search related state management since its now in redux
  const {error: searchResultsError, rebuffed} = useSelector(_selectorSearch);


  // // navigate to chat room page once the chat room document manifest is set
  useNavToChatOnRtcId();

  const accountMode = useAccountMode();
  const {token} = useToken();

  const handleConnect = useCallback(()=>{
      // console log all the conditions to see if we can connect
      if(!clientEmit){
        
        return ()=>{
        };
      }
      if(user.distanceToEnforceAuthentication && user.distanceToEnforceAuthentication < distance){
        openBottomsheetIfClosed(IPagesModularScreen.SignIn, { connectOnSignIn: true, description: copy.signInReason });
        dispatch({type: Actions.SET_SEARCH_REBUFFED, payload: true})
        
        return ()=>{};
      }
        // console log the conditions
      if(socketIsConnected && !gpsError && token){
        // reset preventSearchResultsError to false
        dispatch({type: Actions.SET_SEARCH_RESULTS, payload: []})
        // we can probably remove token as it may not be needed
        clientEmit(ISystemMessageClientTypes.SearchLocations, ({long:longitude, lat:latitude, token, searchForType:"userToUser", distance }))
      }
  },[distance, accountMode, openBottomsheetIfClosed, token, longitude, latitude, clientEmit])

  // if user object is filled and if they were priorly rebuffed
  useEffect(()=>{
    if(rebuffed && (user?.roles?.includes("member") || user?.roles?.includes("admin"))){
      dispatch({type: Actions.SET_SEARCH_REBUFFED, payload: false})
      handleConnect();
    }
  }, [user])


  const gpsReady = (!!latitude && !!longitude) || (latitude===0 && !!longitude) || ( !!latitude && longitude===0)  || (latitude===0 && longitude===0)
  const disableUI = !socketIsConnected || !gpsReady; 
  return (
    <Styled.SearchWrapper>
      <Styled.GetStartedContainer>
        <Styled.SearchContainer>
          
          { 
          !socketIsConnected || !gpsReady
          
            ? 

          (   <LoadingState text={<><span>GPS and</span><br/><span>Connection</span></>} />   )

            :

            <>
              <Styled.Heading>Get Started</Styled.Heading>
              <DistanceSlider max={user.maxDistanceSlider} value={distance} onChange={(val)=>{setDistance(val)}} disabled={disableUI} />
              <LargePrimaryButton onClick={handleConnect} fluid disabled={disableUI}>Connect</LargePrimaryButton>
              {!!searchResultsError ? (<MediumErrorText>{searchResultsError}</MediumErrorText>) : null}
            </>
          }

          
        </Styled.SearchContainer>
      </Styled.GetStartedContainer>
    </Styled.SearchWrapper>
  );
};
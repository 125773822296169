import { useSessionStorage } from "hooks/useSessionStorage";
import { useOpenBottomsheetIfClosed } from "layout/ModalBottomsheetLayout/hooks";
import { useAnonymousTokenFromCookie, useTokenFromCookie } from "pages-modular/hooks";
import { useCrdtContext } from "providers/CrdtProvider";
import React, { createContext, useContext, useState, useRef, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "providers/AppStateProvider";
import { _selectorUser } from "providers/AppStateProvider/store";
import socketio from 'socket.io-client';
import { ANON_XSRF_TOKEN_SENTINEL, XSRF_TOKEN_SENTINEL } from "utils/constants";
import { ISystemMessageClientTypes } from "./socket-handlers/ISystemMessageClientTypes";
import { ISystemMessageServerTypes } from "./socket-handlers/ISystemMessageServerTypes";
import { onHandlers } from "./socket-handlers/onHandlers";
import { EventManager } from "./emitter-handlers/EventManager";
import { usePositionData } from "providers/PositionProvider";

const eventManager = EventManager.getInstance();

const wsUrl = process.env.REACT_APP_MESSAGE_WS_URL ? process.env.REACT_APP_MESSAGE_WS_URL : `https://uio.io:443`;

/**
 * used for real-time messaging to client regarding notifications, progress, etc
 * @param {*} param0 
 * @returns 
 */
export function MessagingProvider({ children }) {
    const {longitude, latitude, error: gpsError} = usePositionData();
    const [restart, setRestart] = useState(false);
    const keepAliveRef = useRef(null);
    const durationRef = useRef(30000);
    const prevConnectRef = useRef(false);

    const [clientId, setClientId] = useSessionStorage("clientId");
    const [socketId, setSocketId] = useSessionStorage("socketId");
    
    const openBottomsheetIfClosed = useOpenBottomsheetIfClosed()

    const {crdtManager} = useCrdtContext();

    const dispatch = useDispatch();

    // prevents recurring renders
    const messagingPrevRef = useRef(false);  

    const [messaging, setMessaging] = useState(null);

    const user = useSelector(_selectorUser);

    // set clientId if not set
    useEffect(()=>{
      if(!clientId && setClientId){
        setClientId(Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15));
      }
    }, [clientId, setClientId])
    

    // get anonToken from the cookies using the hooks
    const anonTokenFromCookie = useAnonymousTokenFromCookie()
    const tokenFromCookie = useTokenFromCookie()

    const getToken = useCallback(()=>{
      const cb = ()=> {
        if( !anonTokenFromCookie || !tokenFromCookie   ){
          return null;
        }
        let token = null;
  
        const {anonymousToken: token1} = anonTokenFromCookie;
        const {token: token2 } = tokenFromCookie;
  
        if(token1 && token1 !== ANON_XSRF_TOKEN_SENTINEL){
          token = token1;
        }else if(token2 && token2 !== XSRF_TOKEN_SENTINEL){
          token = token2;
        }
        return token;
      }
      return cb()
    }, [anonTokenFromCookie, tokenFromCookie])
    

    const systemMessagingSocketCb = useCallback(function(){
      if(!clientId || !getToken || prevConnectRef.current || !longitude || !latitude){
        return null;
      }
      prevConnectRef.current = true;
      const token = getToken();

      // remove quotes from clientId. for some reason, the first time session storage is set, it adds quotes
      const newClientId = clientId.replace(/['"]+/g, '');

      const resultSocketCb =function(reconnect){
        const connectSocket = socketio.io(`${wsUrl}`, {
          query: {
            token,
            clientId: newClientId
          },
          "force new connection": true,
          reconnection: true, // Enables reconnection (default: true)
          reconnectionAttempts: Infinity, // Number of reconnection attempts (default: Infinity)
          reconnectionDelay: 1000, // Time before the first reconnection attempt (default: 1000 ms)
          reconnectionDelayMax: 5000, // Maximum time between reconnection attempts (default: 5000 ms)
          randomizationFactor: 0.5, // Randomization factor for reconnection delay (default: 0.5)
          timeout: 120000,
          transports: ["websocket"],
        });
        connectSocket.on("connect_error", reconnect)
        return connectSocket;
      }
      const reconnect = function(err){
        setRestart(!restart);
      }
      return resultSocketCb(reconnect);

    }, [getToken, clientId, setRestart, restart, longitude, latitude])


    const systemMessagingSocket = useMemo(systemMessagingSocketCb, [systemMessagingSocketCb, restart, longitude, latitude])

    const onConnectHandler = useCallback(()=>{
      if( !systemMessagingSocket || messaging){
        return;
      }
      setMessaging(systemMessagingSocket)
    },[messaging,  systemMessagingSocket])
    

    const clientEmit = useCallback((event, payload)=>{
      if(!messaging) return;
      const newPayload = { ...payload, clientId }
      messaging.emit(event, newPayload)
    }, [clientId, messaging])

    useEffect(()=>{
      const cb = ()=> {

        if( !clientEmit || !eventManager || !user || !anonTokenFromCookie || !tokenFromCookie || messagingPrevRef.current || !dispatch || !crdtManager || !openBottomsheetIfClosed || !onConnectHandler || !systemMessagingSocket){
          return;
        }

        messagingPrevRef.current = true;
        // connect to the messaging socketio server
        // TODO ask if this needs some kind of folder attached to the url for the messaging socketio server


        // for ws testing
        if(process.env.REACT_APP_NODE_ENV === "development"){
          window.messaging = systemMessagingSocket;
          window.ping = ()=>systemMessagingSocket.emit(ISystemMessageClientTypes.Ping, {message: "ping from client"})
        }


        // on socket ready send ping
        systemMessagingSocket.on("connect", onConnectHandler);

        // change the duration of the keep alive
        systemMessagingSocket.on(ISystemMessageServerTypes.Pong, (data)=>{
          setSocketId(data.socketId)
          if(data && data.duration){
            durationRef.current = data.duration;
          }
        })

        const resources = {
          clientEmit,
          eventManager,
          dispatch, 
          crdtManager, 
          bottomsheet: {
            openBottomsheetIfClosed
          } 
        }
        // WARNING: onHandlers and eventManager.setResources are circular dependencies
        // they also inject "messaging" object differently
        onHandlers(systemMessagingSocket, resources);
        eventManager.setResources({...resources, socket: systemMessagingSocket});

      }
      cb()
  
    }, [user, anonTokenFromCookie, tokenFromCookie, openBottomsheetIfClosed, crdtManager, dispatch, onConnectHandler, eventManager, clientEmit])

    useEffect(()=>{
        const keepAliveAsync = async () => {
          if(clientId && clientEmit && systemMessagingSocketCb){
              clearTimeout(keepAliveRef.current)
              
              clientEmit(ISystemMessageClientTypes.Ping, { clientId })
              keepAliveRef.current = setTimeout(keepAliveAsync, durationRef.current)
            }
          }
        if(!keepAliveRef.current){
          keepAliveAsync();
        }
    }, [clientEmit, clientId, messaging, systemMessagingSocketCb]);


    const socketIsConnected = useMemo(()=>messaging?.connected, [messaging])

    window.socketIsConnected = socketIsConnected;

    // TODO: temporary fix for socketio not reconnecting
    useEffect(()=>{
      setTimeout(()=>{
        if(!window.socketIsConnected){
          window.location.reload()
        }
      }, 8000)
    }, [])


  
    return (
      <MessagingContext.Provider value={{ messaging, clientEmit, socketIsConnected }}>
        {children}
      </MessagingContext.Provider>
    );
  }
  
  
  const MessagingContext = createContext();
  
  // use messaging to emit events to websocket chat server
  export function useMessaging() {
    return useContext(MessagingContext);
  }




  
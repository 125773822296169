import styled, { keyframes } from "styled-components";
import { colors } from "config/colors"
import { typographyMixin, TypographySize, TypographyType } from "components/utils/mixins";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const LoadingStateContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    ${({xs})=>xs?`width: 16px; height: 16px;`:``}
`


export const Spinner = styled.div`
    position: absolute;
    width: 100px;
    height: 100px;
    ${({xs})=>xs?`width: 16px; height: 16px;`:``}
    border-radius: 50%;
    border-top: 4px solid ${colors.transparent};
    border-right: 4px solid ${colors.cta};
    border-bottom: 4px solid ${colors.transparent};
    border-left: 4px solid ${colors.cta};
    animation: spin 1s linear infinite;
    z-index:10;
    animation: ${spin} 2s linear infinite;
`

export const LoadingStateText = styled.div`
    ${typographyMixin(TypographyType.black, TypographySize.small)})}  
    z-index:11;
    text-align: center;
    max-width: 80px;
`

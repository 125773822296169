import { Select } from 'antd';



import styled from 'styled-components';
import { fieldStyles } from '../mixins';

const { Option } = Select;


export const SelectRegular = styled(Select)`
${fieldStyles}
width: 100%;
z-index: 999;
`;


export const OptionRegular = styled(Option)`
${fieldStyles}
border: none;
`;
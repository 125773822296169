


import { useMessaging } from "providers";
import { ISystemMessageClientTypes } from "providers/MessagingProvider/socket-handlers/ISystemMessageClientTypes";
import { SuccessErrorWrapper } from "pages-modular/utils";
import { useGetUser } from "pages-modular/SignInFormContent/hooks";
import { useAnonymousTokenFromCookie, useTokenFromCookie } from "pages-modular/hooks";
import { ANON_XSRF_TOKEN_SENTINEL } from "utils/constants";
import { testx } from "utils";
import { useCreateBusinessMutation } from "hooks";
import { useGetRefs } from "pages-modular/UserSettingsContent/hooks/useGetRefs";
import { CreateBusinessForm } from "components/Forms";
import { useCallback } from "react";
import { Heading } from "pages/GetStarted/styles";
import { useModularNavigation } from "layout/ModalBottomsheetLayout/hooks";
import { IPagesModularScreen } from "pages-modular/types";

export const NewBusinessInfoContent = (props)=>{

  const { clientEmit } = useMessaging()

  const { popFromNavStackIfOpenOn} = useModularNavigation();


  const { getUserThenUpdateRedux } = useGetUser();
  const { setAnonymousToken } = useAnonymousTokenFromCookie();
  const {token} = useTokenFromCookie()

  const businessCreateMutation = useCreateBusinessMutation()
  const {data: industriesRef, isLoading } = useGetRefs("industries")

const businessCreateFormSubmitHook = useCallback(async (...args) => {
    const [values, { setSubmitting, resetForm }] = args;

      // if all properties in values are falsey then return
      if(Object.values(values).every(x=>!x)){
        return;
      }
      

      setSubmitting(true);
      const wrappedResponse = await SuccessErrorWrapper(async ()=>await businessCreateMutation.mutateAsync(values))
      const {success} = wrappedResponse;
      setSubmitting(false);
      if(success && token){
        setAnonymousToken(ANON_XSRF_TOKEN_SENTINEL);
        getUserThenUpdateRedux(token);

        // emit event to websocket server to refresh user
        clientEmit(ISystemMessageClientTypes.UpdateUserSession, {token});


        resetForm(); // Reset the form after successful submission
        
        if(props.onSuccessPopNavStack){
          popFromNavStackIfOpenOn(IPagesModularScreen.NewBusinessInfo)
        }
      }
  }, [businessCreateMutation, getUserThenUpdateRedux, setAnonymousToken, clientEmit, token])

  return (
        <>
          <Heading rank={2}>Enter Business Info</Heading>
          <CreateBusinessForm 
          onSubmit={businessCreateFormSubmitHook} submitHook={businessCreateMutation}
          {...props}
          context={{industries: industriesRef}} 
          />
        </>
      ) 
  }

import { businessCityField, businessDescriptionField, businessNameField, businessStateField, businessStreetAddress1Field, businessZipField, contactEmailField, contactPhoneField, industryField, selectBusinessCheckboxField } from "components/utils/forms/fields";
import { FormError } from "components/Text";
import { LargeSecondaryButton } from "components/Button";
import { buildSimpleSubmissionForm } from "components/utils/forms/buildSimpleSubmissionForm";

export const commonBusinessFields = [
  businessNameField,
  industryField,
  businessStreetAddress1Field,
  businessCityField,
  businessStateField,
  businessZipField,
  contactEmailField,
  contactPhoneField,
  businessDescriptionField,
]

const fields = [
    ...commonBusinessFields,
    selectBusinessCheckboxField
]

export const generateBusinessFormControl = ({submitLabel}) =>{
  
  if(!submitLabel){
    submitLabel = `Submit`
  }

  return  ({isSubmitting, submitHook })=>{
    return (
      <>
        <FormError name={"mutation-error"} component={"p"} >{submitHook.isError&&`Invalid Business Profile Information`}&nbsp;</FormError >
        <LargeSecondaryButton type="submit" fluid disabled={isSubmitting}>{isSubmitting?`Submitting...`:submitLabel}</LargeSecondaryButton>
      </>
    )
  }
}

// submitHook is injected into FormControlPanel by buildSimpleSubmissionForm
const FormControlPanel = generateBusinessFormControl({submitLabel:`Create Business Profile`})


export const CreateBusinessForm = buildSimpleSubmissionForm(fields, FormControlPanel)





import { useMessaging, useSelector } from "providers";
import { ISystemMessageClientTypes } from "providers/MessagingProvider/socket-handlers/ISystemMessageClientTypes";
import { SuccessErrorWrapper } from "pages-modular/utils";
import { useGetUser } from "pages-modular/SignInFormContent/hooks";
import { useAnonymousTokenFromCookie, useTokenFromCookie } from "pages-modular/hooks";
import { ANON_XSRF_TOKEN_SENTINEL } from "utils/constants";
import { useEditBusinessMutation } from "hooks";
import { useGetRefs } from "pages-modular/UserSettingsContent/hooks/useGetRefs";
import { useCallback, useEffect, useMemo } from "react";
import { Heading } from "pages/GetStarted/styles";
import { EditBusinessForm } from "components/Forms/EditBusinessForm";
import { _selectorUser } from "providers/AppStateProvider/store";
import { IPagesModularScreen } from "pages-modular/types";
import { useModularNavigation } from "layout/ModalBottomsheetLayout/hooks";


export const EditBusinessInfoContent = (props)=>{
  console.log("TESTX EditBusinessInfoContent props", props)

  const { clientEmit } = useMessaging()

  const { popFromNavStackIfOpenOn} = useModularNavigation();
  

  const { getUserThenUpdateRedux } = useGetUser();
  const { setAnonymousToken } = useAnonymousTokenFromCookie();
  const {token} = useTokenFromCookie()

  const businessEditMutation = useEditBusinessMutation()
  const {data: industriesRef, isLoading } = useGetRefs("industries")
  const user = useSelector(_selectorUser)



const businessEditFormSubmitHook = useCallback(async (...submitHookProps) => {
    const [values, { setSubmitting, resetForm }, initialValues] = submitHookProps;

      // if all properties in values are falsey then return
      if(Object.values(values).every(x=>!x)){
        return;
      }
            
      // Compare 'values' with 'initialValues' and create payload with only changed values.
      const payload = {};
      for (const key in values) {
        if (values[key] !== initialValues[key]) {
          payload[key] = values[key];
        }
      }
      

      setSubmitting(true);
      const businessModPayload = {...payload, _id: user.id}
    
      const wrappedResponse = await SuccessErrorWrapper(async ()=>await businessEditMutation.mutateAsync(businessModPayload))
      const {success} = wrappedResponse;
      setSubmitting(false);
      if(success && token){
        setAnonymousToken(ANON_XSRF_TOKEN_SENTINEL);
        getUserThenUpdateRedux(token);

        // emit event to websocket server to refresh user
        clientEmit(ISystemMessageClientTypes.UpdateUserSession, {token});


        resetForm(); // Reset the form after successful submission
        

        if(props.onSuccessPopNavStack){
          popFromNavStackIfOpenOn(IPagesModularScreen.EditBusinessInfo)
        }
        
      }

  }, [businessEditMutation, getUserThenUpdateRedux, setAnonymousToken, clientEmit, token, user, props.onSuccessPopNavStack])

  // mapped from api data to view schema
  const untoggledValues = useMemo(()=>{
    return transformBusinessModelToEditView(user.selectedBusiness);
  }, [user.selectedBusiness])

  return (
        <>
          <Heading rank={2}>Enter Business Info</Heading>
          <EditBusinessForm 
          onSubmit={businessEditFormSubmitHook} submitHook={businessEditMutation}
          {...props}
          context={{industries: industriesRef}} 
          untoggledValues={untoggledValues}
          />
        </>
      ) 
  }


  const transformBusinessModelToEditView = (b) => {
    const result = {};
    result.name = b.name || "";
    result.industry = b.industry || ""
    result.businessStreetAddress1 = b?.address?.street || "";
    result.businessCity = b?.address?.city || "";
    result.businessState = b?.address?.state || "";
    result.businessZip = b?.address?.zip || "";
    result.emailField = b?.contactInfo?.email || "" ;
    result.phoneField = b?.contactInfo?.phone || "";
    result.descriptionField = b.description || "";
    return result
  }

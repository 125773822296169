
import { FieldInputRegular } from "components/Input";
import { FieldSelectOpen, FieldSelectRegular } from "components/Input/Select";
import { businessValidation, businessNameValidation, confirmNewPasswordTest, emailValidation, fullnameMaybe, fullnameValidation, matchYupField, newPasswordTest, oldPasswordTest, passwordValidation, usernameMaybe, usernameOrEmailValidation, usernameValidation, industriesValidation, businessIndustriesValidation, businessStreetAddress1Validation, businessStreetAddress2Validation, businessCityValidation, businessStateValidation, businessZipValidation, businessPhoneValidation, businessEmailValidation, businessDescriptionValidation, booleanRequiredValidation } from "./fieldValidations";
import { SelectBusinessCheckbox } from "./components/SelectBusinessCheckbox";


export const businessNameField = 
{
  name: "name",
  label: "Business Name",
  yup: businessNameValidation,
  initialValue: "",
  Component: (props) => {
    
    return (
      <FieldInputRegular
        {...props}
        type="text"
        name="name"
        autoComplete="none"
        placeholder="Business Name"
      />
    )
  },
};

export const industryField = 
{
  name: "industry",
  label: "Industry",
  initialValue: null,
  yup: businessIndustriesValidation,
  Component: (props) => {
    console.log("UNTOGGLED FieldSelectRegular props=", props)
    return (
      <FieldSelectRegular
        {...props}
        type="text"
        name="industry"
        autoComplete="none"
        placeholder="Industry"
        options={props.industries.map(industry=>({value: industry, label: industry}))}
      />
    )
  },
};


export const industryControlledField = 
{
  name: "industry",
  label: "Industry",
  initialValue: null,
  yup: businessIndustriesValidation,
  delayToggle: true,
  Component: (props) => {
    console.log("UNTOGGLED FieldSelectRegular props=", props)
    return (
      <FieldSelectOpen
        {...props}
        type="text"
        name="industry"
        autoComplete="none"
        placeholder="Industry"
        value={props?.field?.value}
        options={props.industries.map(industry=>({value: industry, label: industry}))}
        onSelect={ev=>console.log("UNTOGGLED 7 select",ev)}
        onChange={ev=>console.log("UNTOGGLED 8")}
      />
    )
  },
};


export const businessStreetAddress1Field = 
{
  name: "businessStreetAddress1",
  label: "Street",
  yup: businessStreetAddress1Validation,
  initialValue: "",
  Component: (props) => (
    <FieldInputRegular
      {...props}
      type="text"
      name="businessStreetAddress1"
      autoComplete="address-line1"
      placeholder="Street Address"
    />
  ),
};

export const businessStreetAddress2Field = 
{
  name: "businessStreetAddress2",
  label: "Street 2",
  yup: businessStreetAddress2Validation,
  initialValue: "",
  Component: (props) => (
    <FieldInputRegular
      {...props}
      type="text"
      name="businessStreetAddress2"
      autoComplete="address-line2"
      placeholder="Street 2"
    />
  ),
};

export const businessCityField = 
{
  name: "businessCity",
  label: "City",
  yup: businessCityValidation,
  initialValue: "",
  Component: (props) => (
    <FieldInputRegular
      {...props}
      type="text"
      name="businessCity"
      autoComplete="city"
      placeholder="City"
    />
  ),
};

export const businessStateField = 
{
  name: "businessState",
  label: "State",
  yup: businessStateValidation,
  initialValue: "",
  Component: (props) => (
    <FieldInputRegular
      {...props}
      type="text"
      name="businessState"
      autoComplete="state"
      placeholder="State"
    />
  ),
};


export const businessZipField = 
{
  name: "businessZip",
  label: "Zip",
  yup: businessZipValidation,
  initialValue: "",
  Component: (props) => (
    <FieldInputRegular
      {...props}
      type="text"
      name="businessZip"
      autoComplete="zip"
      placeholder="Zip"
    />
  ),
};


export const contactPhoneField = 
{
  name: "phoneField",
  label: "Phone",
  yup: businessPhoneValidation,
  initialValue: "",
  Component: (props) => (
    <FieldInputRegular
      {...props}
      type="text"
      name="phoneField"
      autoComplete="phone"
      placeholder="Phone"
    />
  ),
};


export const contactEmailField = 
{
  name: "emailField",
  label: "Email",
  yup: businessEmailValidation,
  initialValue: "",
  Component: (props) => (
    <FieldInputRegular
      {...props}
      type="text"
      name="emailField"
      autoComplete="email"
      placeholder="Email"
    />
  ),
};

export const selectBusinessCheckboxField = 
{
  name: "selectBusiness",
  label: "Select Business For Account",
  yup:  booleanRequiredValidation,
  initialValue: true,
  Component: (props) => (
    <SelectBusinessCheckbox
      name="selectBusiness"
      {...props}
      defaultChecked={true}
    />
  ),
};

export const businessDescriptionField = 
{
  name: "descriptionField",
  label: "Description",
  yup: businessDescriptionValidation,
  initialValue: "",
  Component: (props) => (
    <FieldInputRegular
      {...props}
      type="text"
      name="descriptionField"
      autoComplete="description"
      placeholder="Description"
    />
  ),
};

export const usernameField = 
{
  name: "username",
  label: "Username",
  yup: usernameValidation,
  initialValue: "",
  Component: () => (
    <FieldInputRegular
      type="text"
      name="username"
      autoComplete="username"
      placeholder="Username"
    />
  ),
};



export const usernameOrEmailField = 
{
  name: "usernameOrEmail",
  label: "Username or Email",
  yup: usernameOrEmailValidation,
  initialValue: "",
  Component: () => (
    <FieldInputRegular
      type="text"
      name="usernameOrEmail"
      autoComplete="usernameOrEmail"
      placeholder="Enter username or email"
    />
  ),
};




export const fullnameModify = 
{
  name: "fullname",
  label: "Full Name",
  yup: fullnameMaybe,
  initialValue: "",
  Component: () => (
    <FieldInputRegular
      type="text"
      name="fullname"
      placeholder="Modify full name"
    />
  ),
};

export const usernameModify = 
{
  name: "username",
  label: "Username",
  yup: usernameMaybe,
  initialValue: "",
  Component: () => (
    <FieldInputRegular
      type="text"
      name="username"
      placeholder="Modify username"
    />
  ),
};

export const newPasswordField = {
  name: "newPassword",
  label: "New Password",
  yup: newPasswordTest,
  initialValue: "",
  Component: () => (
    <FieldInputRegular
      type="password"
      name="newPassword"
      placeholder="New Password"
    />
  ),
}

export const confirmNewPasswordField = {
  name: "confirmNewPassword",
  label: "Confirm New Password",
  yup: confirmNewPasswordTest,
  initialValue: "",
  Component: () => (
    <FieldInputRegular
      type="password"
      name="confirmNewPassword"
      placeholder="Confirm New Password"
    />
  ),
}
export const oldPasswordField = {
  name: "oldPassword",
  label: "Old Password",
  yup: oldPasswordTest,
  initialValue: "",
  Component: () => (
    <FieldInputRegular
      type="password"
      name="oldPassword"
      placeholder="Old Password"
    />
  ),
}
 

export const passwordField = {
    name: "password",
    label: "Password",
    yup: passwordValidation,
    initialValue: "",
    Component: () => (
      <FieldInputRegular
        type="password"
        name="password"
        autoComplete="password"
        placeholder="Password"
      />
    ),
  }
  
  export const confirmPasswordField = {
    name: "confirmPassword",
    label: "Confirm Password",
    yup: matchYupField("password", "Passwords must match"),
    initialValue: "",
    Component: () => (
      <FieldInputRegular
        type="password"
        name="confirmPassword"
        autoComplete="none"
        placeholder="Confirm Password"
      />
    ),
  }
  export const emailField={
    name: "email",
    label: "Email",
    yup: emailValidation,
    initialValue: "",
    Component: () => (
      <FieldInputRegular
        type="text"
        name="email"
        autoComplete="email"
        placeholder="Email"
      />
    ),
  };
  
export const fullnameField={
    name: "fullname",
    label: "Full Name",
    yup: fullnameValidation,
    initialValue: "",
    Component: () => (
      <FieldInputRegular
        type="text"
        name="fullname"
        autoComplete="name"
        placeholder="Your full name"
      />
    ),
  };


export const businessSelectFieldDebounced={
  name: "selectedBusiness",
  label: "Your account's selected business",
  yup: businessValidation,
  Component: (props) => {
    const businesses = props.businesses || [];
    const value1 = businesses.filter(business=>business._id === props.values.selectedBusiness)
    const value = value1[0]
    // NOTE - this is a hack to get the value to be the business object
    return (
      <FieldSelectRegular
        name="selectedBusiness"
        placeholder="Select a business"
        {...props}
        value={typeof value === "string"?value:value?._id}
        options={props.businesses.map(business=>({value: business._id, label: business.name}))}
        
      />
    )
  },
};


export const businessSelectFieldDebouncedAlt={
  name: "selectedBusiness",
  label: "Your account's selected business",
  yup: businessValidation,
  Component: (props) => {
    // NOTE - this is a hack to get the value to be the business object
    return (
      <FieldSelectRegular
        name="selectedBusiness"
        placeholder="Select a business"
        {...props}
        
      />
    )
  },
};
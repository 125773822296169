import { usePosition } from "hooks/usePosition";
import React, { createContext, useContext } from "react";

const PositionContext = createContext(null);

const PositionProvider = ({ children }) => {
  const positionData = usePosition();

  return (
    <PositionContext.Provider value={positionData}>
      {children}
    </PositionContext.Provider>
  );
};

const usePositionData = () => {
    const context = useContext(PositionContext);
  
    if (!context) {
      throw new Error("usePositionData must be used within a PositionProvider");
    }
  
    return context;
  };

export { PositionContext, PositionProvider, usePositionData };


import { IPagesModularScreen } from "pages-modular/types";
import { copy } from "pages/GetStarted";
import { ICRDTActionResult } from "providers/CrdtProvider/CrdtManager";
import { ISystemMessageClientTypes } from "providers/MessagingProvider/socket-handlers/ISystemMessageClientTypes";
import { ISystemMessageServerTypes } from "providers/MessagingProvider/socket-handlers/ISystemMessageServerTypes";
import * as Actions from "providers/AppStateProvider/actions";
import { errorLog } from "utils";
import { documentManifestFoundHandler } from "./documentManifestFoundHandler";
import { IBottomsheetBehavior } from "providers/AppStateProvider/reducers/pageModularScreenReducer";

export const searchForLocationsEventHandlers =[
    {
        event: ISystemMessageServerTypes.ForceSignInSignUp,
        handler: async ({bottomsheet, dispatch}, data) => {
            bottomsheet.openBottomsheetIfClosed(IPagesModularScreen.SignIn, { connectOnSignIn: true, description: copy.anonymousNotAllowed });
            dispatch({type: Actions.SET_SEARCH_REBUFFED, payload:true})
        }
    },
    {
        event: ISystemMessageServerTypes.SearchResults,
        handler: async (connectors, data) => {
            const { dispatch, socket } = connectors;
            // if the user is anonymous and tries to connect to a location which does not yet have a room chat model
            // then the server will emit this event to the client. the client will tell the user to wait for a few seconds
            // while the server creates a room chat model for the location.
            dispatch({type:Actions.SET_SEARCH_RESULTS, payload: data})
            
            if(!data || data.length === 0){
                return;
            }
            // get a location based on a selection strategy and then emit get document manifest if it is not null, otherwise create it if it is null and user is not anonymous
            const locationId = data[0]?._id
            if(locationId){
                socket.emit(ISystemMessageClientTypes.CreateMainGeoCollaboration, {locationId})
            }
        }
    },
    {
        event: ISystemMessageServerTypes.SharedDocumentFound,
        handler: async ({ crdtManager, dispatch, bottomsheet }, {sharedDocument: doc, forDocumentManifest: key}) => {
                const {result} = crdtManager.setCrdtWithDoc({key, doc});
                if(result !== ICRDTActionResult.SuccessSetWithDoc){
                    errorLog("crdt manager couldn't update key", key, "with document");
                    return;
                }
                dispatch({type: Actions.SET_RTC, payload: doc.rtcId})
                bottomsheet.openBottomsheetIfClosed(IPagesModularScreen.Chat, {documentId:key}, IBottomsheetBehavior.FixedBar);
        }
    },
    {
        event: ISystemMessageServerTypes.DocumentManifestFound,
        handler: documentManifestFoundHandler
    },
    {
        event: ISystemMessageServerTypes.SearchResultsError,
        handler: async ({ dispatch }, data) => {
            dispatch({type:Actions.SET_SEARCH_RESULTS_ERROR, payload: data})
        }
    },

]

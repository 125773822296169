export const COUNTER_INCREMENT="COUNTER_INCREMENT";
export const COUNTER_DECREMENT="COUNTER_DECREMENT";

export const DASHBOARD_OPEN = "DASHBOARD_OPEN";
export const BOTTOMSHEET_OPEN = "BOTTOMSHEET_OPEN";
export const BOTTOMSHEET_BEHAVIOR = "BOTTOMSHEET_BEHAVIOR";
export const BOTTOMSHEET_HEIGHT = "BOTTOMSHEET_HEIGHT";
export const BOTTOMSHEET_TOP = "BOTTOMSHEET_TOP";

export const CHANGE_SCREEN_AND_PROPS = "CHANGE_SCREEN_AND_PROPS";

export const SET_USER_INFO = `SET_USER_INFO`;
export const SET_AUTH = "SET_AUTH";
export const SET_ANONYMOUS_AUTH = "SET_ANONYMOUS_AUTH";

export const SET_RTC = `SET_RTC`;
export const SET_CONNECT_NOT_DISABLED = `SET_CONNECT_NOT_DISABLED`;
export const SET_CHAT_DOCMANI = `SET_CHAT_DOCMANI`;

export const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS";
export const SET_SEARCH_RESULTS_ERROR = "SET_SEARCH_RESULTS_ERROR";
export const SET_SEARCH_REBUFFED = "SET_SEARCH_REBUFFED"

export const SET_SHARED_DOCUMENT = "SET_SHARED_DOCUMENT";
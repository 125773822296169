import * as Automerge from '@automerge/automerge';
import _ from 'lodash';
import { assert } from 'superstruct';
import { IPrompt } from './prompts/chat';

const ICrdtSyncState = {
  UNSYNCHRONIZED: "UNSYNCHRONIZED",
  SYNCHRONIZING: "SYNCHRONIZING",
  SYNCHRONIZED: "SYNCHRONIZED",
  SYNCHRONIZE_FAILED: "SYNCHRONIZE_FAILED",
  PAUSE: "PAUSE",
  PLAY: "PLAY"
}

class Crdt {
  version = 0;

  promptType = null;
  prompt = null;

  doc = null;
  operations = {};
  changeSetRulesEngine = null;
  components = null;

  peers = null;
  type = null;

  // stack for doing and undoing operations
  undoStack = [];
  doStack = [];

  state = ICrdtSyncState.UNSYNCHRONIZED
  owner = null;
  versionWrapperRef = null;

  // constructor
  constructor(args) {
    const {key, versionWrapperRef, type, operations, owner, initialData, schema, promptType, peers=[], components=null, changeSetRulesEngine = null, maxValidationErrorsLogging = 100, version=0} = args;
    this.schema = schema;
    this.doc = Automerge.from(initialData);
    this.owner = owner;
    this.validationErrors = [];
    this.maxValidationErrorsLogging = maxValidationErrorsLogging;
    this.changeSetRulesEngine = changeSetRulesEngine;
    this.operations = operations;
    this.peers = peers;
    this.type = type;
    this.version = version;
    this.versionWrapperRef = versionWrapperRef;
    this.documentKey = key

    this.promptType = promptType;
    this.prompt = IPrompt[this.promptType]

    // iterate over the components list and add the component to the components object
    if(components){
      this.components = {};
      components.forEach((component) => {
          this.components[component.name] = component.Component;
        });
      }
  }

  // crdt state
  getCrdtState = () => this.state;
  setUnsynchronized = () => this.state = ICrdtSyncState.UNSYNCHRONIZED;
  setSyncronizing = () => this.state = ICrdtSyncState.SYNCHRONIZING;
  setSyncronized = () => this.state = ICrdtSyncState.SYNCHRONIZED;

  // version management
  setVersion = (version) => this.version = version;
  getVersion = () => this.version;

  getType(){
    return this.type;
  }
  /**
   * get the components
   */
  getComponents = () => {
    return this.components;
  }

  /**
   * get the document validation errors
   * @returns
   * @memberof Crdt
   * @returns {Array}
   */
  getValidationErrors = () => {
    return this.validationErrors;
  }


  /**
   * handles logging of multiple errors
   * @param {*} error 
   */
   multipleValidationErrorLog = (error) => {
    // if error is an array, iterate over the array and log each error
    if(Array.isArray(error)){
      error.forEach((err) => {
        this.logValidationErrors(err);
      });
      return;
    }
  }

  /**
   * handles logging of errors
   * @param {*} error 
   */
   logValidationErrors = (error) => {
    // if error is not an array, log the error
    this.validationErrors.push(error);
    // max 100 errors
    if(this.validationErrors.length > this.maxValidationErrorsLogging){
      // remove the earliest error
      this.validationErrors.shift();
    }
  }

  /**
   * returns "client-app"  or "admin-app" depending on the environment
   */
  getMode = () => {
    return process.env.REACT_APP_ACCESSIBLE_MODE;
  }

  setDocument = (newDoc) => {
    this.doc = Automerge.from(newDoc);
  }

  // backwards will undo last operation 
  backwards = () => {
    const item = this.undoStack.pop();
    if(!item){
      return;
    }

    const [undoneDocument] = (item.undoOperation.bind(this))(item.user);

    const success = this.updateDocument({
      updatedDocument:undoneDocument, 
      payload: item.payload,
      mutationHint: item.mutationHint, 
      claimant: item.claimant,
    });

    if(!success){
      // this should never happen so log it
      this.logValidationErrors('backwards operation failed:', JSON.stringify(item));
      return;
    }
    this.doStack.push(item);
    this.version = item.targetsVersion;

    // update the version for the react client
    this.increaseClientVersion(item.targetsVersion, item.opKey);
  }

  // forwards will redo the operation from the doStack
  forwards = () => {
    const item = this.doStack.pop();
    if(!item){
      throw new Error('forwards operation failed');
    }

    const [updatedDocument, undoOperation] = this.commonOperations({ operation: item.operation, payload: item.payload, user: item.user });



    const success = this.updateDocument({updatedDocument, user: item.user, mutationHint: item.mutationHint});
    if(!success){
      // this should never happen so log it
      throw new Error('forwards operation failed', JSON.stringify(item));
    }

    const newItem = {
      opKey: item.opKey,
      operation: item.operation,
      payload: item.payload,
      user: item.user,
      mutationHint: item.mutationHint, 
      targetsVersion: item.targetsVersion,
      undoOperation,
      claimant: item.claimant,
    }

    this.undoStack.push(newItem);
    this.version = item.targetsVersion;

    // increase version for the react client
    this.increaseClientVersion(item.targetsVersion, item.opKey);
  }

  increaseClientVersion = (ver=null, opKey=null) => {
    // increase version for the react client
    if(this.versionWrapperRef?.current && typeof this.versionWrapperRef.current === 'function'){
      this.versionWrapperRef.current(this.documentKey, ver?ver:this.version + 1, opKey);
    }else{
      throw new Error(`versionWrapperRef.current must be a function. It is instead ${typeof this.versionWrapperRef.current}`)
    }
  }
  commonOperations({ operation, payload, user, claimant }){
        // get the operation from the operations object and run it with the payload
        const operationFunction = this.operations[operation];
        if(!operationFunction){
          throw new Error(`operation '${operation}' does not exist`);
        }
    
        // check to see if the payload is an object and operationFunction is a function
        if(typeof payload !== 'object'){
          throw new Error(`payload must be an object`);
        }
    
        if(typeof operationFunction !== 'function'){
          throw new Error(`operation '${operation}' must be a function`);
        }
    
        // each operation that is run must generate an item on the undoStack
        const opResult = (operationFunction.bind(this))(payload, user, claimant);
        if(Array.isArray(opResult) === false){
          throw new Error(`operation '${operation}' must return an array with the first item being the updated document and the second item being the undo operation. the undo operation is allowed to do nothing but must be a function`);
        }
    
        const [updatedDocument, undoOperation] = opResult;
        if(undoOperation === undefined || undoOperation === null){
          throw new Error(`operation '${operation}' must return an array with the first item being the updated document and the second item being the undo operation. the undo operation is allowed to do nothing but must be a function`);
        }
        return [updatedDocument, undoOperation];
  }

  /**
   * mutate the document in the instance
   * @param {object} operation string value identifying the operation to perform
   * @param {object} payload the payload to pass to the operation. must be an object.
   * @param {string} mutationHint the reason for the change
   */
  mutateDocument = ({opKey, operation, claimant, payload=null, user = null, mutationHint = null} ) => {

    const [updatedDocument, undoOperation] = this.commonOperations({operation, payload, user, claimant});

    const currVersion = this.getVersion()

    // create item for do and undo operations
    const item = {
      opKey,
      operation,
      payload,
      user: {id: user.id, username: user.username, roles: user.roles},
      mutationHint,
      targetsVersion: currVersion,
      undoOperation,
      claimant
    }

    // push the undo operation to the undoStack
    this.undoStack.push(item);


    // increase version for the crdt
    this.setVersion(currVersion + 1);

    
    this.increaseClientVersion(null, opKey);



    const success = this.updateDocument({updatedDocument, user, mutationHint, claimant});
    if(!success){
      // if the update failed, remove the undo item from the undoStack
      this.undoStack.pop();
    }

    // clear the doStack
    this.doStack = [];

    return success;
  
  }

  /**
   * mutate the document in the instance. (private) should never be called outside of this class
   * @param {object} operation string value identifying the operation to perform
   * @param {object} payload the payload to pass to the operation. must be an object.
   * @param {string} mutationHint the reason for the change
   */
   updateDocument = ({updatedDocument, user, claimant, mutationHint = null} ) => {

    if(!user){
      return false
    }

    // validate here
    const resultValid  = !this.isNotValid(updatedDocument);

    // if valid, update the doc after doing the rules
    if(!resultValid){
      return false;
    }

    if( !this.changeSetRulesEngine ){
      this.doc = updatedDocument;

      // TODO send operation, payload, user object to client layer if not null
      
      return true;
    }

    // if changeSetRulesEngine is defined, run the rules
    // changeRulesEngine should be focused on checking to see what changes that are NOT allowed
    // so any result would mean the change is not allowed
    const results = this.changeSetRulesEngine({newDocument:updatedDocument, oldDocument:this.doc, user, mutationHint, claimant});
    if(!results || results.length === 0 ){
      this.doc = updatedDocument;
     
      return true;
    }
    // if the rules engine returns false, do not update the doc
    this.multipleValidationErrorLog(results);
    return false;
    
    
  
  }
  
  removeUserFromPeers = (user) => {
    // filter user from peers
    this.peers = this.peers.filter((peer) => peer.id !== user.id);
  }

  addUserToPeers = (user) => {
    // check to see if user is already in the list if not then add
    const found = this.peers.find((peer) => peer.id === user.id);
    if(!found){
      this.peers.push(user);
    }
  }

  getDocument = () => {
    return this.doc;
  }

  /**
   * determine if the document is valid according to schema
   * @param {*} newDoc 
   * @returns 
   */
  isNotValid = (newDoc) => {

    try{
      assert(newDoc, this.schema);
      return null;
    }catch(error){
      this.logValidationErrors(error);
      return error
    }
  }
}




export { Crdt }
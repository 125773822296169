import * as Actions from "providers/AppStateProvider/actions";


export const bottomsheetLayoutReducer = (state, action = {type: null, payload: null}) => {
  const { type, payload } = action
  switch (type) {
    case Actions.BOTTOMSHEET_HEIGHT:{
      return {
        ...state,
        height: payload,
      }
    }
    case Actions.BOTTOMSHEET_OPEN: {
        return {
            ...state,
            open: payload,
        }
    }
    default:
        return state
  }
}
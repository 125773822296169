
export const colors = {
    transparent: "transparent",
    black: "#010101",
    white: "#fafafa",
    cta: "#FF4BFF",
    ctaHover: "#B73CB7",
    ctaActive: "#FFA9FF",
    red: "#cc0000",

    /* https://www.figma.com/file/KqRwFTipTFKeeZoIJvvmYU/Create-a-connection?node-id=883%3A3113 */
    gray: {
        100: "#070707",
        200: "#171717",
        300: "#2e2e2e",
        400: "#4c4c4c",
        500: "#6d6d6d",
        600: "#8e8e8e",
        700: "#afafaf",
        800: "#cdcdcd",
        900: "#e4e4e4",
        1000: "#f4f4f4"
    }
}
import * as Actions from "providers/AppStateProvider/actions";

export const dashboardLayoutReducer = (state, action = {type: null, payload: null}) => {
  const { type, payload } = action
  switch (type) {
    case Actions.DASHBOARD_OPEN: {
        return {
            ...state,
            open: payload,
        }
    }
    default:
        return state
  }
}
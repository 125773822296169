import * as CommonStyled from "../styles";
import * as Styled from "./styles";

export const DrawerLayout = (props) => {
  const { 
    openBottomsheet, 
    handleOverlayClick, 
    onDismiss, 
    renderContent, 
    showBackgroundOverlay, 
    placement='left' // added placement, default is 'left'
  } = props;

  const onClose = () => {
    handleOverlayClick();
    onDismiss();
    // TODO also clear out the nonprimaryScreens
  };
  
  return (
    <>
      <CommonStyled.BottomSheetOverlay
        shouldBlock={showBackgroundOverlay}
        onClick={handleOverlayClick}
      />
        
        <Styled.StyledDrawer 
            placement={placement}
            closable={true} 
            onClose={onClose}
            visible={openBottomsheet}
            key={placement}
        >
          <Styled.OverflowY>
            {renderContent({ 
              openBottomsheet, 
              handleOverlayClick, 
              onDismiss, 
              renderContent, 
              showBackgroundOverlay 
            })}
        </Styled.OverflowY>
      </Styled.StyledDrawer>
    </>
  );
};

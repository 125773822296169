import { geocollabchatHandler } from "./geocollabchatHandler";
import { transformToEmitterActions } from "./transformToEmitterActions";

const IEmitterHandlerEvents = {
    TRANSFORM_TO_EMITTER_ACTIONS: "TRANSFORM_TO_EMITTER_ACTIONS",
    GEOCOLLABORATION_CHAT: "GEOCOLLABORATION_CHAT",
}

const eventEmitterHandlers = [
    {
        event: IEmitterHandlerEvents.TRANSFORM_TO_EMITTER_ACTIONS,
        handler: transformToEmitterActions
    },
    {
        event: IEmitterHandlerEvents.GEOCOLLABORATION_CHAT,
        handler: geocollabchatHandler
    }
]

export {
    eventEmitterHandlers,
    IEmitterHandlerEvents
}
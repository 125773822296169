

import * as Automerge from '@automerge/automerge';


const IChatOperations = {
    UnshiftToChatRoll: "UnshiftToChatRoll",
    ModifyChatInChatRoll: "ModifyChatInChatRoll",
    ArchiveChatInChatRoll: "ArchiveChatInChatRoll",
}

let lastDoc =null;

// common js class for Crdt
const operations =  {

    // places new chat to chatRoll in content property
    [IChatOperations.UnshiftToChatRoll]: function( payload, user, claimant ) {
        const chat = {
            id: payload.id,
            author: payload.author,
            authorFullname: payload.fullname,
            content: payload.content,
            timestamp: Date.now(),
            archived: false,
          };

        const updatedDoc = Automerge.change(this.doc, (doc) => {

            // insert chat into 0th index
            doc.chatRoll.unshift(chat); 
        });
        lastDoc = updatedDoc


        const undoOperation = function(){
            const undoneDoc = Automerge.change(this.doc, (doc) => {
                // remove chat from 0th index
                doc.chatRoll.shift();

            })
            return [undoneDoc]
        }
        return [updatedDoc, undoOperation]
    },

    // modify the chatRoll in particular spot if the user is the author
    [IChatOperations.ModifyChatInChatRoll]: function( payload, user, claimant ){
        let oldChat = null;
        const chat = payload;
        const updatedDoc = Automerge.change(this.doc, (doc) => {
            const chatId = payload.id
            // find the chat in the chatRoll
            const chatIndex = doc.chatRoll.findIndex((chat) => chat.id === chatId);
            if(chatIndex === -1){
                throw new Error("chat not found");
            }

            // if the user is not the author, throw an error
            if(doc.chatRoll[chatIndex].author !== chat.author || claimant !== chat.author){
                throw new Error("user is not the author");
            }

            // modify the chat
            oldChat = doc.chatRoll[chatIndex];
            doc.chatRoll[chatIndex].content = chat.content;
        });

        const undoOperation = function(){
            const undoneDoc = Automerge.change(this.doc, (doc) => {
                const chatId = chat.id
                // find the chat in the chatRoll
                const chatIndex = doc.chatRoll.findIndex((chat) => chat.id === chatId);
                if(chatIndex === -1){
                    throw new Error("chat not found");
                }

                // modify the chat
                doc.chatRoll[chatIndex] = oldChat;
            })
            return [undoneDoc]
        }

        return [updatedDoc, undoOperation]
    },

    // mark the chat as archived if the user is the author
    [IChatOperations.ArchiveChatInChatRoll]: function({id}, user, claimant){


        const updatedDoc = Automerge.change(this.doc, (doc) => {
            const chatId = id;
            // find the chat in the chatRoll
            const chatIndex = doc.chatRoll.findIndex((chat) => {
                return chat.id === chatId && claimant === chat.author
            });

            if(chatIndex === -1){
                throw new Error("cannot archive chat");
            }

            // modify the chat
            doc.chatRoll[chatIndex].archived = true;
        });

        const undoOperation = function(){
            const undoneDoc = Automerge.change(this.doc, (doc) => {
                const chatId = id;
                // find the chat in the chatRoll
                const chatIndex = doc.chatRoll.findIndex((chat) => chat.id === chatId && claimant === chat.author);
                if(chatIndex === -1){
                    throw new Error("cannot unarchive chat");
                }

                // modify the chat
                doc.chatRoll[chatIndex].archived = false;
            })
            return [undoneDoc]
        }

        return [updatedDoc, undoOperation]
    }
}

const promptableText = `
An object called operations that is exported from the operations.js file as a common js module.
The object has the following properties: UnshiftToChatRoll, ModifyChatInChatRoll, ArchiveChatInChatRoll.

Each property is a function that takes a payload and a user object and returns an array of two elements: the updated doc and an undo operation.
Each undo operation is a function that takes no arguments and returns an array of one element: the updated doc and it must undo the operation that was performed on variable "this.doc"
the updatedDoc or undoneDoc must be a valid Automerge document.

UnshiftToChatRoll - payload is a chat object that includes the following properties: author, content.
                   the timestamp is the time the chat was sent and is generated by the operation.
                   id is also generated by the operation as a uuid.

ModifyChatInChatRoll - payload is a chat object that includes the following properties: id, author, content. if author is not the owner of the document, an error is thrown.
                   if content is different then update the content of the chat with that specific id and same author    

ArchiveChatInChatRoll: takes a payload with property id and marks the chat with that id as archived if the author is the owner of the document.
                    if the author is not the owner of the document, an error is thrown.

The file must also export an object called IChatOperations that has the same properties as operations but the values are strings.
`



export { operations, IChatOperations, promptableText }

import { Form } from "formik";
import { buildFormik } from "components/utils/forms/buildFormik";
import { MediumText } from "components/Text";
import { FieldError, FieldLabel } from "components/Text/Field";
import { useMemo } from "react";

/**
 * A series of fields followed by a control panel area that could contain submission button
 * You call this and attach it to a JSX component name. That JSX component needs a `onSubmit` prop.
 * must inject react query / mutation object into NewForm
 * @param {*} fields that you want user to input
 * @param {*} FormControlPanel is injected the formik control props like setValue, setError, etc.
 * @returns 
 */
export const buildSimpleSubmissionForm = (fields, FormControlPanel) => {
    const NewForm = ({onSubmit, submitHook, context, untoggledValues={}}) => {
      const FormikForm = useMemo(()=>{
        return buildFormik(fields, untoggledValues)
      }, [fields, untoggledValues])
        return (
          <FormikForm
            onSubmit={(...args)=>{
              return onSubmit(...args);
            }}
          >
            {(props) => {
              console.log("UNTOGGLED 6 props=",props);
              
              return (
                <Form name="formikForm" method="post" onSubmit={props.handleSubmit}>
                  {fields.map((Field, index) => {
                    let untoggledValue = ""
                    if(untoggledValues&&untoggledValues[Field.name]){
                      untoggledValue = untoggledValues[Field.name]
                    }
                    return (

                      <FieldLabel htmlFor={Field.name} key={`${Field.name}-${index}`}>
                        <MediumText>{Field.label}</MediumText>
                        {
                          Field.type==="checkbox" ?

                            <Field.Component
                              checked={props.values.checkbox}
                              key={Field.name + "-" + index}
                              name={Field.name} 
                              {...context}
                              untoggledValue={untoggledValue}
                            />

                          :Field.type==="text" ?

                            <Field.Component
                              key={Field.name + "-" + index}
                              name={Field.name} 
                              {...context}
                              untoggledValue={untoggledValue}
                            />

                          :<Field.Component // default to text type
                          key={Field.name + "-" + index}
                          name={Field.name} 
                          {...context}
                          untoggledValue={untoggledValue}
                        />
                        }
                        {/* <Field.Component
                          
                          key={Field.name + "-" + index}
                          name={Field.name} 
                          {...context}
                        /> */}
                        <FieldError name={[Field.name]} component={"p"} />
                      </FieldLabel>
                  ) }  ) }
                  <FormControlPanel {...props} submitHook={submitHook} 
                        {...context}
                  />
                </Form>
              )
            }}
          </FormikForm>
        );
      };

      return NewForm
}

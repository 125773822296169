import * as Styled from "../styles";
import { BottomSheet } from "react-spring-bottom-sheet";

export const BottomsheetLayout = (props) => {
  const { openBottomsheet, handleOverlayClick, onDismiss, renderContent, showBackgroundOverlay } =
    props;

  return (
    <>
      <Styled.BottomSheetOverlay
        shouldBlock={showBackgroundOverlay}
        onClick={handleOverlayClick}
      />
        
        <BottomSheet
          
            style={{
              maxHeight: '80vh',
            }}
          onDismiss={onDismiss}
          open={openBottomsheet}
          blocking={false}
        >
          {renderContent(props)}
        </BottomSheet>

    </>
  );
};

import * as Actions from "providers/AppStateProvider/actions";

export const IBottomsheetBehavior = {
  Priority: "Priority", // default. there should be an overlay the user can click to close the bottomsheet which prioritizes the bottomsheet. when bottomsheet closes behavior must be returned to priority.
  Cooperatively: "Cooperatively", // can be used if the bottomsheet is not the main focus of the screen such as the chat screen
  FixedBar: "FixedBar", // can be used if a simple bar is needed at the bottom of the screen
  FixedLeftSideDrawer: "FixedLeftSideDrawer", // can be used if the modal needs to be fixed on the left side of the screen, no effect on bottomsheet
}

export const pageModularScreenReducer = (state, action = {}) => {
  const { type, payload } = action
  switch (type) {
    case Actions.CHANGE_SCREEN_AND_PROPS: {
      return {
        ...state,
        screen: payload.screen,
        nonprimaryScreens: payload.nonprimaryScreens? payload.nonprimaryScreens : [],
        props: payload.props,
        behavior: payload.behavior? payload.behavior : IBottomsheetBehavior.Priority,
        options: payload.options? payload.options : {}
      }
    }
    default:
      return state
  }
}
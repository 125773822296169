import { counterReducer } from "./counterReducer"

import { dashboardLayoutReducer } from "./dashboardLayoutReducer"
import { bottomsheetLayoutReducer } from "./bottomsheetLayoutReducer"
import { pageModularScreenReducer } from "./pageModularScreenReducer"
import { chatReducer } from "./chatReducer"
import {userReducer } from "./userReducer";
import { authReducer } from "./authReducer"
import { combineReducers } from "providers/AppStateProvider/utils";
import { searchReducer } from "./searchReducer"

export const rootReducers = combineReducers({
  counter: counterReducer,
  dashboardLayout: dashboardLayoutReducer,
  bottomsheetLayout: bottomsheetLayoutReducer,
  pageModularScreen: pageModularScreenReducer,
  user: userReducer,
  auth: authReducer,
  chat: chatReducer,
  search: searchReducer,
})

// VersionProvider.js
import React, { createContext, useState } from 'react';
import { useContext } from 'react';

export const VersionContext = createContext({ versionData:{}, addVersionData:()=>{}, deleteVersionData:()=>{} });

const VersionProvider = ({ children }) => {
  const [versionData, setVersionData] = useState({});

  const addVersionData = (docId, version, opKey) => {
    setVersionData((prevVersionData) => ({
      ...prevVersionData,
      [docId]: { version, opKey },
    }));
  };

  const deleteVersionData = (docId) => {
    setVersionData((prevVersionData) => {
      const { [docId]: _, ...rest } = prevVersionData;
      return rest;
    });
  }

  const resetAll = () => {
    setVersionData({});
  }

  return (
    <VersionContext.Provider value={{ versionData, addVersionData, deleteVersionData, resetAll }}>
      {children}
    </VersionContext.Provider>
  );
};

export const useVersion = () => {
  const everything = useContext(VersionContext);
  return everything;
}

export const useGetVersion = (key) => {
  const { versionData } = useVersion()
  return versionData[key];
}

export default VersionProvider;
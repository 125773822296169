import React from 'react';
import { Slider } from 'antd';
import * as Styled from "./styles";

const yardsInAMile = 1760
export const maxYardLabel = 100

export const DistanceSlider = (props) => {
  return (
    <Styled.SliderWrapper>
      <Slider min={0} max={props.max} defaultValue={100} tooltip={{formatter:(val)=> val <= maxYardLabel ? `${val} yards`: `${trimOneMile((val/yardsInAMile).toFixed(3))} mile`}} {...props}/>
    </Styled.SliderWrapper>
  );
};



const trimOneMile = (str) => {
  const hasDecimal = str.indexOf('.') > -1;
  if(hasDecimal){
    if(str==="1.000"){
      return "1"
    }
  }
  return str;
}
import React from 'react';
import { useField, useFormikContext } from 'formik';
import { FormError } from 'components/Text';
import { OptionRegular, SelectRegular } from './styles';

function SelectOpenOnMount(props){
  return <SelectAlt {...props} open={true} />
}

function SelectAlt(props){
  const { name, options, ...rest } = props;
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const errorElement = (meta.touched && meta.error) ? <div><FormError>{meta.error}</FormError></div> : null;  

  
  return (
    <>
      <GeneralSelect {...props} field={field} errorElement={errorElement} onChange={(val)=>{console.log("UNTOGGLED 11 onChange",val)}} onSelect={(val)=>{
        console.log("UNTOGGLED 4 GeneralSelect", name, val)
    setFieldValue(name, val);
  }}/>
    </>
  )

}

function GeneralSelect(props) {
  const { name, options, ...rest } = props;
  

  const handleChange = value => {
    console.log("UNTOGGLED handleChange", value)
    if(props.onSelect){
      props.onSelect(value);
    }
  };
  console.log("UNTOGGLED GeneralSelect", rest)
  return (
    <div>
      <SelectRegular
         ref={rest.innerRef}
        {...rest}
        onSelect={handleChange}
      >
        {options?options.map((option, index) => (
          <OptionRegular key={index} value={option.value}>
            {option.label}
          </OptionRegular>
        )):null}
      </SelectRegular>
      {rest.errorElement?rest.errorElement:null}
    </div>
  );
}


export  {SelectAlt as FieldSelectRegular, GeneralSelect, SelectOpenOnMount as FieldSelectOpen};

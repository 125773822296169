import { Typography } from "antd";
import * as Styled from "../styles";
import { HelmetCreator } from "pages/components/HelmetCreator";
import { useEffect, useMemo } from "react";
import { errorLog } from "utils";
import { useSelector } from "providers/AppStateProvider";
const { Title } = Typography;

const useTestableStateSnapshot = ()=>{
    const state = useSelector(state=>state);
    
    // filter out secure information
    const newState = useMemo(()=>({...state, auth: "redacted"}), [state])
    return newState;
}

export const Error = () => {
    const testableState = useTestableStateSnapshot();
    useEffect(()=>{
        if(!testableState){
            return;
        }
        errorLog(`v0.1.0`, testableState)
    }, [testableState])
    
    return (
        <>
        <HelmetCreator title={"Email Confirmation"} />

        <Styled.Container>
            <Typography>
            <Title>Error</Title>
                Email already confirmed
            </Typography>
        </Styled.Container>
        </>
    );
};


import {useState, useEffect } from 'react';

const isDevelopment = process.env.REACT_APP_NODE_ENV === 'development';

export const usePosition = () => {
  const [position, setPosition] = useState({});
  const [error, setError] = useState(null);
  
  const onChange = ({coords}) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
    });
  };
  const onError = (error) => {
    setError(error.message);
  };

  // TODO change use effect so that it does not send back an object
  useEffect(() => {
    const geo = navigator.geolocation;
    if (!geo) {
      setError('Geolocation is not supported');
      return;
    }
    const watcher = geo.watchPosition(onChange, onError);
    return isDevelopment ? ()=>{} : () => geo.clearWatch(watcher);
  }, []);
  return isDevelopment ? {latitude:45, longitude:45, error: null} : {...position, error};
}

import styled from "styled-components";

// header text

export const TitleHeader = styled.h1`
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.5px;
    margin-bottom: 0;
`

import { errorLog } from "utils";
import { actionCreators } from "./services";

/**
 * this is a rules engine to determine and execute a course of action according to the input provided it.
 * the first uses of this was to guide the user to reconnect to the previous chat they were in prior to page refresh
 * @param {*} param0 
 * @param {*} payload 
 */
export const transformToEmitterActions = (resources, payload) => {
    const {eventManager } = resources
    // navigates to the /uio/go page to connect with the specific documentId
    const {success, error} = actionCreators(payload)

    if(success){
        eventManager.emit(success.event, success.payload)
    }
    
    if(error){
        errorLog("error in transformToEmitterActions", error)
    }

    // error state. this should never happen. it means that there is a gap in the cases where transformToEmitterActions is called.
    return {error:"payload could not be transformed into actions"}
}
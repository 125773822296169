const theme = {
    fontSize:{
        xlarge: "3rem",
        large: "2rem",
        medium: "1rem",
        small: "0.5rem"
    },
   fontWeight: {
    xlarge: 800,
    large: 600,
    medium: 400,
    small: 200
   },
   spacing: (x)=>`${Math.pow(2, x)}px`
};


export { theme };
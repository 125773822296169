import { passwordField, usernameOrEmailField } from "components/utils/forms/fields";
import { LargePrimaryButton, LargeSecondaryButton } from "components/Button";
import { buildSimpleSubmissionForm } from "components/utils/forms/buildSimpleSubmissionForm";
import { useModularNavigation } from "layout/ModalBottomsheetLayout/hooks";
import { IPagesModularScreen } from "pages-modular/types";
import { FormError } from "components/Text";

const fields = [
  usernameOrEmailField,
  passwordField,
];


const FormControPanel = ({isSubmitting, submitHook})=>{
  const {navigateToAlways} = useModularNavigation()
  return (<>
        <FormError name={"signin-error"} component={"p"} >{submitHook.isError?`Bad username / password combination`:null}&nbsp;</FormError >  
        <LargePrimaryButton type="submit" disabled={isSubmitting} fluid>{isSubmitting?`Signing In...`:`Sign In`}</LargePrimaryButton>
        <LargeSecondaryButton type="button" onClick={()=>{navigateToAlways({screen:IPagesModularScreen.SignUp})}} fluid>Create Account</LargeSecondaryButton>           
    </>)
}

export const SignInForm = buildSimpleSubmissionForm(fields, FormControPanel)
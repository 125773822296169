import styled from "styled-components";
import { Button } from 'antd';
import {colors} from "config/colors";
import { 
    buttonMixins, ButtonMixinSizeType, ButtonMixinStyleType, fluidMixin, 
} from "components/utils/mixins";



export const DashboardButton = styled(Button)`
    padding: 0;
    height: auto;
    color: ${colors.black};
    &:hover, &:active, &:visited{
        color: ${colors.gray[500]};
    }
    &:active{
        color: ${colors.gray[900]};
    }
` 


export const SmallPrimaryButton = styled.button`    
    ${buttonMixins(ButtonMixinStyleType.primary, ButtonMixinSizeType.small)}
`


export const LargePrimaryButton = styled.button`
    ${buttonMixins(ButtonMixinStyleType.primary, ButtonMixinSizeType.large)}
    ${({fluid})=>fluidMixin({fluid})}
    ${({noOutline})=>noOutline && `border: none;`}
    ${({disabled})=>disabled&&`opacity: 0.5;`}
`

export const LargeSecondaryButton = styled.button`
    ${buttonMixins(ButtonMixinStyleType.secondary, ButtonMixinSizeType.large)}
    ${({fluid})=>fluidMixin({fluid})}
    ${({noOutline})=>noOutline && `border: none;`}
    ${({disabled})=>disabled&&`opacity: 0.5;`}
`
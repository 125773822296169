import * as Yup from "yup";
import { testx } from "utils";



const fullnameValidationCommon = Yup.string()
.min(2, "Your name must be at least 2 characters long")


export const fullnameValidation = fullnameValidationCommon
.required("Please enter your full name");

export const businessValidation = Yup.mixed().required("Select A Business");


export const fullnameMaybe = fullnameValidationCommon;

export const passwordValidationGenerator = (yupChain) => yupChain.string()
.min(8, "Must be 8 characters or more")
.matches(/[a-z]+/, "One lowercase character")
.matches(/[A-Z]+/, "One uppercase character")
.matches(/[@$!%*#?&]+/, "One special character")
.matches(/\d+/, "One number")

export const passwordValidation = passwordValidationGenerator(Yup)
  .required("Required")

export const usernameValidationGenerator = (yupChain) => yupChain.string()
.min(2, "Your username must be at least 2 characters long")
.max(32, "Your username must be no more than 32 characters long")
.required("You must provide a username")
.matches(/^[0-9a-zA-Z-]+$/, "Your username can only contain letters, numbers, and hyphens");


export const usernameValidation = usernameValidationGenerator(Yup)

export const emailValidation = Yup.string()
  .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "Your email must be valid")
  .required("Please enter you email")



export const businessNameValidation = Yup.string().min(1, "Name required")
export const businessIndustriesValidation = Yup.string()
export const businessStreetAddress1Validation = Yup.string().min(1, "Street required")
export const businessStreetAddress2Validation = Yup.string()
export const businessCityValidation = Yup.string().min(1, "City required")
export const businessStateValidation = Yup.string().min(1, "State required")
export const businessZipValidation = Yup.string().min(1, "Zip required")
export const businessPhoneValidation = Yup.string().min(1, "Phone required")
export const businessEmailValidation = emailValidation
export const businessDescriptionValidation = Yup.string().min(1, "Description required")

export const booleanRequiredValidation =  Yup.boolean().required('This field must only give either true or false')


// TODO not working when its a username
// for the sign in
export const usernameOrEmailValidation = Yup.string()
  .required('Required Field')
  .test('is-username-or-email', 'Invalid username or email', (value) => {
    if (value) {
      return value.includes('@') ? emailValidation.isValidSync(value) : usernameValidation.isValidSync(value)
    }
    return true
  })

export const usernameMaybe = Yup.string()
  .test('is-username-maybe', 'Invalid username or email', (value) => {
    if (value) {
      return value.includes('@') ? false : usernameValidation.isValidSync(value);
    }
    return true;
  });

export const emailMaybe = Yup.string()
  .test('is-email-maybe', 'Invalid email', (value) => {
    if (value) {
      return Yup.string().email().isValidSync(value);
    }
    return true;
  });


  
export const matchYupField = (yupField, message) => Yup.string()
.oneOf([Yup.ref(yupField), null], message?message:`This must match with ${yupField}`)





/// START


export const oldPasswordTest = Yup.string().nullable().test(
  'isValidOldPassword',
  'Invalid old password',
  function (value) {
    if (!value) return true;  // Allowing it to be empty (as per your previous code)

    const oldPasswordValidation = passwordValidationGenerator(Yup);
    try {
      oldPasswordValidation.validateSync(value);
      return true;
    } catch (error) {
      return this.createError({ path: 'oldPassword', message: error.message || 'Invalid old password' });
    }
  }
);


export const newPasswordTest = Yup.string().nullable().test(
  'isValidNewPassword',
  'Either leave all fields blank or fill all fields',
  function (value) {
    const { oldPassword, newPassword, confirmNewPassword } = this.parent;

    if (!oldPassword && !newPassword && !confirmNewPassword) {
      return true;
    }

    if (!newPassword) {
      return this.createError({ path: 'newPassword', message: 'Required' });
    }

    const newPasswordValidation = passwordValidationGenerator(Yup);
    try {
      newPasswordValidation.validateSync(newPassword);
      return true;
    } catch (error) {
      return this.createError({ path: 'newPassword', message: error.message || 'Invalid new password' });
    }
  }
);


export const confirmNewPasswordTest = Yup.string().nullable().test(
  'isValidConfirmNewPassword',
  'Either leave all fields blank or fill all fields',
  function (value) {
    const { oldPassword, newPassword, confirmNewPassword } = this.parent;

    if (!oldPassword && !newPassword && !confirmNewPassword) {
      return true;
    }
testx("2 this.parent",this.parent)
    if (!confirmNewPassword) {
      testx("3 this.parent",this.parent)
      return this.createError({ path: 'confirmNewPassword', message: 'Required' });
    }

    
    if (newPassword !== confirmNewPassword) {
      return this.createError({ path: 'confirmNewPassword', message: 'Passwords must match' });
    } else {
      return true;
    }
  }
);

import { GeneralSelect } from "components/Input/Select"
import { LoadingState } from "components/LoadingState";
import { FieldLabel } from "components/Text";
import { useBusinessModifyMutation } from "hooks/useBusinessModifyMutation";
import { useAnonymousTokenFromCookie, useTokenFromCookie } from "pages-modular/hooks";
import { useGetUser } from "pages-modular/SignInFormContent/hooks";
import { SuccessErrorWrapper } from "pages-modular/utils";
import { useMessaging, useSelector } from "providers"
import { _selectorUser } from "providers/AppStateProvider/store"
import { ISystemMessageClientTypes } from "providers/MessagingProvider/socket-handlers/ISystemMessageClientTypes";
import { useCallback, useMemo, useState } from "react";
import { ANON_XSRF_TOKEN_SENTINEL } from "utils/constants";

/**
 * Warning: do not make the logic in this form reusable unless it this special case of a single selection form per field is needed in other places. Reason: We had to re-implement some of the same logic that formik and yup already does for us. Trying to do this with formik and yup was not working.
 * @param {*} props 
 * @returns 
 */
export const SelectBusinessIndependentField = props => {
    const { clientEmit } = useMessaging()
    const {token} = useTokenFromCookie()
    const { setAnonymousToken } = useAnonymousTokenFromCookie();
    const { getUserThenUpdateRedux } = useGetUser();
    const [submitting, setSubmitting] = useState(false);
    const businessModifyMutation = useBusinessModifyMutation()
    const user = useSelector(_selectorUser);
    const options = useMemo(()=>{
        return user.businesses.map(business=>({label: business.name, value: business._id}));
    },[user.businesses])
    const value = useMemo(()=>{
        return user.selectedBusiness._id;
    },[user.selectedBusiness._id])

    const handleChange = useCallback(async (ev)=>{
        const value = ev;
        const business = user.businesses.filter(business=>business._id === value)[0];

        setSubmitting(true);
        const fieldName = props.name || "selectedBusiness";
        const businessModPayload ={[fieldName]: value, _id: user.id}
        const wrappedResponse = await SuccessErrorWrapper(async ()=>await businessModifyMutation.mutateAsync(businessModPayload))
        const {success} = wrappedResponse;
        setSubmitting(false);
        if(success && token){
            
            setAnonymousToken(ANON_XSRF_TOKEN_SENTINEL);
            getUserThenUpdateRedux(token);
            // emit event to websocket server to refresh user
            clientEmit(ISystemMessageClientTypes.UpdateUserSession, {token});
        }
    },[businessModifyMutation, clientEmit, getUserThenUpdateRedux, props, setAnonymousToken, token, user.businesses, user.id])

    return <>
        <FieldLabel>Select Business For Account{submitting?<><LoadingState /></>:null}</FieldLabel>
        <GeneralSelect options={options} value={value} onChange={handleChange}/>
    </>
}
import { useEffect, useRef } from 'react';
import { useSelector } from 'providers/AppStateProvider';
import { _selectorChat } from "providers/AppStateProvider/store";
import { useNavigate } from "react-router-dom";
export const useNavToChatOnRtcId = () => {
  const navigateTo = useNavigate();

  const {rtcId} = useSelector(_selectorChat)
  const navPrevRef = useRef(false);
  useEffect(()=>{
    if(navPrevRef.current || !rtcId || !navigateTo){
      return()=>{
      };
    }
    navPrevRef.current = true;
    navigateTo("/uio/go")
  },[rtcId, navigateTo])


  return null;
};
import { useSelector } from "providers";
import { _selectorUser } from "providers/AppStateProvider/store";
import { useMemo } from "react";

export const useHasValidBusiness = () =>{
    
    const user = useSelector(_selectorUser);

    const hasValidBusiness = useMemo(()=>{
        return user.businesses.length > 1;
    }, [user])
    return hasValidBusiness
}



import { businessSelectFieldDebouncedAlt } from "components/utils/forms/fields";
import React, { useState, useCallback, useRef, useEffect } from "react";
import { LargeSecondaryButton } from "components/Button";
import { useModularNavigation } from "layout/ModalBottomsheetLayout/hooks";
import { IPagesModularScreen } from "pages-modular/types";
import { IBottomsheetBehavior } from "providers/AppStateProvider/reducers/pageModularScreenReducer";
import * as _ from "lodash";
import { FieldLabel } from "components/Text";
import { buildInitialValues } from "components/utils/forms/buildFormik";
import { useFormik  } from "formik";


const fields = [ 
  businessSelectFieldDebouncedAlt
];


export const BusinessModifyFormAlt = props => {

    const {pushOntoNavStackIfOpenOn} = useModularNavigation();

    const handleNestedPresentation = useCallback((e)=>{
        e.preventDefault();
        pushOntoNavStackIfOpenOn(IPagesModularScreen.BusinessUserSettings, {screen:IPagesModularScreen.NewBusinessInfo, props:{}, behavior:IBottomsheetBehavior.FixedLeftSideDrawer, options:{showBack:false}})
      }, [pushOntoNavStackIfOpenOn])

    const MyForm = buildPatchableForm(fields, null);
    return (
        <>
            <MyForm {...props} />

            <LargeSecondaryButton onClick={handleNestedPresentation} fluid>
                Enter New Business Info
            </LargeSecondaryButton>
        </>
    )
}


const buildPatchableForm = (fields, FormControl) => {
    const initialValues = buildInitialValues(fields);

    const MyStatefulField = (props)=>{
        const [myValue, setMyValue] = useState(initialValues[props.name]);

        const formik = useFormik({
            initialValues: { [props.name]: initialValues[props.name] || '' },
            onSubmit: (values) => {
                // Handle submission here
                // handleChange(values);
            },
            // Add validation if needed
        });
        
        const lastValueRef = useRef(initialValues[props.name]);

        useEffect(()=>{

            if(!props.values) return;
            if(!props.name) return;

            // deep equality using lodash between lastValueRef.current and props.values[props.name]
            if ( !_.isEqual(lastValueRef.current, props.values[props.name]) ) {
                lastValueRef.current = myValue;
                setMyValue(props.values[props.name]);
            }
        }, [myValue, props.name, props.values] )

        const MyField = props.Component;
        return (<>
            <form>
                        <div>
                            {/* <input type="text" {...field} /> */}
                            <MyField {...props} {...formik.getFieldProps(props.name)} />
                            {formik.errors[props.name] && formik.touched[props.name] && <div>{formik.errors[props.name]}</div>}
                            
                        </div>
            </form>

        </>)
    }
    const results = fields.map((field,index) => {
        const MyLabel = field.label;
        const labelIsReact = typeof MyLabel === "object" && React.isValidElement(MyLabel);
        let labelToRender = null;
        if(labelIsReact){
            labelToRender = (props) => <MyLabel {...field} index={index} {...props} />
        }else{
            labelToRender = <>{MyLabel}</>
        }
        // will need to wrap the Field in a single Formik 
        return (
            <>
                <FieldLabel>{labelToRender}</FieldLabel>
                <MyStatefulField {...field} index={index} />
            </>
        )
    })
    return (props) => {
        return (
            <>
                {results}
            </>
        )
    }
}
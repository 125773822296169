
export const combineReducers = reducers => {
  return (state, action) => {
    return Object.keys(reducers).reduce(
      (acc, prop) => {
        return ({
          ...acc,
          ...{[prop]:reducers[prop](
            {...acc[prop] }
            , action)},
        })
      },
      state
    )
  }
}

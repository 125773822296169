import reactUseCookie from "react-use-cookie";
import { ANON_XSRF_TOKEN, ANON_XSRF_TOKEN_SENTINEL, XSRF_TOKEN, XSRF_TOKEN_SENTINEL } from "utils/constants";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "providers/AppStateProvider";
import * as Actions from "providers/AppStateProvider/actions";
import { _selectorAuth } from "providers/AppStateProvider/store";

/** use this hook exclusively inside react components to manage non-anonymous user token */
export const useTokenFromCookie = () => {
  const [userToken, setUserToken] = reactUseCookie(XSRF_TOKEN, XSRF_TOKEN_SENTINEL);
  const dispatch = useDispatch();
  const { token } = useSelector(_selectorAuth)
  const setToken = useCallback((tokenString)=>{
     setUserToken(tokenString);
     dispatch({type: Actions.SET_AUTH, payload:{token:tokenString}});
  }, [dispatch, setUserToken]);

  return {token, setToken}

}




/** use this hook exclusively inside react components to manage anonymous user token */
export const useAnonymousTokenFromCookie = () => {
  const [anonUserToken, setAnonUserToken] = reactUseCookie(ANON_XSRF_TOKEN, ANON_XSRF_TOKEN_SENTINEL);
  const dispatch = useDispatch();
  const { anonymousToken } = useSelector(_selectorAuth)
  const setAnonymousToken = useCallback((tokenString)=>{
    setAnonUserToken(tokenString);
     dispatch({type: Actions.SET_ANONYMOUS_AUTH, payload:{anonymousToken:tokenString}});
  }, [dispatch, setAnonUserToken]);

  return {anonymousToken, setAnonymousToken}

}

export const useNoCookieTokens = () => {

  const [anonUserToken, setAnonUserToken] = reactUseCookie(ANON_XSRF_TOKEN, ANON_XSRF_TOKEN_SENTINEL);
  const [userToken, setUserToken] = reactUseCookie(XSRF_TOKEN, XSRF_TOKEN_SENTINEL);
  const result = useMemo(()=> (!anonUserToken && !userToken) 
  || (anonUserToken===ANON_XSRF_TOKEN_SENTINEL && userToken===XSRF_TOKEN_SENTINEL)
  , [anonUserToken, userToken])
  return result;
}


const MILLISECONDS_IN_A_DAY = 1000 * 24 * 60 * 60
export const useShouldGetAnonymousCookieToken = () => {
  const nowMilliseconds = (new Date()).valueOf();
  const [anonTokenCreated] = useLocalStorage("anonTokenCreated", nowMilliseconds );

  const [userToken, setUserToken] = reactUseCookie(XSRF_TOKEN, XSRF_TOKEN_SENTINEL);
  const result = useMemo(()=> ( (!userToken) || (userToken===XSRF_TOKEN_SENTINEL)) || MILLISECONDS_IN_A_DAY <= (nowMilliseconds - anonTokenCreated) , [anonTokenCreated, nowMilliseconds, userToken])
  return result;
}


export const IAccountMode = {
 NONE: "NONE",
 ANONYMOUS: "ANONYMOUS",
 MEMBER_OR_ADMIN: "MEMBER_OR_ADMIN",
}
export const useAccountMode = ()=> {
  const {anonymousToken} = useAnonymousTokenFromCookie();
  const {token}= useTokenFromCookie();
  const accountMode = useMemo(()=>getAccountModeCommon(token, anonymousToken),[token, anonymousToken])
  return accountMode
}

export const getAccountModeCommon = (token, anonymousToken)=>{
  if(!!token && token!==XSRF_TOKEN_SENTINEL && ( anonymousToken===ANON_XSRF_TOKEN_SENTINEL || !anonymousToken )){
    return IAccountMode.MEMBER_OR_ADMIN
  }else if(anonymousToken!==ANON_XSRF_TOKEN_SENTINEL && !!anonymousToken && (token===XSRF_TOKEN_SENTINEL || !token)){
    return IAccountMode.ANONYMOUS;
  }
  return IAccountMode.NONE;
}


/** returns valid token whether signed in or anonymous */
export const useToken = () => {
  const  {anonymousToken} = useAnonymousTokenFromCookie();
  const {token} = useTokenFromCookie()
  const accountMode = useAccountMode();
  if(accountMode===IAccountMode.ANONYMOUS){
    return {token: anonymousToken}
  }else   if(accountMode===IAccountMode.MEMBER_OR_ADMIN){
    return {token}
  }
  return {token: null}
}

const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    let currentValue;

    try {
      currentValue = JSON.parse(
        localStorage.getItem(key) || String(defaultValue)
      );
    } catch (error) {
      currentValue = defaultValue;
    }

    return currentValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  return [value, setValue];
};

export default useLocalStorage;

import axios from "axios";
import { getAccountModeCommon, IAccountMode } from "pages-modular/hooks";
import { IUserCommonType } from "pages-modular/SignInFormContent/hooks";
import { getCookie } from 'react-use-cookie';
import { ANON_XSRF_TOKEN, XSRF_TOKEN } from "utils/constants";


/**
 * will produce an axios object using a config object. great with interceptors.
 * specifying userCommonType will force the client to use the associated token or anonymousToken from cookie. Used when tokens in cookie are not yet initialized.
 * while useTokenFromCookie will choose token based on the actual cookie state.
 * @param {*} param0 
 */
export const axiosFactory = ({userCommonType, useTokenFromCookie=false}) => {

  const axiosApiInstance = axios.create();
  // Request interceptor for API calls
  axiosApiInstance.interceptors.request.use(
    config => {
      config.headers = { 
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      }


      if(useTokenFromCookie && !userCommonType){

        const token = getCookie(XSRF_TOKEN);
        const anonymousToken = getCookie(ANON_XSRF_TOKEN);
        const accountMode = getAccountModeCommon(token, anonymousToken)

        let cookieToken = null;
        if(accountMode===IAccountMode.MEMBER_OR_ADMIN){
          cookieToken = getCookie(XSRF_TOKEN);
        }else if(token===IAccountMode.ANONYMOUS){
          cookieToken = getCookie(ANON_XSRF_TOKEN);
        }
        if(cookieToken){
          config.headers["Authorization"] = `Bearer ${cookieToken}`
        }
      }else if (userCommonType){

        let cookieToken = null;
        if(userCommonType===IUserCommonType.MemberOrAdmin){
          cookieToken = getCookie(XSRF_TOKEN);
        }else if(userCommonType===IUserCommonType.Anonymous){
          cookieToken = getCookie(ANON_XSRF_TOKEN);
        }
        if(cookieToken){
          config.headers["Authorization"] = `Bearer ${cookieToken}`
        }
      }

      return config;
    },
    error => {
      Promise.reject(error)
  });

  return axiosApiInstance
}
import styled from "styled-components";
import { colors } from "config/colors"

export const SliderWrapper = styled.div`
    && .ant-slider-track {
        background-color: ${colors.cta};
    }
    && .ant-slider-handle {
        border: 2px solid ${colors.ctaActive};
    }
    && .ant-slider-handle:active {
        border: 2px solid ${colors.ctaHover};
    }
`
import { LargePrimaryButton } from "components/Button"
import { colors } from "config/colors"
import { theme } from "config/themes"
import { mobileMixins } from "layout/ModalBottomsheetLayout/styles"
import styled from "styled-components"

export const Container = styled.div`
    margin: auto;
    width: 100%;
    min-height: 100%;
`

export const MobileContainer = styled.div`
    ${mobileMixins}
`

export const CtaTitleContainer = styled.div`
    margin: auto ${theme.spacing(3)};
    &>*{
        margin-top: 16px;

    }
`

export const CtaCopyContainer = styled.div`
    margin: auto;
    &>*{
        margin-top: 16px;

    }
`

export const HeroRow = styled.div`
    margin: 64px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @media screen and (min-width: 412px) {
        flex-direction: row;
    }
`

export const HeroColumn = styled.div`
    margin: 64px auto;
    display: flex;
    flex-direction: column;
`

export const CTAButton = styled(LargePrimaryButton)`
    margin-top: 64px auto;
    padding: 8px 64px;
    width: 100%;
    height: auto;
    justify-content: center;
`

export const HeroContainer = styled.div`
    display: flex;
    justify-content: center;
`


export const SeparatorContainer = styled.div`
    margin: ${theme.spacing(5)} auto;

    div {
        height: ${theme.spacing(2)};
        width: ${theme.spacing(5)};
        background-color: ${colors.gray[100]};
    }
`


export const ReadableContainer = styled.div`
    margin-left: ${theme.spacing(3)};
    margin-right: ${theme.spacing(3)};
`

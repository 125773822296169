import { faClose, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LargeSecondaryButton } from "components/Button";
import { FieldLabel, MediumText, SmallText } from "components/Text";
import { colors } from "config/colors";
import { useModularNavigation } from "layout/ModalBottomsheetLayout/hooks";
import { SmallSecondaryIconButton } from "layout/ModalBottomsheetLayout/styles";
import { IPagesModularScreen } from "pages-modular/types";
import { Heading } from "pages/GetStarted/styles"
import { IBottomsheetBehavior } from "providers/AppStateProvider/reducers/pageModularScreenReducer";
import { useCallback, useMemo, useRef, useState } from "react";
import { SelectBusinessIndependentField } from "./SelectBusinessIndependentField"
import * as Styled from "./styles"


export const BusinessModifyForm = ({user}) => {
    // get user info to find out how many businesses they have
    // if they have more than one, show the select business field

    const {pushOntoNavStackIfOpenOn, popFromNavStackIfOpenOn, getCurrentScreenObject} = useModularNavigation();
  
    const handleNestedPresentation = useCallback((e)=>{

      e.preventDefault();

      pushOntoNavStackIfOpenOn(IPagesModularScreen.BusinessUserSettings, {screen:IPagesModularScreen.NewBusinessInfo, props:{onSuccessPopNavStack: true}, behavior:IBottomsheetBehavior.FixedLeftSideDrawer, options:{showBack:false}})
    
    }, [pushOntoNavStackIfOpenOn])

    const currentScreenObject = useMemo(()=>getCurrentScreenObject(), [getCurrentScreenObject])


    const pushToEditBusinessInfo = useCallback(()=>{
        pushOntoNavStackIfOpenOn(IPagesModularScreen.BusinessUserSettings, 
            {
                screen:IPagesModularScreen.EditBusinessInfo, 
                props: { 
                    onSuccessPopNavStack: true
                }, 
                behavior: IBottomsheetBehavior.FixedLeftSideDrawer, 
                options: {
                    showBack:false
                }
            }
        )
    }, [pushOntoNavStackIfOpenOn])


    const handleNestedEditBusiness = useCallback((e)=>{

        e.preventDefault();

        if(currentScreen!==IPagesModularScreen.EditBusinessInfo){

            pushToEditBusinessInfo()
        
        }else{

            popFromNavStackIfOpenOn(IPagesModularScreen.EditBusinessInfo)
        }
        
      }, [popFromNavStackIfOpenOn, pushOntoNavStackIfOpenOn])
    

    const validBusinesses = useMemo(()=>{
        if(!user.businesses) return [];
        return user.businesses.filter((business)=>business.name.toLowerCase()!=="no business")
    },[user.businesses])

    const hasValidBusiness = useMemo(()=>{
        return validBusinesses.length > 0;
    },[validBusinesses])

    const currentScreen = currentScreenObject.screen

    const {address} = user.selectedBusiness || {};
    const { street, city, state, zip, phone, email } = address || {};
    return (
        <>
            <Heading>Business Profile</Heading>

            {
                validBusinesses && validBusinesses.length === 0 ?                
                <LargeSecondaryButton onClick={handleNestedPresentation} fluid>
                    Enter New Business Info
                </LargeSecondaryButton>
                : null
            }

            {
                hasValidBusiness && validBusinesses.length > 1 ?            <SelectBusinessIndependentField /> 
                : null
            }

            {
                hasValidBusiness && validBusinesses.length === 1 ? 
                <>

                    <Styled.Flex>
                        <p><Heading rank={2}>Business</Heading></p>
                        <p><MediumText>{validBusinesses[0].name} </MediumText>
                            <SmallSecondaryIconButton onClick={handleNestedEditBusiness}>
                                
                                {currentScreen!==IPagesModularScreen.EditBusinessInfo
                                ? <><FontAwesomeIcon icon={faPencil} color={colors.gray} /> Edit</>
                                : <><FontAwesomeIcon icon={faClose} color={colors.gray} /> Close</>}
                            </SmallSecondaryIconButton>
                        </p>
                    </Styled.Flex>
                    <Styled.Flex>
                        {address?

                            <div>
                                <Heading rank={2}>Address </Heading>

                                <MediumText>{street}</MediumText>
                                <MediumText>{city}</MediumText>
                                <MediumText>{state}</MediumText>
                                <MediumText>{zip}</MediumText>
                                <MediumText>{phone}</MediumText>
                                <MediumText>{email}</MediumText>
                            </div>
                        
                        :null}
                    </Styled.Flex>
                </>
                : null
            }
        </>
    )
}
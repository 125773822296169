// should be consistent with the backend nodejs app file Message.js
export const ISystemMessageClientTypes = {
    Error: "error",
    Ping: "ping2",
    UpdateUserSession: "updateUserSession",

    // events that facilitate chat
    Dm: "dm", // when a user wants to direct message another user,
    CreateRoomChatDocuments: "createRoomChatDocuments", // when a user wants to create a room chatCreateOrGetRoomChatDocuments: "createOrGetRoomChatDocuments", // when a user wants to create a room chat
    GetRoomChatDocuments: "getRoomChatDocuments", // when a user wants to create a room chat

    // events for geo collaboration
    CreateMainGeoCollaboration: "createMainGeoCollaboration",
    GetGeoCollaborationDocuments: "getGeoCollaborationDocuments",
    getGeoCollaborationSharedDoc: "getGeoCollaborationSharedDoc",
    CreateBranchGeoCollaboration: "CreateBranchGeoCollaboration",
    SocketLeaveDocumentManifest: "SocketLeaveDocumentManifest",
    UpsertResourceConsistency: "UpsertResourceConsistency",
    DocumentManifestOnboardedSuccess: "DocumentManifestOnboardedSuccess", 

    // generic events
    GetDocumentManifest: "GetDocumentManifest",
    GetSharedDocument: "GetSharedDocument",
    GetMutationsFromVersion: "GetMutationsFromVersion",
    
    // events for searching
    SearchLocations: "searchLocations",
    SearchResults: "searchResults",

    // event for crdt
    CrdtMutateData: "CrdtMutateData",
    CrdtManagementOperation: "CrdtManagementOperation",
    CrdtCreateDocument: "CrdtCreateDocument",
    CrdtDeleteDocument: "CrdtDeleteDocument",
}
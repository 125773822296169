
import 'antd/dist/antd.min.css'

import MainRoutes from 'MainRoutes';

import { AppStateProvider } from 'providers/AppStateProvider';
import { rootReducers } from 'providers/AppStateProvider/reducers';
import { initialState } from 'providers/AppStateProvider/store';
import {
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { CrdtProvider, MessagingProvider } from 'providers';
import VersionProvider from 'providers/CrdtProvider/VersionProvider';
import { PagesModularStackProvider } from 'providers/PagesModularStackProvider';
import { PositionProvider } from 'providers/PositionProvider';

// import socketio from 'socket.io-client';
// window.socketio=socketio;
// TODO import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
 
const queryClient = new QueryClient();
/**
 * The App component should only have 1 component composed in JSX for all the apparent-seeming routes
 * This App component should be the layer for the provider injection
 * This component MUST be composed once into the jsx of the top-level index.js file
 * @returns 
 */
function App() {
  return (
    <div className="app">
      <PositionProvider>
        <PagesModularStackProvider >
          <AppStateProvider reducer={rootReducers} initialState={initialState}>
            <QueryClientProvider client={queryClient}>
              <VersionProvider>
                <CrdtProvider >
                  <MessagingProvider>
                      <MainRoutes />
                  </MessagingProvider>
                </CrdtProvider>
              </VersionProvider>
            </QueryClientProvider>
          </AppStateProvider>
        </PagesModularStackProvider>
      </PositionProvider>
    </div>
  );
}

export default App;

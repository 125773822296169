
// takes list of objects with a .yup Yup Validation property. will wrap that transforms the .yup such that null values are treated as undefined and all others are wrapped in an optional property
export const generateOptionalFields = (fields)=>{
    
    const optionalEditFields = fields.map(obj => {
        const result = { ...obj };
    
        // Decorate the Yup validation with optional
        if (result.yup) {
            result.yup = result.yup.transform((currentValue, originalValue) => {
                // Treat null as undefined
                if (originalValue === null) {
                    return undefined;
                }
                return currentValue;
            }).optional();
        }
    
        return result;
    });

    return optionalEditFields;
}
import React, { createContext, useReducer, useContext, useMemo } from "react";
import * as PropTypes from "prop-types";

const StateContext = createContext();

// Since this provider is closely tied to the redux flow, this provider will be in `/redux` rather than `/providers`
export function AppStateProvider({ reducer, initialState = {}, children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  );
}

AppStateProvider.propTypes = {
  reducer: PropTypes.func,
  initialState: PropTypes.object,
};

export function useAppState() {
  return useContext(StateContext);
}

export function useDispatch() {
  const { dispatch } = useAppState();
  return dispatch;
}

export function useSelector(selectionFunc) {
  const { state } = useAppState();
  const data = useMemo(() => selectionFunc(state), [state, selectionFunc]);
  return data;
}

import { theme } from "config/themes";
import styled from "styled-components"

export const simpleFormSpacingMixin = () => `
    p {
        margin-bottom: ${theme.spacing(1)};
    }
    label:first-child {
        margin-top: ${theme.spacing(2)};
    }
    label {
        margin-bottom: ${theme.spacing(3)};
    }
    button[type="submit"]{
        margin-bottom: ${theme.spacing(2)};
    }
    button[type="button"]{
        margin-bottom: ${theme.spacing(2)};
    }
    margin-left: ${theme.spacing(2)};
    margin-right: ${theme.spacing(2)};
`



export const Flex = styled.div`
    display: flex;
    flex-direction: ${({direction})=> direction ? direction : "row"};
    align-items: center;
    justify-content: center;
    & > * {
        display: inline;
    }
`
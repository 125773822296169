import * as Styled from "./styles";
import { HelmetCreator } from "pages/components/HelmetCreator";
import { useEffect, useMemo, useRef } from "react";
import { infoLog } from "utils";
import { useSelector } from "providers/AppStateProvider";
import { _selectorBottomsheetLayout, _selectorChat } from "providers/AppStateProvider/store";
import Iframe from "react-iframe";
import { useOpenBottomsheetIfClosed } from "layout/ModalBottomsheetLayout/hooks";
import { IPagesModularScreen } from "pages-modular/types";
import { IBottomsheetBehavior } from "providers/AppStateProvider/reducers/pageModularScreenReducer";

const rtcSubDomain = `uio-rtc`

const iframeUrl = (rtcSubDomain, rtcId) =>`https://${rtcSubDomain}.uio.io/demos/demo_multiparty2.html?c=${rtcId}`

const useTestableStateSnapshot = ()=>{
    const state = useSelector(state=>state);
    
    // filter out secure information
    const newState = useMemo(()=>({...state, auth: "redacted"}), [state])
    return newState;
}

export const GoChat = () => {
    const chat = useSelector(_selectorChat)
    const {height, top} = useSelector(_selectorBottomsheetLayout);
    const testableState = useTestableStateSnapshot();
    const idPrevRef = useRef(false);
    const openBottomsheetIfClosed = useOpenBottomsheetIfClosed()
    const divRef = useRef(null);
  

    useEffect(() => {
        let interval = null;
        if (divRef.current) {
            interval = setInterval(() => {

                divRef.current.scrollTop = 0;
            })
        }
        return () => {
            if(!interval){
                return;
            }
            clearInterval(interval)
        }
      }, [top]);


    useEffect(()=>{
        if(!testableState){
            return;
        }
        infoLog(`v0.2.0`, testableState)
    }, [testableState])


    const rtcId = chat?.rtcId; 
    const id = chat?.roomDocManifest?.id
    if(!rtcId){
        return null;
    }
    if(!idPrevRef.current && id){
        idPrevRef.current = true;
        openBottomsheetIfClosed(IPagesModularScreen.Chat, {documentId:id}, IBottomsheetBehavior.FixedBar);
        
    }

    return (
        <>
            <HelmetCreator title={"Videochat in progress | UIO.io"} />
            <div 
                ref={divRef}
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: `${top}px`,
                    zIndex: 99999,
                }}
            />
            <Styled.VideoContainer>
                <div
                    width={"100%"} 
                    scrolling={"no"}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%"
                    }}
                >
                        <div
                            width={"100%"}
                            style={{
                                flex: 1,
                            }} >
                        <Iframe url={iframeUrl(rtcSubDomain, rtcId)} 
                            title={"Videochat"}
                            allow={"geolocation; microphone; camera; midi; encrypted-media;"} 
                            styles={{width:"100%", height: `100%`}}
                        />
                        </div>
                        <div
                            width={"100%"} 
                            scrolling={"no"}
                            style={{ width:"100%", height: `${height}px`}}
                        ></div>
                    </div>
            </Styled.VideoContainer>

        </>
    );
};


import { setCookie } from "react-use-cookie";
import { USER_INFO_COOKIE } from "utils/constants";
import * as Actions from "providers/AppStateProvider/actions";

export const userReducer = (state, action = {type: null, payload: null}) => {
  const { type, payload } = action
  switch (type) {
    case Actions.SET_USER_INFO: {
        // this saving to cookie shouldn't happen often
        setCookie(USER_INFO_COOKIE, JSON.stringify(payload))
        // if payload is null then all individual props will be reset to null
        return {
            ...state,
            fullname: payload?.fullname,
            email: payload?.email,
            id: payload?._id,
            isEmailConfirmed: payload?.isEmailConfirmed,
            username: payload?.username,
            roles: payload?.roles,  // can be member, admin, anonymous
            maxDistanceSlider: payload?.maxDistanceSlider,
            distanceToEnforceAuthentication: payload?.distanceToEnforceAuthentication,
            initialLocationTypeOnCreateChat: payload?.initialLocationTypeOnCreateChat,
            selectedBusiness: payload?.selectedBusiness,
            businesses: payload?.businesses,
        }
    }
    default:
        return state
  }
}

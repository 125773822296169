const ICrdtType = {
    uiochat: "uiochat"
}

const crdtFactory = async (crdtType)=>{
    if(crdtType===ICrdtType.uiochat){
        return await import("./uiochat");
    }
    return null;
}


export {
    crdtFactory,
    ICrdtType
}
import styled from "styled-components";
import { Typography } from "antd";
import { 
    typographyMixin, TypographyType, TypographySize
} from "components/utils/mixins";
import { colors } from "config/colors";


export const SmallHotText = styled(Typography)`
    ${typographyMixin(TypographyType.black, TypographySize.small)}
    color: ${colors.cta};
    font-weight: 900;
    letter-spacing: -1px;
`
export const MediumHotText = styled(Typography)`
    ${typographyMixin(TypographyType.black, TypographySize.medium)}
    color: ${colors.cta};
    font-weight: 900;
    letter-spacing: -1px;
`
export const LargeHotText = styled(Typography)`
    ${typographyMixin(TypographyType.black, TypographySize.large)}
    color: ${colors.cta};
    font-weight: 900; 
    letter-spacing: -1px;
`
export const ExtraLargeHotText = styled(Typography)`
    ${typographyMixin(TypographyType.black, TypographySize.extraLarge)}
    color: ${colors.cta};
    font-weight: 900; 
    letter-spacing: -1px;
`

export const SmallText = styled(Typography)`
    ${typographyMixin(TypographyType.black, TypographySize.small)}
`
export const MediumText = styled(Typography)`
    ${typographyMixin(TypographyType.black, TypographySize.medium)}
`
export const LargeText = styled(Typography)`
    ${typographyMixin(TypographyType.black, TypographySize.large)}
`
export const ExtraLargeText = styled(Typography)`
    ${typographyMixin(TypographyType.black, TypographySize.extraLarge)}
`

export const Bolded = styled.span`
    font-weight: bold;
`


export const LargeErrorText = styled.p`
    ${typographyMixin(TypographyType.red, TypographySize.large)}
`
export const MediumErrorText = styled.p`
    ${typographyMixin(TypographyType.red, TypographySize.medium)}
`
export const SmallErrorText = styled.p`
    ${typographyMixin(TypographyType.red, TypographySize.small)}
`

import { confirmPasswordField, emailField, fullnameField, passwordField, usernameField } from "components/utils/forms/fields";
import { FormError, SmallText } from "components/Text";
import { LargePrimaryButton, LargeSecondaryButton } from "components/Button";
import { buildSimpleSubmissionForm } from "components/utils/forms/buildSimpleSubmissionForm";
import { useModularNavigation } from "layout/ModalBottomsheetLayout/hooks";
import { IPagesModularScreen } from "pages-modular/types";

const fields = [
  usernameField,
  passwordField,
  confirmPasswordField,
  emailField,
  fullnameField
];

// submitHook is injected into FormControlPanel by buildSimpleSubmissionForm
const FormControlPanel = ({isSubmitting, submitHook})=>{
  const {navigateToAlways} = useModularNavigation();
  return (
    <>
      <FormError name={"mutation-error"} component={"p"} >{submitHook.isError&&`Bad username / password combination`}&nbsp;</FormError >
      <LargePrimaryButton type="submit" fluid disabled={isSubmitting}>{isSubmitting?`Submitting...`:`Create Account`}</LargePrimaryButton>
      <LargeSecondaryButton type="button" onClick={()=>{navigateToAlways({screen: IPagesModularScreen.SignIn})}} fluid>Sign In</LargeSecondaryButton>           
      <SmallText>By clicking on “Create Account“ button, you agree with UIO.io’s <a target="_blank" href="/uio-cdn/terms-of-service">Term of Use</a> & <a target="_blank" href="/uio-cdn/privacy-policy">Privacy Policy</a></SmallText>
    </>
  )
}

export const SignUpForm = buildSimpleSubmissionForm(fields, FormControlPanel)


import * as Styled from "../styles";

export const NavLayout = ({ renderLeft, renderRight, dynamicHeight=false }) => (
  <Styled.Nav dynamicHeight={dynamicHeight}>
    <Styled.NavAlignmentContainer>
      <Styled.LeftContainer>{renderLeft}</Styled.LeftContainer>
      <Styled.RightContainer>{renderRight}</Styled.RightContainer>
    </Styled.NavAlignmentContainer>
  </Styled.Nav>
);

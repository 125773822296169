
import * as Actions from "providers/AppStateProvider/actions";

export const searchReducer = (state, action = {type: null, payload: null}) => {
  const { type, payload } = action
  switch (type) {
    case Actions.SET_SEARCH_RESULTS: {
        // if payload is null then all individual props will be reset to null
        return {
            ...state,
            results: payload
        }
    }
    case Actions.SET_SEARCH_RESULTS_ERROR: {
        return {
            ...state,
            error: payload
        }
    }
    case Actions.SET_SEARCH_REBUFFED: {
        return {
            ...state,
            rebuffed: payload
        }
    }
    default:
        return state
  }
}

import { getCookie } from "react-use-cookie";
import { ANON_XSRF_TOKEN, USER_INFO_COOKIE, XSRF_TOKEN } from "utils/constants";
import { IBottomsheetBehavior } from "../reducers/pageModularScreenReducer";

const user = getCookie(USER_INFO_COOKIE);

const valueOrNull = (value, defaultValue=null) => value ? value : defaultValue

export const initialState = {
    counter: {
        count: 0
    }, 
    
    // bottomsheet layout user interface
    bottomsheetLayout:{
        open: false,
        height: 0,
    },
    
    // dashboard layout user interface
    dashboardLayout:{
        open: false
    },

    // affects the screen and prop data to show in bottomsheet content
    pageModularScreen: {
        screen: null,
        nonprimaryScreens: [],
        props: null,
        behavior: IBottomsheetBehavior.Priority,
        options: {
            showBack: false
        }
    },

    // user object
    user: {
        fullname: valueOrNull(user?.fullname),
        email:  valueOrNull(user?.email),
        id: valueOrNull(user?.id),
        isEmailConfirmed: valueOrNull(user?.isEmailConfirmed, false),
        username: valueOrNull(user?.username),
        roles: valueOrNull(user?.roles, []),  // can be member, admin, anonymous
        initialLocationTypeOnCreateChat: valueOrNull(user?.initialLocationTypeOnCreateChat, "worksite"),
        selectedBusiness: valueOrNull(user?.selectedBusiness),
        businesses: valueOrNull(user?.businesses, []),
    },

    auth: {
        token: getCookie(XSRF_TOKEN),
        anonymousToken: getCookie(ANON_XSRF_TOKEN)
    },
    
    chat: {
        chatId: null,
        connectNotDisabled: false,
        rtcId: null,
    },

    search: {
        results: [],
        error: null,
        rebuffed: false,
    }
};


/************** Start useSelector selectors **************/

export const _selectorBottomsheetLayout = state => state.bottomsheetLayout;
export const _selectorPageDashboardLayout = state => state.dashboardLayout;
export const _selectorPageModularScreen = state => state.pageModularScreen;
export const _selectorChatModularScreen = state => state.chatModularScreen;
export const _selectorUser = state => state.user;
export const _selectorAuth = state => state.auth;
export const _selectorChat = state => state.chat;
export const _selectorSearch = state => state.search;


/************** Start useSelector selectors **************/
// should be consistent with backend node app file Message.js
export const ISystemMessageServerTypes = {
    Error: "error",
    Pong: "pong2",
    DmDocument: "dmDocument", // sending new dm document

    // events to get search results
    SearchResults: "SearchResults",
    SearchResultsError: "SearchResultsError",
    ForceSignInSignUp: "ForceSignInSignUp",

    // events from server that facilitate any type of document manifest
    DocumentManifestNotAvailable: "DocumentManifestNotAvailable", // when the user first enters a chat room and there is no room chat document yet. most likely a anonymous user gets this, or a signed-in user who clicked to join shortly before another signed-in user created a location model
    DocumentManifestCreated: "DocumentManifestCreated", // when the user first enters a chat room and there is no room chat document yet.
    DocumentManifestFound: "DocumentManifestFound", // used after the document manifest is created and sent back to non-anon user or anon user asks for it.
    SharedDocumentFound:"SharedDocumentFound",
    ResourcesInconsistent: "ResourcesInconsistent", // user probably had refreshed browser and there are old resources in the cache. this is a signal to the client to begin onboarding to the other resources with the new socket.
    
   
    // events from server that facilitate geo collaboration
    GeoCollabDocumentNotAvailable: "geoCollabDocumentNotAvailable", // when the user first enters a chat room and there is no room chat document yet. most likely a anonymous user gets this, or a signed-in user who clicked to join shortly before another signed-in user created a location model
    GeoCollabDocumentCreated: "geoCollabDocumentCreated", // when the user first enters a chat room and there is no room chat document yet.
    GeoCollabDocumentManifestFound: "geoCollabDocumentManifestFound", // used after the document manifest is created and sent back to non-anon user or anon user asks for it.
    

    // events from server that facilitate chat
    RoomChatDocumentNotAvailable: "roomChatDocumentNotAvailable", // when the user first enters a chat room and there is no room chat document yet. most likely a anonymous user gets this, or a signed-in user who clicked to join shortly before another signed-in user created a location model
    RoomChatDocumentCreated: "roomChatDocumentCreated", // when the user first enters a chat room and there is no room chat document yet.
    RoomChatDocumentManifestFound: "roomChatDocumentManifestFound", // used after the document manifest is created and sent back to non-anon user or anon user asks for it.
    

    LocationUpdated: "locationUpdated", // when a user updates the location properties like rootDocumentManifest

    // CRDT things
    CrdtMutateData: "CrdtMutateData",
    CrdtManagementOperation: "CrdtManagementOperation",
}